/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";

// Material Dashboard 2 PRO React TS examples components

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import PricingCard from "containers/pricing/components/PricingCard";
import { useState } from "react";
// import { sleep } from "utils/sleep";
import Header from "../Header";

function PricingCards(): JSX.Element {
	const [period, setPeriod] = useState<"monthly" | "quarterly" | "yearly">(
		"monthly"
	);
	// const [prices, setPrices] = useState<string[]>(["99", "499", "2600"]);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	// const [starter, premium, enterprise] = prices;
	const [tabValue, setTabValue] = useState<number>(0);

	const [priceConfig, setPriceConfig] = useState<any>({
		starter: {
			name: "starter",
			currency: "$",
			basePrice: 99,
			minimumMonthlyPlayerSessions: 3000,
			monthlyPlayerSessions: 3000,
			maxPlayers: 10000,
			// step: 1,
			price: 99,
		},
		premium: {
			name: "pro",
			currency: "$",
			basePrice: 499,
			price: 499,
			minimumMonthlyPlayerSessions: 10000,
			monthlyPlayerSessions: 10000,
			placeholder: "COMING SOON!",
			maxPlayers: 50000,
			// step: 1,
		},
		pro: {
			name: "enterprise",
			currency: "$",
			basePrice: 2600,
			price: 2600,
			minimumMonthlyPlayerSessions: 250000,
			monthlyPlayerSessions: 250000,
			maxPlayers: 500000,
			placeholder: "CUSTOM",
			// step: 1,
		},
	});

	const onUpdateMonthlyPlayerSessions = (
		type: string,
		value: number,
		periodFromTab?: string
	) => {
		// const pricePerThousand = 9.91;
		let periodValue = 1;
		let discountPercentage = 0;
		if (periodFromTab) {
			if (periodFromTab === "quarterly") {
				periodValue = 3;
				discountPercentage = 8;
			} else if (periodFromTab === "yearly") {
				periodValue = 12;
				discountPercentage = 25;
			}
		} else if (period === "quarterly") {
			periodValue = 3;
			discountPercentage = 8;
		} else if (period === "yearly") {
			periodValue = 12;
			discountPercentage = 25;
		}
		const currentStep =
			(value -
				priceConfig[type as keyof typeof priceConfig]
					.minimumMonthlyPlayerSessions) /
			1000;
		const unit = 0.033;
		const baseDiscount = 0.01;
		let price = value * unit;
		const discountAmount = price * (baseDiscount * currentStep);
		price -= discountAmount;
		price *= periodValue;
		price = Math.ceil(price);
		if (discountPercentage) {
			const discount = price * (discountPercentage / 100);
			price -= discount;
			price = Math.ceil(price);
		}
		// console.log({ type, value, periodFromTab, plan });
		/* if (value === plan.value) {

		} */

		/* let { basePrice } = priceConfig[type as keyof typeof priceConfig];
		basePrice *= periodValue;
		const newPrice = currentStep * pricePerThousand + basePrice; */
		const temp = {
			...priceConfig,
		};
		// temp[type as keyof typeof priceConfig].price = newPrice.toFixed(2);
		temp[type as keyof typeof priceConfig].price = price.toFixed(2);
		temp[type as keyof typeof priceConfig].monthlyPlayerSessions = value;
		setPriceConfig({ ...temp });
	};

	const handleSetTabValue = async (event: any, newValue: any) => {
		setTabValue(newValue);
		if (event.currentTarget.id === "yearly") {
			setPeriod("yearly");
			onUpdateMonthlyPlayerSessions(
				"starter",
				priceConfig.starter.monthlyPlayerSessions,
				"yearly"
			);
			onUpdateMonthlyPlayerSessions(
				"premium",
				priceConfig.premium.monthlyPlayerSessions,
				"yearly"
			);
			onUpdateMonthlyPlayerSessions(
				"pro",
				priceConfig.pro.monthlyPlayerSessions,
				"yearly"
			);
		} else if (event.currentTarget.id === "quarterly") {
			setPeriod("quarterly");
			onUpdateMonthlyPlayerSessions(
				"starter",
				priceConfig.starter.monthlyPlayerSessions,
				"quarterly"
			);
			onUpdateMonthlyPlayerSessions(
				"premium",
				priceConfig.premium.monthlyPlayerSessions,
				"quarterly"
			);
			onUpdateMonthlyPlayerSessions(
				"pro",
				priceConfig.pro.monthlyPlayerSessions,
				"quarterly"
			);
		} else {
			setPeriod("monthly");
			onUpdateMonthlyPlayerSessions(
				"starter",
				priceConfig.starter.monthlyPlayerSessions,
				"monthly"
			);
			onUpdateMonthlyPlayerSessions(
				"premium",
				priceConfig.premium.monthlyPlayerSessions,
				"monthly"
			);
			onUpdateMonthlyPlayerSessions(
				"pro",
				priceConfig.pro.monthlyPlayerSessions,
				"monthly"
			);
		}
	};

	const getDiscountText = () => {
		switch (period) {
			case "monthly":
				return "RECOMMENDED";
			case "quarterly":
				return "8% Discount";
			case "yearly":
				return "25% Discount";
			default:
				return "RECOMMENDED";
		}
	};

	return (
		<Header tabValue={tabValue} tabHandler={handleSetTabValue}>
			<MDBox position="relative" zIndex={10} mt={8} px={{ xs: 1, sm: 0 }}>
				<Grid container spacing={3} justifyContent="center">
					<Grid item xs={12} lg={4}>
						<PricingCard
							config={priceConfig.starter}
							period={period}
							discountConfig={{
								text: getDiscountText(),
								color: "primary",
							}}
							onChangeSlider={onUpdateMonthlyPlayerSessions}
							color={!darkMode ? "white" : "dark"}
							badge={{
								color: darkMode ? "info" : "info",
								label: "starter",
							}}
							price={{
								currency: "$",
								value: priceConfig.starter.price,
								type: period.slice(0, 2),
							}}
							specifications={[
								{
									label: "1 Platform (App or Web)",
									includes: true,
								},
								{
									label: "4 Active tournaments",
									includes: true,
								},
								{ label: "1 team member", includes: true },
								{ label: "Basic games", includes: true },
								{
									label: "Analytics",
									includes: true,
								},
							]}
							action={{
								type: "internal",
								route: "/",
								color: darkMode ? "info" : "info",
								label: "join",
							}}
							note="*An Overcharge fee of $0.033 would be charged
							per additional active player"
							// shadow={darkMode}
						/>
					</Grid>
					<Grid item xs={12} lg={4}>
						<PricingCard
							config={priceConfig.premium}
							period={period}
							onChangeSlider={onUpdateMonthlyPlayerSessions}
							color={!darkMode ? "white" : "dark"}
							badge={{ color: "grey-400", label: "pro" }}
							sliderConfig={{
								disabled: true,
							}}
							/* price={{
								currency: "$",
								value: priceConfig.premium.price,
								type: period.slice(0, 2),
							}} */
							price={{
								currency: "",
								value: "COMING SOON!",
							}}
							specifications={[
								{
									label: "1 Platform (App or Web)",
									includes: true,
								},
								{
									label: "16 Active Tournaments",
									includes: true,
								},
								{ label: "4 Team members", includes: true },
								{ label: "Premium games", includes: true },
								{
									label: "Analytics",
									includes: true,
								},
								{ label: "Advanced features", includes: true },
								/* {
									label: "Advanced Data dashboard",
									includes: true,
								},
								{ label: "Premium templates", includes: true }, */
							]}
							action={{
								type: "external",
								route: "/",
								color: "light",
								label: "join",
								disabled: true,
							}}
						/>
					</Grid>
					<Grid item xs={12} lg={4}>
						<PricingCard
							config={priceConfig.pro}
							period={period}
							onChangeSlider={onUpdateMonthlyPlayerSessions}
							color={darkMode ? "dark" : "white"}
							badge={{
								color: darkMode ? "warning" : "warning",
								label: "enterprise",
							}}
							subtitle="For Price Estimate contact our Sales Team"
							hideSlider
							sliderConfig={{
								disabled: true,
							}}
							/* price={{
								currency: "$",
								value: priceConfig.pro.price,
								type: period.slice(0, 2),
							}} */
							price={{
								currency: "",
								value: priceConfig.pro.price,
							}}
							specifications={[
								{
									label: "1 Platform (App or Web)",
									includes: true,
								},
								{
									label: "Custom number of Tournaments",
									includes: true,
								},
								{
									label: "Platform UI and Theme Customization",
									includes: true,
								},
								{ label: "Premium games", includes: true },
								{
									label: "Customized branded games",
									includes: true,
								},
								{ label: "Advanced Analytics", includes: true },
								{
									label: "Engagement Plus",
									includes: true,
									help: {
										text: "Addition features to further drive user engagement, including missions, welcome bonuses, loyalty & reward points",
									},
								},
								{
									label: "Customized integrations",
									includes: true,
								},
								{
									label: "Dedicated Account Manager",
									includes: true,
								},
								{
									label: "Growth Hacking team",
									includes: true,
								},
								{
									label: "24/7 Tech Support",
									includes: true,
								},
							]}
							action={{
								type: "external",
								route: "https://goama.com/goama/contact-sales/",
								color: darkMode ? "warning" : "warning",
								label: "contact sales",
							}}
							// shadow={darkMode}
						/>
					</Grid>
				</Grid>
			</MDBox>
		</Header>
	);
}

export default PricingCards;
