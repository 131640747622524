/* eslint-disable react/require-default-props */
import Card from "@mui/material/Card";
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import MDAvatar from "template/components/MDAvatar";
import MDButton from "template/components/MDButton";
import avatarThumbnail from "assets/images/avatars/dummy_user.png";
import { styled } from "@mui/system";
// import { useSelector } from "react-redux";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";

const MsgStyle = styled("div")({
	padding: "5rem 0",
	textAlign: "center",
	"& .MuiAvatar-root": {
		margin: "40px auto",
		marginBottom: "0",
	},
});

interface ICongratulationsMessage {
	closeModal: () => void;
	buttonText: string;
	header: any;
	description: string;
	imageSrc: any;
	showImage?: boolean;
	imageComponent?: null | any;
	imageShape?: "circular" | "square";
	imageSize?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
}

export default function CongratulationsMessage(props: ICongratulationsMessage) {
	// const { user } = useSelector((state: any) => state.auth);
	const { width, height } = useWindowSize();
	const {
		closeModal,
		buttonText = "OKAY",
		header = "",
		description = "",
		imageSrc = "",
		showImage = true,
		imageComponent = null,
		imageShape = "circular",
		imageSize = "xl",
	} = props;
	return (
		<>
			<Confetti
				width={width}
				height={height}
				recycle={false}
				numberOfPieces={1000}
				style={{
					position: "absolute" as "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
				}}
			/>
			<Card>
				<MDBox
					mt={-3}
					mx={2}
					p={5}
					justifyContent="center"
					textAlign="center"
				>
					<MsgStyle>
						<div className="msg-wrapper">
							<div className="message-content">
								<MDTypography
									variant="h5"
									fontWeight="regular"
									pb={1}
								>
									{header}
								</MDTypography>
								<MDTypography variant="body2" color="text">
									{description}
								</MDTypography>
								{(showImage &&
									(imageComponent || (
										<MDAvatar
											src={imageSrc || avatarThumbnail}
											alt="profile picture"
											size={imageSize}
											variant={imageShape}
											sx={{
												mt: 5,
											}}
										/>
									))) ||
									null}
								<MDButton
									variant="outlined"
									color="dark"
									size="large"
									onClick={closeModal}
									sx={{
										marginTop: 5,
									}}
								>
									{buttonText}
								</MDButton>
							</div>
						</div>
					</MsgStyle>
				</MDBox>
			</Card>
		</>
	);
}
