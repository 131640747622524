import { useState, useEffect } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import { TextField } from "@mui/material";
import { ErrorMessage } from "@hookform/error-message";
import { styled } from "@mui/system";

const InputStyles = styled("div")(() => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	"& .form-group": {
		position: "relative",
		display: "flex",
		"& input[type='text']": {
			width: "100%",
		},
		"& .MuiBox-root": {
			width: "100%",
		},
		"& .tooltip-wrapper": {
			"& button": {
				width: "30px",
				height: "30px",
				marginTop: "15px",
				"& img": {
					maxWidth: "30px",
				},
			},
		},
	},
}));

const modes: string[] = [
	"Manually",
	"SDK",
	"Wordpress plugin",
	"Shopify plugin",
	"Wix plugin",
];
const technologies: string[] = [
	"Native Android - Java",
	"React Native",
	"Swift",
	"Kotlin",
	"Other",
	"I don’t know right now",
];

export default function IntegrationModeForm({ form }: { form: any }) {
	const [specifyField, setSpecifyField] = useState<boolean>(false);
	const {
		register,
		setValue,
		getValues,
		formState: { errors },
	} = form;

	const onSelect = (event: SelectChangeEvent) => {
		setValue(event.target.name, event.target.value);
		if (
			event.target.name === "technology" &&
			event.target.value === "Other"
		) {
			setSpecifyField(true);
		} else {
			setSpecifyField(false);
		}
	};

	useEffect(() => {
		if (getValues("technology") === "Other") {
			setSpecifyField(true);
		} else {
			setSpecifyField(false);
		}
	}, [specifyField]);

	return (
		<form>
			<MDBox>
				<MDBox width="82%" textAlign="center" mx="auto" mt={5} pt={4}>
					<MDBox mb={1}>
						<MDTypography variant="h4" fontWeight="regular">
							Select integration mode
						</MDTypography>
					</MDBox>
					<MDTypography variant="body2" color="text">
						Some options aren’t available at this time
					</MDTypography>
				</MDBox>
				<MDBox mt={1} pt={2}>
					<InputStyles>
						<FormControl
							sx={{ m: 1, width: 242, maxWidth: "98%", mt: 2 }}
						>
							<InputLabel id="country-label">
								Integration mode
							</InputLabel>
							<Select
								{...register("mode")}
								name="integration_mode"
								labelId="mode-label"
								id="integration_mode"
								// value={getValues("country")}
								defaultValue={getValues("integration_mode")}
								label="Integration mode"
								onChange={onSelect}
								IconComponent={KeyboardArrowDown}
								sx={{ minHeight: 45 }}
								placeholder="Select one option"
							>
								<MenuItem value="none" disabled>
									Select one option
								</MenuItem>
								{modes.map((key: string, index: number) => {
									if (index + 1 > 1) {
										return (
											<MenuItem
												disabled
												// eslint-disable-next-line react/no-array-index-key
												key={`${key}${index}`}
												value={key}
											>
												{key}
											</MenuItem>
										);
									}
									return (
										<MenuItem
											// eslint-disable-next-line react/no-array-index-key
											key={`${key}${index}`}
											value={key}
										>
											{key}
										</MenuItem>
									);
								})}
							</Select>
							<ErrorMessage
								errors={errors}
								name="integration_mode"
								render={({ message }) => (
									<MDTypography
										variant="caption"
										color="secondary"
										style={{
											marginLeft: 15,
										}}
									>
										{message}
									</MDTypography>
								)}
							/>
						</FormControl>
					</InputStyles>
				</MDBox>
				<MDBox mt={1}>
					<InputStyles>
						<FormControl
							sx={{ m: 1, width: 242, maxWidth: "98%", mt: 2 }}
						>
							<InputLabel id="country-label">
								Technology
							</InputLabel>
							<Select
								{...register("technology")}
								name="technology"
								labelId="mode-label"
								id="technology"
								// value={getValues("country")}
								defaultValue={getValues("technology")}
								label="Technology"
								onChange={onSelect}
								IconComponent={KeyboardArrowDown}
								sx={{ minHeight: 45 }}
								placeholder="Select one option"
							>
								<MenuItem value="none" disabled>
									Select one option
								</MenuItem>
								{technologies.map(
									(key: string, index: number) => {
										if (index + 1 > 1) {
											return (
												<MenuItem
													// eslint-disable-next-line react/no-array-index-key
													key={`${key}${index}`}
													value={key}
												>
													{key}
												</MenuItem>
											);
										}
										return (
											<MenuItem
												// eslint-disable-next-line react/no-array-index-key
												key={`${key}${index}`}
												value={key}
											>
												{key}
											</MenuItem>
										);
									}
								)}
							</Select>
							<ErrorMessage
								errors={errors}
								name="technology"
								render={({ message }) => (
									<MDTypography
										variant="caption"
										color="secondary"
										style={{
											marginLeft: 15,
										}}
									>
										{message}
									</MDTypography>
								)}
							/>
						</FormControl>
					</InputStyles>
				</MDBox>
				{/* Should show when user select other technology */}
				{specifyField && (
					<MDBox mt={2}>
						<InputStyles>
							<MDBox
								sx={{
									display: "flex",
									justifyContent: "center",
									flexDirection: "column",
								}}
							>
								<TextField
									onChange={(event: any) => {
										setValue("comment", event.target.value);
									}}
									{...register("comment")}
									label="Please Specify"
									name="comment"
									defaultValue={getValues().comment}
									placeholder="Specify Technology"
									sx={{
										m: 1,
										width: 242,
										maxWidth: "98%",
									}}
								/>
								<ErrorMessage
									errors={errors}
									name="comment"
									render={({ message }) => (
										<MDTypography
											variant="caption"
											color="secondary"
											style={{
												marginLeft: 15,
											}}
										>
											{message}
										</MDTypography>
									)}
								/>
								{/* {duplicate && (
								<MDTypography
									style={{
										float: "left",
									}}
									variant="caption"
									color="secondary"
								>
									&nbsp;&nbsp;* Platform already exists
								</MDTypography>
							)} */}
							</MDBox>
						</InputStyles>
					</MDBox>
				)}
			</MDBox>
		</form>
	);
}
