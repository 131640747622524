import * as React from "react";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// @mui material components
// import Grid from "@mui/material/Grid";
// import { styled } from "@mui/system";

// Material Dashboard 2 PRO React TS components

import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";

// import FormField from "components/elements/FormField";

import OptionBox from "components/elements/OptionBox";

// images
import watchImg from "assets/images/icons/watch.png";
import userImg from "assets/images/icons/user_2.png";
import alarmImg from "assets/images/icons/alarm.png";
import { FormProviderProps } from "react-hook-form";
import analytics from "utils/analytics";

function Template({
	form,
	onGoalSubmit,
}: {
	form: FormProviderProps;
	onGoalSubmit: Function;
}): JSX.Element {
	const { register, getValues, setValue } = form;
	const [selectedValue, setSelectedValue] = React.useState(getValues("goal"));
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event?.target?.value) {
			setSelectedValue(event.target.value);
			setValue("goal", event.target.value);
			analytics.selectTemplate({ template: event.target.value });
			setTimeout(() => onGoalSubmit(), 500);
		} else if (selectedValue) {
			setTimeout(() => onGoalSubmit(), 500);
		}
	};

	return (
		<form>
			<MDBox>
				<MDBox width="82%" textAlign="center" mx="auto" mt={5} pt={5}>
					<MDBox mb={2}>
						<MDTypography variant="h5" fontWeight="regular">
							Start with a simple template to reach specific goals
						</MDTypography>
					</MDBox>
					<MDTypography variant="body2" color="text">
						Choose one option to start
					</MDTypography>
				</MDBox>
				<MDBox
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
					flexWrap="wrap"
				>
					<FormControl>
						<div style={{ opacity: "0" }}>
							<Radio
								{...register("goal")}
								checked={
									selectedValue ===
									"User acquisition (Lower CAC)"
								}
								onChange={handleChange}
								onClick={() => handleChange(null)}
								value="User acquisition (Lower CAC)"
								name="goal"
								inputProps={{
									"aria-label":
										"User acquisition (Lower CAC)",
								}}
								id="template_a"
							/>
						</div>
						<FormLabel htmlFor="template_a">
							<OptionBox
								icon={userImg}
								title="User acquisition (Lower CAC)"
								selected={
									selectedValue ===
									"User acquisition (Lower CAC)"
								}
							/>
						</FormLabel>
					</FormControl>
					<FormControl>
						<div style={{ opacity: "0" }}>
							<Radio
								{...register("goal")}
								checked={
									selectedValue ===
									"Navigation time (Engagement)"
								}
								onChange={handleChange}
								onClick={() => handleChange(null)}
								value="Navigation time (Engagement)"
								name="goal"
								inputProps={{
									"aria-label":
										"Navigation time (Engagement)",
								}}
								id="template_b"
							/>
						</div>
						<FormLabel htmlFor="template_b">
							<OptionBox
								icon={watchImg}
								title="Navigation time (Engagement)"
								selected={
									selectedValue ===
									"Navigation time (Engagement)"
								}
							/>
						</FormLabel>
					</FormControl>
					<FormControl>
						<div style={{ opacity: "0" }}>
							<Radio
								{...register("goal")}
								checked={
									selectedValue === "Daily visits (Frequency)"
								}
								onChange={handleChange}
								onClick={() => handleChange(null)}
								value="Daily visits (Frequency)"
								name="goal"
								inputProps={{
									"aria-label": "Daily visits (Frequency)",
								}}
								id="template_c"
							/>
						</div>
						<FormLabel htmlFor="template_c">
							<OptionBox
								icon={alarmImg}
								title="Daily visits (Frequency)"
								selected={
									selectedValue === "Daily visits (Frequency)"
								}
							/>
						</FormLabel>
					</FormControl>
				</MDBox>
			</MDBox>
		</form>
	);
}

export default Template;
