import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
//  FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
// import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

// template component
import MDTypography from "template/components/MDTypography";
import MDButton from "template/components/MDButton";

import SwitchButton from "components/elements/SwitchButton";

import AlertMessage from "components/modals/AlertMessage";

// images
import towerImg from "assets/images/icons/tower.png";
import { dateFormat, removeTimezoneFromDateString } from "utils/date-format";
// import { Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	openTournamentBuilderModal,
	openPreviewAppModal,
} from "slices/modalSlice";
import { updateTournamentBuilderForm } from "slices/tournamentBuilderSlice";
import { TOURNAMENT_STATUS } from "constants/constants";
import {
	deleteTournament,
	updateTournamentStatus,
} from "actions/tournamentActions";
import analytics from "utils/analytics";
import { toast } from "react-toastify";

export default function SingleTournament(props: any) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { isDesktop } = useSelector((state: any) => state.layoutReducer);
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [openOffDialog, setOpenOffDialog] = useState<boolean>(false);
	const [appStatus, setAppStatus] = useState<boolean>(false);
	const [deleteItem, setDeleteItem] = useState<boolean>(false);
	// const [playLink, setPlayLink] = useState<string>("");

	const { tournament } = props;

	const { platforms } = useSelector((state: any) => state.platformsReducer);
	const platform =
		platforms && platforms.length > 0 && platforms[0] ? platforms[0] : null;
	const { tournaments } = useSelector(
		(state: any) => state.tournamentsReducer
	);
	const countActiveTournaments = () => {
		const activeTournaments = tournaments.filter(
			(tournament: any) =>
				tournament.status === TOURNAMENT_STATUS.ON ||
				tournament.status === TOURNAMENT_STATUS.COMPLETED ||
				tournament.status === TOURNAMENT_STATUS.SCHEDULED
		);
		return activeTournaments.length;
	};
	// const label = { inputProps: { "aria-label": "Checkbox demo" } };

	useEffect(() => {
		if (
			tournament &&
			tournament.status !== TOURNAMENT_STATUS.DRAFT &&
			tournament.status !== TOURNAMENT_STATUS.OFF
		) {
			setAppStatus(true);
		} else {
			setAppStatus(false);
		}
		// setPlayLink(tournament?.game?.play_link);
	}, [tournament]);

	const handleEdit = () => {
		// console.log({ tournament });
		// let prizeData = [];
		// const hasPrize = tournament?.prize && tournament?.prize?.length > 0;
		const params = {
			...tournament,
			game_alias: tournament?.game?.alias || null,
			start_time: tournament?.start_time
				? new Date(removeTimezoneFromDateString(tournament?.start_time))
				: new Date(),
			end_time: tournament?.end_time
				? new Date(removeTimezoneFromDateString(tournament?.end_time))
				: null,
			// no_rewards_checked: !hasPrize,
			// show_reward_setup: "yes",
		};
		/* if (hasPrize) {
			prizeData = tournament?.prize.map((item: any) => {
				if (item.prize_type === PRIZE_TYPE.PHYSICAL_PRIZE) {
					const split = item.prize.split(" ");
					const quantity = split[0];
					const prize = split[1];
					return {
						...item,
						prize,
						quantity,
					};
				}
				return {
					...item,
					quantity: item.prize,
					prize: "",
				};
			});
			// console.log({ prizeData });
			params.prize = prizeData;
		} */
		dispatch(
			updateTournamentBuilderForm({
				formData: {
					...params,
				},
				step: (tournament?.steps > 6 ? 6 : tournament?.steps) || 0,
			})
		);
		dispatch(openTournamentBuilderModal());
	};
	const handleCancel = () => {
		setOpenDialog(false);
		// setAppStatus(false);
	};
	const handleSwitchChange = () => {
		// let changedStatus = !appStatus;
		if (TOURNAMENT_STATUS.SCHEDULED === tournament?.status) {
			setOpenOffDialog(true);
			/* dispatch(
				updateTournamentStatus({
					alias: tournament?.alias,
					status: TOURNAMENT_STATUS.OFF,
				})
			); */
		} else if (TOURNAMENT_STATUS.OFF === tournament?.status) {
			setOpenDialog(true);
		}
	};

	const onDelete = () => {
		setDeleteItem(true);
	};
	const handleDelete = () => {
		setDeleteItem(false);
		dispatch(deleteTournament(tournament?.alias) as any).then(() => {
			setDeleteItem(false);
		});
	};
	const handleCancelDelete = () => {
		setDeleteItem(false);
	};

	const startTime =
		(tournament?.start_time &&
			dateFormat(
				new Date(removeTimezoneFromDateString(tournament?.start_time)),
				"dd MMMM yyyy"
			)) ||
		"";

	const endTime =
		(tournament?.end_time &&
			dateFormat(
				new Date(removeTimezoneFromDateString(tournament?.end_time)),
				"dd MMMM yyyy"
			)) ||
		"";

	const getSwitch = () => {
		if (!platform) return null;
		if (tournament.status === TOURNAMENT_STATUS.OFF) {
			return (
				<div className="off-status">
					<SwitchButton
						checked={appStatus}
						onChange={() => {
							if (countActiveTournaments() >= 2) {
								toast(
									"Can not activate more than two tournaments under trial period.",
									{
										autoClose: 3000,
										type: "error",
										hideProgressBar: false,
										theme: "light",
									}
								);
							} else {
								handleSwitchChange();
							}
						}}
						activeButtontext="Scheduled"
						inactiveButtonText="off"
					/>
				</div>
			);
		}
		if (tournament.status === TOURNAMENT_STATUS.SCHEDULED) {
			return (
				<div className={appStatus ? "scheduled-status" : "off-status"}>
					<SwitchButton
						checked={appStatus}
						onChange={handleSwitchChange}
						activeButtontext="Scheduled"
						inactiveButtonText="off"
					/>
				</div>
			);
		}
		if (tournament.status === TOURNAMENT_STATUS.ON) {
			return (
				<div className={appStatus ? "on-status" : "off-status"}>
					<SwitchButton
						checked={appStatus}
						activeColor="success"
						// onChange={handleSwitchChange}
						activeButtontext="On"
						inactiveButtonText="off"
					/>
				</div>
			);
		}
		if (tournament.status === TOURNAMENT_STATUS.COMPLETED) {
			return (
				<div>
					<SwitchButton
						disabled
						checked={appStatus}
						onChange={() => {}}
						activeButtontext="Completed"
						inactiveButtonText="off"
						activeColor="grey"
					/>
				</div>
			);
		}
		if (tournament.status === TOURNAMENT_STATUS.DRAFT) {
			return (
				<MDButton
					variant="outlined"
					color="dark"
					onClick={() => {
						handleEdit();
						analytics.clickEditDraftTournament({
							alias: tournament?.alias,
							game: tournament?.game?.name,
						});
					}}
					// fullWidth
					// style={{ maxWidth: 190 }}
				>
					Edit draft
				</MDButton>
			);
		}
		return null;
	};

	const { workspaces } = useSelector((state: any) => state.workspaces);
	const workspace =
		workspaces && workspaces.length > 0 ? workspaces[0] : null;

	const { user } = useSelector((state: any) => state.auth);
	// preview button
	const previewButton = () => {
		if (tournament && tournament.status !== TOURNAMENT_STATUS.DRAFT) {
			return (
				<MDButton
					variant="outlined"
					color="success"
					style={{ paddingBottom: 7 }}
					onClick={() => {
						analytics.preview_clicked({});
						if (isDesktop) {
							dispatch(openPreviewAppModal());
						} else {
							window
								.open(
									`${process.env.REACT_APP_TOURNAMENT_PLATFORM_URL}/tournament?app=${workspace?.alias}&country=ZZ&userid=${user?.email}`,
									"_blank"
								)
								.focus();
						}
					}}
				>
					Preview
				</MDButton>
			);
		}
		return null;
	};

	return (
		<React.Fragment key={tournament?.alias}>
			<AlertMessage
				title="Turn off Tournament"
				message="Confirm that you want to turn off this tournament"
				openDialog={openOffDialog}
				// handleClose={handleClose}
				handleConfirm={() => {
					setOpenOffDialog(false);
					// handleCancel();
					dispatch(
						updateTournamentStatus({
							alias: tournament?.alias,
							status: TOURNAMENT_STATUS.OFF,
						})
					);
				}}
				handleCancel={() => setOpenOffDialog(false)}
				cancelButtonText="cancel"
				successButtonText="confirm"
				successButtonColor="secondary"
			/>
			<AlertMessage
				title="Publish Tournament"
				message="Select Confirm to make this tournament go live on your App on Start Date."
				openDialog={openDialog}
				// handleClose={handleClose}
				handleConfirm={() => {
					handleCancel();
					dispatch(
						updateTournamentStatus({
							alias: tournament?.alias,
							status: 1,
						})
					);
				}}
				handleCancel={handleCancel}
				cancelButtonText="cancel"
				successButtonText="confirm"
			/>
			<AlertMessage
				title="Delete Tournament"
				message="Are you sure you want to delete this tournament permanently?"
				openDialog={deleteItem}
				handleConfirm={handleDelete}
				handleCancel={handleCancelDelete}
				cancelButtonText="no"
				successButtonText="yes, delete"
				successButtonColor="secondary"
			/>
			<TableRow>
				<TableCell align="center" colSpan={2}>
					<div className="game-info">
						{/* <div className="game-checked">
						<Checkbox {...label} />
					</div> */}
						<div className="game-thumb">
							<img
								src={tournament?.game?.cover_image}
								width={42}
								height={42}
								alt="game"
								style={{
									borderRadius: 5,
									objectFit: "cover",
									marginRight: 10,
								}}
							/>
						</div>
						<div className="game-data">
							<MDTypography variant="h6" fontSize="small">
								{tournament?.title}
							</MDTypography>
						</div>
					</div>
				</TableCell>
				<TableCell align="left" colSpan={1} style={{ minWidth: 160 }}>
					<MDTypography variant="caption" fontSize="small">
						{startTime !== "Invalid date" && startTime
							? startTime
							: ""}
					</MDTypography>
				</TableCell>
				<TableCell align="left" colSpan={1} style={{ minWidth: 100 }}>
					<MDTypography variant="caption" fontSize="small">
						{endTime !== "Invalid date" && endTime ? endTime : ""}
					</MDTypography>
				</TableCell>
				<TableCell align="left" colSpan={1}>
					<MDTypography variant="caption" fontSize="small">
						{/* {tournament?.max_player} */}
					</MDTypography>
				</TableCell>
				<TableCell
					align="center"
					colSpan={1}
					style={{ paddingRight: 45 }}
				>
					<div className="avg-player">
						<div className="game-action">
							<Button
								type="button"
								disabled={appStatus}
								sx={{ minWidth: "unset" }}
								onClick={() => {
									handleEdit();
									analytics.clickEditTournament({
										alias: tournament?.alias,
										game: tournament?.game?.name,
									});
								}}
							>
								<ModeEditOutlineIcon fontSize="small" />
							</Button>
							<Button
								type="button"
								sx={{ minWidth: "unset" }}
								onClick={() => {
									navigate("/analytics");
								}}
							>
								<img src={towerImg} width="15" alt="tower" />
							</Button>
							<Button
								type="button"
								sx={{ minWidth: "unset" }}
								disabled={appStatus}
								onClick={onDelete}
							>
								<DeleteIcon fontSize="small" />
							</Button>
						</div>
					</div>
				</TableCell>
				<TableCell align="left" colSpan={1}>
					<div className="preview-wrapper">{previewButton()}</div>
				</TableCell>

				{/* {platform ? (
					<TableCell align="left" colSpan={1}>
						<div className="platform-name">
							<FormControl>
								<InputLabel id="demo-simple-select-label">
									Platform
								</InputLabel>
								<Select
									// disabled
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={platform?.name}
									label="Platform"
									style={{
										minHeight: 40,
										maxWidth: 185,
										width: 185,
									}}
								>
									<MenuItem value={platform?.name}>
										<MDTypography
											variant="inherit"
											fontWeight="normal"
											textTransform="capitalize"
										>
											{platform?.name}
										</MDTypography>
									</MenuItem>
								</Select>
							</FormControl>
						</div>
					</TableCell>
				) : (
					<TableCell align="left" colSpan={1}>
						<div className="platform-name">
							<MDButton
								onClick={() => {
									analytics.connectPlatform({});
									navigate("/integration");
								}}
								color="success"
								variant="outlined"
								// fullWidth
								// style={{ maxWidth: 190 }}
							>
								<Icon
									sx={{ mb: 0.2 }}
									fontSize="small"
									color="inherit"
								>
									add
								</Icon>
								&nbsp;&nbsp;Connect platform
							</MDButton>
						</div>
					</TableCell>
				)} */}

				<TableCell
					align="center"
					style={{ minWidth: "120px" }}
					colSpan={1}
				>
					{getSwitch()}
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}
