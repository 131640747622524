import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseButton from "components/elements/CloseButton";
import MDButton from "template/components/MDButton";
import ModalWrapper from "../ModalWrapper";

const style = {
	"& .modal-content": {
		backgroundColor: "#fff",
		paddingTop: "4rem",
		paddingBottom: "3rem",
		boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
		borderRadius: "12px",
	},
	"& .box": {
		display: "flex",
		justifyContent: "center",
	},
};

type ModalType = {
	open: boolean;
	handleClose: Function;
	title: string;
	subTitle: string;
	buttonText: string;
	image: string;
	modalButtonAction: Function;
};

export default function WelcomeModal({
	open,
	handleClose,
	title,
	subTitle,
	buttonText,
	image,
	modalButtonAction,
}: ModalType) {
	const { width, height } = useWindowSize();
	return (
		<div>
			<Modal
				open={open}
				onClose={() => handleClose()}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Confetti
						width={width}
						height={height}
						recycle={false}
						numberOfPieces={1000}
						style={{
							position: "absolute" as "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
						}}
					/>
					<ModalWrapper>
						<div className="modal-content">
							<Typography
								id="modal-modal-title"
								variant="h5"
								component="h1"
								fontSize="large"
								fontWeight="normal"
								lineHeight="2.75rem"
								sx={{
									textAlign: "center",
									maxWidth: "60%",
									margin: "0 auto",
								}}
							>
								{title}
							</Typography>
							<div className="box">
								<div>
									<img src={image} alt="next" />
								</div>
								<Typography
									id="modal-modal-description"
									sx={{ mt: 6 }}
									variant="body2"
								>
									{subTitle}
								</Typography>
							</div>
							<div className="box">
								<MDButton
									variant="outlined"
									color="dark"
									onClick={() => modalButtonAction()}
								>
									{buttonText}
								</MDButton>
							</div>
						</div>
					</ModalWrapper>

					<CloseButton handleClose={handleClose} buttonText="Close" />
				</Box>
			</Modal>
		</div>
	);
}
