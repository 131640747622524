/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

interface IModals {
	fullScreenLoading: boolean;
	showWorkspaceModal: boolean;
	showTournamentBuilderModal: boolean;
	showPreviewAppModal: boolean;
	showPlatformIntegratorModal: boolean;
	showGetStartedModal: boolean;
	modalVideoUrl?: any;
}

const initialState: IModals = {
	fullScreenLoading: false,
	showWorkspaceModal: false,
	showTournamentBuilderModal: true,
	showPreviewAppModal: false,
	showPlatformIntegratorModal: false,
	showGetStartedModal: false,
	modalVideoUrl: "https://www.youtube.com/embed/3ztRM_LO8bI?autoplay=1",
};

const slice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		startFullScreenLoading(state: IModals): void {
			state.fullScreenLoading = true;
		},
		stopFullScreenLoading(state: IModals): void {
			state.fullScreenLoading = false;
		},
		openWorkspaceModal(state: IModals): void {
			state.showWorkspaceModal = true;
		},
		openGetStartedModal(state: IModals, videoUrl): void {
			state.showGetStartedModal = true;
			state.modalVideoUrl = videoUrl;
		},
		closeGetStartedModal(state: IModals): void {
			state.showGetStartedModal = false;
		},
		closeWorkspaceModal(state: IModals): void {
			state.showWorkspaceModal = false;
		},
		openTournamentBuilderModal(state: IModals): void {
			state.showTournamentBuilderModal = true;
		},
		openPreviewAppModal(state: IModals): void {
			state.showPreviewAppModal = true;
		},
		closePreviewAppModal(state: IModals): void {
			state.showPreviewAppModal = false;
		},
		closeTournamentBuilderModal(state: IModals): void {
			state.showTournamentBuilderModal = false;
		},
		openPlatformIntegratorModal(state: IModals): void {
			state.showPlatformIntegratorModal = true;
		},
		closePlatformIntegratorModal(state: IModals): void {
			state.showPlatformIntegratorModal = false;
		},
	},
});

export const { actions, reducer } = slice;

export const {
	startFullScreenLoading,
	stopFullScreenLoading,
	openWorkspaceModal,
	closeWorkspaceModal,
	openTournamentBuilderModal,
	openPreviewAppModal,
	closePreviewAppModal,
	closeTournamentBuilderModal,
	openPlatformIntegratorModal,
	closePlatformIntegratorModal,
	openGetStartedModal,
	closeGetStartedModal,
} = actions;

export default reducer;
