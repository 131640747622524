// import { authorizeGoogle } from "actions/auth";
import { authorizeGoogle } from "actions/auth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import SimpleLayout from "template/layouts/simple-layout/SimpleLayout";
import { getJSONfromQueryParams } from "utils";

function SocialAuthWait() {
	const dispatch = useDispatch();
	useEffect(() => {
		const {
			location: { search },
		} = window;
		const params = getJSONfromQueryParams(search);
		// eslint-disable-next-line no-console
		console.log({ params });
		dispatch(authorizeGoogle({ ...params }));
	}, []);
	return <SimpleLayout>Loading...</SimpleLayout>;
}

export default SocialAuthWait;
