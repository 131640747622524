import { useState } from "react";
import MDTypography from "template/components/MDTypography";
import MDButton from "template/components/MDButton";
import { styled } from "@mui/system";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyStyle = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.dark.ultraLight,
	padding: "10px 15px",
	borderRadius: "10px",
	"& .copy-game-wrapper": {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			alignItems: "baseline",
			".copy-btn": {
				marginTop: "10px",
			},
		},
	},
}));
export default function CopyGameBlock({ link }: any) {
	const [copied, setCopied] = useState<boolean>(false);
	return (
		<CopyStyle>
			<div className="copy-game-wrapper">
				<div className="copy-content">
					<MDTypography variant="body2" fontWeight="light">
						Your unique URL
					</MDTypography>
					<MDTypography
						variant="body2"
						fontSize="small"
						fontWeight="medium"
					>
						{link}
					</MDTypography>
				</div>
				<div className="copy-btn">
					<CopyToClipboard
						text={link}
						onCopy={() => {
							setCopied(true);
							setTimeout(() => {
								setCopied(false);
							}, 1000);
						}}
					>
						<MDButton size="large" color="dark">
							{copied ? "COPIED" : "COPY"}
						</MDButton>
					</CopyToClipboard>
				</div>
			</div>
		</CopyStyle>
	);
}
