/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

interface IModals {
	loading: boolean;
	formData: {
		alias: string | null;
		name: string;
		country: string;
		mode: string | number;
		integration_mode: string | number;
		technology: string | number;
		comment?: string;
		platform_type: string;
	};
}

const initialFormData: any = {
	alias: null,
	name: "",
	country: "none",
	mode: 1,
	integration_mode: "Manually",
	technology: "",
	comment: "",
	platform_type: "mobile",
};

const initialState: IModals = {
	loading: false,
	formData: initialFormData,
};

const slice = createSlice({
	name: "platformIntegrator",
	initialState,
	reducers: {
		updatePlatformIntegratorForm(state: IModals, action: any): void {
			const formData = action.payload;
			// eslint-disable-next-line no-console
			// console.log(formData);
			if (formData) {
				state.formData = {
					...state.formData,
					alias: formData?.alias || null,
					name: formData?.name,
					country: formData?.country,
					technology: formData?.technology,
					comment: formData.comment,
					integration_mode: formData.integration_mode,
					mode: 1,
				};
			}
		},
		resetPlatformIntegratorForm(state: IModals): void {
			state.formData = initialFormData;
		},
	},
});

export const { actions, reducer } = slice;

export const { updatePlatformIntegratorForm, resetPlatformIntegratorForm } =
	actions;

export default reducer;
