/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

interface IModals {
	loading: boolean;
	graph: string;
}

const initialState: IModals = {
	loading: false,
	graph: null,
};

const slice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		fetchGraphBegin(state: IModals): void {
			state.loading = true;
		},
		fetchGraphSuccessful(state: IModals, action: any): void {
			state.loading = false;
			state.graph = action.payload;
		},
		fetchGraphFailure(state: IModals): void {
			state.loading = false;
			state.graph = null;
		},
	},
});

export const { actions, reducer } = slice;

export const { fetchGraphBegin, fetchGraphSuccessful, fetchGraphFailure } =
	actions;

export default reducer;
