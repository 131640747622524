/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { ITournamentBuilderFormData } from "types/data";

interface IModals {
	loading: boolean;
	formData: ITournamentBuilderFormData;
	step: number;
}

const initialFormData: ITournamentBuilderFormData = {
	alias: null,
	goal: "",
	game_alias: "",
	title: "",
	subtitle: "",
	tournament_type: 1,
	game_mode: 1,
	start_time: new Date(),
	end_time: null,
	timezone: null,
	// show_reward_setup: "",
	max_player: 500,
	/* prize: [
		{
			// alias: 1,
			rank_from: 1,
			rank_to: 1,
			prize_type: 1,
			quantity: 1,
			prize: "",
		},
		{
			// alias: 2,
			rank_from: 2,
			rank_to: 2,
			quantity: 1,
			prize: "",
			prize_type: 1,
		},
		{
			// alias: 3,
			rank_from: 3,
			rank_to: 3,
			quantity: 1,
			prize: "",
			prize_type: 1,
		},
		{
			// alias: 4,
			rank_from: 4,
			rank_to: 10,
			quantity: 1,
			prize: "",
			prize_type: 1,
		},
		{
			// alias: 5,
			rank_from: 11,
			rank_to: 200,
			quantity: 1,
			prize: "",
			prize_type: 1,
		},
	],
	no_rewards_checked: false, */
};

const initialState: IModals = {
	loading: false,
	formData: initialFormData,
	step: 0,
};

const slice = createSlice({
	name: "tournamentBuilder",
	initialState,
	reducers: {
		updateTournamentBuilderForm(state: IModals, action: any): void {
			const { formData, step } = action.payload;
			// eslint-disable-next-line no-console
			// console.log({ formData, step });
			if (formData) {
				state.formData = {
					...state.formData,
					...formData,
					/* prize: formData.prize
						? JSON.parse(JSON.stringify(formData.prize) as any)
						: initialFormData.prize, */
				};
			}
			if (step !== undefined && !Number.isNaN(step)) {
				state.step = step;
			}
		},
		resetTournamentBuilderForm(state: IModals): void {
			state.formData = initialFormData;
			state.step = 0;
			// state.games = [];
		},
	},
});

export const { actions, reducer } = slice;

export const { updateTournamentBuilderForm, resetTournamentBuilderForm } =
	actions;

export default reducer;
