import * as React from "react";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
// import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";

import { styled } from "@mui/system";

// import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// @mui material components
// import Grid from "@mui/material/Grid";
// import { styled } from "@mui/system";

// Material Dashboard 2 PRO React TS components

import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
// import MDAlert from "template/components/MDAlert";
// import MDButton from "template/components/MDButton";
import { toast } from "react-toastify";
// import FormField from "components/elements/FormField";

// import OptionBox from "components/elements/OptionBox";

// images

// steps component

import SingleGame from "components/games/SingleGame";
import { useEffect } from "react";
import fetchGames from "actions/gamesActions";
import { useDispatch, useSelector } from "react-redux";
import { FormProviderProps } from "react-hook-form";
import { updateTournamentBuilderForm } from "slices/tournamentBuilderSlice";
import { Divider } from "@mui/material";
// import MDButton from "template/components/MDButton";

const GamesStyle = styled("div")(({ theme }) => ({
	"& .pt-0": {
		paddingTop: "0 !important",
	},
	"& .my-0": {
		marginTop: "0 !important",
		marginBottom: "0 !important",
	},
	"& .games-container": {
		// flexGrow: 1,
		maxWidth: "28rem",
		// width: "100%",
		marginBottom: 5,
		// marginTop: 10,
		maxHeight: "18rem",
		overflowY: "scroll",
		overflowX: "hidden",
		"& .single-game-item": {
			border: "3px solid transparent",
			boxSizing: "content-box",
		},
		"& .selected-game": {
			borderColor: `${theme.palette.secondary.main}`,
			borderRadius: "8px",
		},
	},
	"*::-webkit-scrollbar": {
		width: "0.4em",
		backgroundColor: "#CFE8FC",
	},
	"*::-webkit-scrollbar-track": {
		// WebkitBoxShadow: "inset 0 0 6px blue",
		height: "10px",
	},
	"*::-webkit-scrollbar-thumb": {
		backgroundColor: "#87c6f8",
		borderRadius: "50px",
	},
}));

/* const AlertStyle = styled("div")(() => ({
	position: "absolute",
	zIndex: 1,
	width: "75%",
	top: "-80px",
	right: "20px",
	maxWidth: "540px",
})); */

interface IGame {
	alias: string;
	average_game_play: string;
	category: string;
	cover_image: string;
	global_active_playser: number;
	name: string;
	placement: number;
	play_link: string;
	price: number;
	price_currency: string;
	service: "premium" | "standard";
	slug: string;
	subscribed: boolean;
	thumbnail_image: string;
}

function SelectGame({ form }: { form: FormProviderProps }): JSX.Element {
	const dispatch = useDispatch();
	const { games, categories } = useSelector(
		(state: any) => state.gamesReducer
	);
	const { setValue, getValues } = form;
	const [gameCategory, setGameCategory] = React.useState<string>("all");
	const [selectedGame, setSelectedGame] = React.useState<IGame | null>(null);
	const handleChange = (event: SelectChangeEvent) => {
		setGameCategory(event.target.value as string);
	};

	const handleSelect = (id: any) => {
		const selectedItem = games.find((item: any) => item.alias === id);
		const data = {
			game_alias: selectedItem.alias,
		};
		dispatch(
			updateTournamentBuilderForm({
				formData: data,
			})
		);
		setValue("game_alias", selectedItem.alias);
		setSelectedGame(selectedItem);
		if (selectedItem?.service === "premium") {
			toast("You can use this Premium game in your 30-days free trial.", {
				autoClose: 1500,
				type: "warning",
				hideProgressBar: false,
				theme: "light",
			});
		}
	};

	useEffect(() => {
		dispatch(fetchGames());
	}, []);

	useEffect(() => {
		const preSelectedGameAlias = getValues("game_alias");
		if (preSelectedGameAlias) {
			const selectedItem = games.find(
				(item: any) => item.alias === preSelectedGameAlias
			);
			setSelectedGame(selectedItem);
		}
	}, [games]);

	return (
		<GamesStyle>
			<form>
				<MDBox>
					<MDBox width="82%" textAlign="center" mx="auto" my={4}>
						<MDBox mb={1}>
							<MDTypography variant="h4" fontWeight="regular">
								Select the game
							</MDTypography>
						</MDBox>

						<MDTypography variant="h5" fontWeight="regular">
							Play and choose the best game for your audience
						</MDTypography>
						<MDTypography
							variant="body2"
							color="text"
							fontStyle="italic"
						>
							You can always change this later
						</MDTypography>
					</MDBox>
					<MDBox mt={1.625}>
						{/* {selectedGame?.service === "premium" && (
							<Grid
								item
								container
								style={{ position: "relative" }}
							>
								<Grid lg={9} mx="auto">
									<AlertStyle>
										<MDAlert dismissible>
											<div
												style={{
													display: "flex",
													alignItems: "center",
												}}
											>
												<MDTypography
													variant="h6"
													color="white"
												>
													You can use this Premium
													game in your 30-days free
													trial.
												</MDTypography>
												<MDButton
													sx={{
														ml: 4,
													}}
													type="submit"
													variant="gradient"
													color="warning"
												>
													ADD FOR $15
												</MDButton>
											</div>
										</MDAlert>
									</AlertStyle>
								</Grid>
							</Grid>
						)} */}

						<MDBox
							sx={{
								display: "flex",
								justifyContent: "space-evenly",
								alignItems: "flex-start",
								flexDirection: "row",
								flexWrap: "wrap",
								// overflow: "hidden",
								// height: "100%",
								width: "100%",
							}}
						>
							<MDBox
								sx={{
									// minWidth: "100%",
									maxWidth: "8rem",
									width: "100%",
									// flexGrow: 1,
								}}
							>
								<FormControl fullWidth>
									<InputLabel id="demo-simple-select-label">
										Filter by genre
									</InputLabel>
									<Select
										labelId="game_category-label"
										id="game_category-select"
										value={gameCategory}
										name="game_category"
										label="Filter by genre"
										onChange={handleChange}
										IconComponent={KeyboardArrowDown}
										style={{
											minHeight: "50px",
											width: "100%",
											maxWidth: "10rem",
											boxShadow:
												"0px 2px 6px rgba(0, 0, 0, 0.25)",
											borderRadius: "8px",
										}}
									>
										<MenuItem key="all" value="all">
											All
										</MenuItem>
										{categories.map((category: any) => (
											<MenuItem
												key={category}
												value={category}
											>
												{category}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</MDBox>
							<MDBox
								className="games-container"
								sx={{
									display: "flex",
									justifyContent: "space-evenly",
									alignItems: "center",
									flexDirection: "row",
									flexWrap: "wrap",
									// flexShrink: 1,
									// flexGrow: 1,
									// overflow: "hidden",
									// height: "100%",
									// width: "100%",
								}}
							>
								{games
									.filter(
										(game: IGame) =>
											game.category === gameCategory ||
											gameCategory === "all"
									)
									.map((game: any) => (
										<MDBox
											key={game.alias}
											// item
											// sm={12}
											onClick={() =>
												handleSelect(game.alias)
											}
											className={
												selectedGame?.alias ===
												game.alias
													? "pt-0 single-game-item selected-game"
													: "pt-0 single-game-item"
											}
										>
											<SingleGame game={game} />
										</MDBox>
									))}
							</MDBox>
							<MDBox
								sx={{
									flexShrink: 1,
								}}
							>
								<Divider light={false} variant="inset" />
								<div>
									<MDTypography variant="h6">
										Game stats
									</MDTypography>
									<MDTypography variant="subtitle2">
										Name: <b>{selectedGame?.name}</b>
									</MDTypography>
									<MDTypography variant="subtitle2">
										Global active players:
										<b>
											{
												selectedGame?.global_active_playser
											}
										</b>
									</MDTypography>
									<MDTypography variant="subtitle2">
										Average game play:
										<b>{selectedGame?.average_game_play}</b>
									</MDTypography>
									<MDTypography variant="subtitle2">
										Placement:
										<b>{selectedGame?.placement}</b>
									</MDTypography>
								</div>
							</MDBox>
						</MDBox>
					</MDBox>
				</MDBox>
			</form>
		</GamesStyle>
	);
}

export default SelectGame;
