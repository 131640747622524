import { useCallback } from "react";
import _debounce from "lodash/debounce";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import MDTypography from "template/components/MDTypography";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import { updateUserInfo } from "actions/auth";
import Icon from "@mui/material/Icon";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { dateFormat } from "utils/date-format";

const BasicFormStyle = styled("div")(({ theme }) => ({
	padding: "25px",
	boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
	backgroundColor: theme.palette.white.main,
	borderRadius: "12px",
	"& .basic-form-header": {
		marginBottom: "35px",
	},
	"& .form-wrapper": {
		"& .MuiSelect-select": {
			padding: "0 0 5px!important",
		},
		"& .select-label": {
			marginLeft: "-15px",
		},
	},
}));

const schema = yup.object().shape({
	first_name: yup.string().required("first name is required"),
	last_name: yup.string().required("last Name is required"),
	gender: yup.number().required("gender is required"),
	confirm_email: yup.string().email(),
	location: yup.string().required("location is required"),
	phone_nummber: yup.string().required("phone number is required"),
	date_of_birth: yup.date().typeError("date is invalid").nullable(),
	email: yup
		.string()
		.email()
		.oneOf([yup.ref("confirm_email"), null], "emails must match")
		.required("email is required"),
});

export default function BasicInfoForm() {
	const dispatch = useDispatch();
	const { user } = useSelector((state: any) => state.auth);
	// const [formData, setFormData] = useState({ ...user });

	const form = useForm<{
		first_name: string;
		last_name: string;
		gender: number;
		location: string;
		phone_nummber: string;
		email: string;
		confirm_email: string;
		date_of_birth: string;
	}>({
		mode: "onSubmit",
		reValidateMode: "onChange",
		shouldFocusError: true,
		defaultValues: {
			first_name: user?.first_name,
			last_name: user?.last_name,
			gender: user?.gender,
			phone_nummber: user?.phone_nummber,
			location: user?.location,
			email: user?.email,
			confirm_email: user?.email,
			date_of_birth: user?.date_of_birth,
		},
		resolver: yupResolver(schema),
	});

	const {
		getValues,
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = form;

	const onSubmit = (data: any) => {
		const payload = { ...data };
		delete payload.confirm_email;
		dispatch(
			updateUserInfo({
				...payload,
				date_of_birth: dateFormat(data.date_of_birth, "yyyy-MM-dd"),
			})
		);
	};

	async function handleDebounceFn() {
		handleSubmit(onSubmit)();
	}

	const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

	function handleChange(
		event: any,
		field:
			| "email"
			| "first_name"
			| "last_name"
			| "confirm_email"
			| "gender"
			| "location"
			| "phone_nummber"
			| "date_of_birth"
	) {
		setValue(field, event.target.value);
		/* setFormData({
			...formData,
			[field]: event.target.value,
		}); */
		debounceFn();
	}

	return (
		<>
			<BasicFormStyle>
				<div className="basic-form-header">
					<MDTypography variant="h5">Basic Info</MDTypography>
				</div>
				<div className="form-wrapper">
					<form>
						<Grid container spacing={3} mb={4}>
							<Grid item lg={6}>
								<TextField
									{...register("first_name")}
									label="First Name"
									variant="standard"
									defaultValue={`${user?.first_name}`}
									onChange={(e) =>
										handleChange(e, "first_name")
									}
									fullWidth
								/>
								<ErrorMessage
									errors={errors}
									name="first_name"
									render={({ message }) => (
										<MDTypography
											variant="caption"
											color="secondary"
										>
											{message}
										</MDTypography>
									)}
								/>
							</Grid>
							<Grid item lg={6}>
								<TextField
									{...register("last_name")}
									label="Last Name"
									variant="standard"
									onChange={(e) =>
										handleChange(e, "last_name")
									}
									defaultValue={`${user?.last_name}`}
									fullWidth
								/>

								<ErrorMessage
									errors={errors}
									name="last_name"
									render={({ message }) => (
										<MDTypography
											variant="caption"
											color="secondary"
										>
											{message}
										</MDTypography>
									)}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={3} mb={4}>
							<Grid item lg={6}>
								<FormControl fullWidth>
									<InputLabel
										id="gender-select-label"
										className="select-label"
									>
										{` I’m `}
									</InputLabel>
									<Select
										labelId="gender-select-label"
										id="gender-simple-select"
										defaultValue={user?.gender}
										label="gender"
										variant="standard"
										IconComponent={KeyboardArrowDown}
										onChange={(e) =>
											handleChange(e, "gender")
										}
									>
										<MenuItem value={1} selected>
											Male
										</MenuItem>
										<MenuItem value={2}>Female</MenuItem>
										<MenuItem value={3}>Other</MenuItem>
										<MenuItem value={4}>
											Prefer not to say
										</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item lg={6}>
								<FormControl fullWidth>
									{/* <InputLabel
										id="birth-select-label"
										className="select-label"
									>
										Birth Date
									</InputLabel> */}
									<LocalizationProvider
										dateAdapter={AdapterDateFns}
									>
										<DatePicker
											label="Birth Date"
											{...register("date_of_birth")}
											value={getValues("date_of_birth")}
											onChange={(newValue) => {
												// setValue(newValue);
												handleChange(
													{
														target: {
															value: newValue,
														},
													},
													"date_of_birth"
												);
												// eslint-disable-next-line no-new
												/* console.log(
													dateFormat(
														newValue,
														"yyyy-mm-dd"
													)
												); */
											}}
											renderInput={(params: any) => (
												<TextField
													variant="standard"
													{...params}
												/>
											)}
										/>
									</LocalizationProvider>
								</FormControl>
								<ErrorMessage
									errors={errors}
									name="date_of_birth"
									render={({ message }) => (
										<MDTypography
											variant="caption"
											color="secondary"
										>
											{message}
										</MDTypography>
									)}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={3} mb={4}>
							<Grid item lg={6}>
								<TextField
									{...register("email")}
									label="Email"
									variant="standard"
									defaultValue={`${user?.email}`}
									onChange={(e) => handleChange(e, "email")}
									fullWidth
								/>
								<ErrorMessage
									errors={errors}
									name="email"
									render={({ message }) => (
										<MDTypography
											variant="caption"
											color="secondary"
										>
											{message}
										</MDTypography>
									)}
								/>
							</Grid>
							<Grid item lg={6}>
								<TextField
									{...register("confirm_email")}
									onChange={(e) =>
										handleChange(e, "confirm_email")
									}
									label="Confirm Email"
									variant="standard"
									defaultValue=""
									fullWidth
								/>
							</Grid>
						</Grid>
						<Grid container spacing={3} mb={3}>
							<Grid item lg={6}>
								<TextField
									label="Your location"
									variant="standard"
									defaultValue={`${user?.location}`}
									onChange={(e) =>
										handleChange(e, "location")
									}
									fullWidth
								/>
								<ErrorMessage
									errors={errors}
									name="location"
									render={({ message }) => (
										<MDTypography
											variant="caption"
											color="secondary"
										>
											{message}
										</MDTypography>
									)}
								/>
							</Grid>
							<Grid item lg={6}>
								<TextField
									label="Phone Number"
									variant="standard"
									defaultValue={`${user?.phone_nummber}`}
									onChange={(e) =>
										handleChange(e, "phone_nummber")
									}
									fullWidth
								/>
								<ErrorMessage
									errors={errors}
									name="phone_nummber"
									render={({ message }) => (
										<MDTypography
											variant="caption"
											color="secondary"
										>
											{message}
										</MDTypography>
									)}
								/>
							</Grid>
						</Grid>
					</form>
				</div>
			</BasicFormStyle>
			<MDTypography
				sx={{
					textAlign: "center",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					mt: 2,
				}}
				variant="h6"
				className="title"
			>
				<Icon>check</Icon>
				&nbsp;Automatically saved
			</MDTypography>
		</>
	);
}
