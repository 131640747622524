import { styled } from "@mui/system";
import MDTypography from "template/components/MDTypography";
import MDButton from "template/components/MDButton";

const DeactivateStyle = styled("div")(({ theme }) => ({
	padding: "25px",
	boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
	backgroundColor: theme.palette.white.main,
	borderRadius: "12px",
	"& .deactivate-wrapper": {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	"& .button-group": {
		marginBottom: "0",
	},
}));

export default function DeactivateAccount() {
	return (
		<DeactivateStyle>
			<div className="deactivate-wrapper">
				<div className="deactivate-info">
					<MDTypography variant="h5" lineHeight={2}>
						Deactivate Account
					</MDTypography>
					<MDTypography
						variant="body2"
						color="text"
						fontSize="medium"
					>
						Once you delete your account, there is no going back.
						Please be certain
					</MDTypography>
				</div>
				<div className="button-group">
					<MDButton variant="outlined" color="dark">
						Deactivate Account
					</MDButton>
				</div>
			</div>
		</DeactivateStyle>
	);
}
