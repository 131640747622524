import { styled } from "@mui/system";
import closeImg from "assets/images/icons/close.png";
import MDTypography from "template/components/MDTypography";

const CloseBtnStyle = styled("div")(() => ({
	"& .button-group": {
		display: "flex",
		position: "absolute",
		top: "20px",
		right: "20px",
		cursor: "pointer",
		backgroundColor: "transparent",
		border: "0",
		padding: "0",
		" img": {
			maxWidth: "20px",
			maxHeight: "20px",
			marginLeft: "8px",
		},
	},
}));

export default function CloseButton(props: any) {
	const { handleClose, buttonText } = props;
	return (
		<CloseBtnStyle>
			<button
				className="button-group"
				type="button"
				onClick={() => handleClose()}
			>
				<MDTypography variant="body2" color="white" fontSize="small">
					{buttonText}
				</MDTypography>
				<img src={closeImg} alt="close" />
			</button>
		</CloseBtnStyle>
	);
}
