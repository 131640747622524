import { useState } from "react";
// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import MDButton from "template/components/MDButton";
import MDAlert from "template/components/MDAlert";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import numberal from "numeral";
import ICON_HELP from "assets/images/icons/help.svg";
import Tooltip from "@mui/material/Tooltip";

// Declaring props types for PricingCard
interface Props {
	config?: any;
	subtitle?: string | null;
	note?: string | null;
	onChangeSlider: any;
	hideSlider?: boolean;
	color?:
		| "primary"
		| "secondary"
		| "info"
		| "success"
		| "warning"
		| "error"
		| "light"
		| "dark"
		| "white";
	badge: {
		color:
			| "primary"
			| "secondary"
			| "info"
			| "success"
			| "warning"
			| "error"
			| "light"
			| "grey-400"
			| "dark";
		label: string;
	};
	price: {
		currency: string;
		value: string;
		type?: string;
	};
	discountConfig?: {
		text: string;
		color: string;
	};
	sliderConfig?: {
		disabled: boolean;
	};
	specifications: {
		label: string;
		includes?: boolean;
		help?: {
			text?: string;
		};
	}[];
	action: {
		type: "external" | "internal";
		route: string;
		label: string;
		disabled?: boolean;
		color:
			| "primary"
			| "secondary"
			| "info"
			| "success"
			| "warning"
			| "error"
			| "light"
			| "dark";
	};
	shadow?: boolean;
	[key: string]: any;
}

const PrettoSlider = styled(Slider)(({ theme }) => {
	const { palette } = theme;
	// console.log(palette);
	return {
		color: palette.warning.main,
		height: 5,
		"& .MuiSlider-track": {
			border: "none",
			height: "0.4rem",
			backgroundColor: palette.warning.main,
		},
		"& .MuiSlider-rail": {
			height: "0.4rem",
		},
		"& .MuiSlider-thumb": {
			height: 15,
			width: 15,
			backgroundColor: palette.warning.main,
			border: "5px solid currentColor",
			"&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
				boxShadow: "inherit",
			},
			"&:before": {
				display: "none",
			},
		},
		"& .MuiSlider-valueLabel": {
			lineHeight: 1.2,
			fontSize: 12,
			background: "unset",
			padding: 0,
			width: 32,
			height: 32,
			borderRadius: "50% 50% 50% 0",
			backgroundColor: "#52af77",
			transformOrigin: "bottom left",
			transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
			"&:before": { display: "none" },
			"&.MuiSlider-valueLabelOpen": {
				transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
			},
			"& > *": {
				transform: "rotate(45deg)",
			},
		},
	};
});

const AlertStyle = styled("div")(() => ({
	position: "absolute",
	zIndex: 1,
	width: "calc(100% + 120px)",
	top: "35%",
	left: "-50px",
	maxWidth: "540px",
}));

function PricingCard({
	config,
	// period,
	onChangeSlider,
	color,
	badge,
	price,
	specifications,
	action,
	shadow,
	hideSlider,
	subtitle,
	sliderConfig,
	discountConfig,
	note,
}: Props): JSX.Element {
	const [openAlert, setOpenAlert] = useState(false);
	const handlePopupMessage = () => {
		setOpenAlert(true);
	};

	const renderSpecifications = specifications.map(
		({ label, includes, help }) => (
			<MDBox key={label} display="flex" alignItems="center" p={1}>
				<MDBox
					display="flex"
					justifyContent="center"
					alignItems="center"
					width="1.5rem"
					height="1.5rem"
					mr={2}
					mt={-0.125}
				>
					<MDTypography
						variant="body1"
						color={color === "white" ? "inherit" : "white"}
						sx={{ lineHeight: 0 }}
					>
						<Icon>{includes ? "done" : "remove"}</Icon>
					</MDTypography>
				</MDBox>
				<MDTypography
					variant="body2"
					color={color === "white" ? "inherit" : "white"}
					fontWeight="regular"
				>
					{label}&nbsp;
					{help && (
						<Tooltip placement="right-start" title={help?.text}>
							<img src={ICON_HELP} alt="" />
						</Tooltip>
					)}
				</MDTypography>
			</MDBox>
		)
	);

	const getPriceType = () => {
		if (price?.type) {
			if (price.type === "mo") {
				return "mo";
			}
			if (price.type === "ye") {
				return "yr";
			}
			if (price.type === "qu") {
				return "qtr";
			}
		}
		return "";
	};

	/* return numberal(config.price).format(
			config.price < 1000 && config.price % 1 === 0 ? "0a" : "0.00a"
		); */
	const renderPrice = () => numberal(config.price).format("0,0");
	return (
		<Card
			sx={{
				boxShadow: ({ boxShadows: { lg } }) => (shadow ? lg : "none"),
				height: "100%",
			}}
			key={config.name}
		>
			<MDBox
				bgColor={color}
				variant={color === "white" ? "contained" : "gradient"}
				borderRadius="xl"
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
				}}
			>
				<MDBox
					bgColor={badge.color}
					width="max-content"
					px={4}
					pt={0}
					pb={0.5}
					mx="auto"
					mt={-1.375}
					borderRadius="section"
					lineHeight={1}
					sx={{
						boxShadow: ({ boxShadows: { xs } }) => xs,
					}}
				>
					<MDTypography
						variant="caption"
						textTransform="uppercase"
						fontWeight="medium"
						color={
							badge.color === "light" ||
							badge.color === "grey-400"
								? "dark"
								: "white"
						}
					>
						{badge.label}
					</MDTypography>
				</MDBox>
				{discountConfig && (
					<MDTypography
						sx={{
							fontSize: "0.7rem",
							paddingY: "0.2rem",
							paddingX: "0.3rem",
							position: "absolute",
							top: 30,
							background: ({ palette }: any) =>
								palette[discountConfig.color]?.main,
						}}
						color="white"
						variant="caption"
					>
						{discountConfig.text}
					</MDTypography>
				)}
				<MDBox pt={3} pb={2} px={2} textAlign="center">
					<MDBox my={1}>
						<MDTypography
							variant="h3"
							color={color === "white" ? "inherit" : "white"}
						>
							<MDTypography
								display="inline"
								component="small"
								variant="h5"
								color="inherit"
								verticalAlign="top"
							>
								{price.currency}
							</MDTypography>
							<span key={config.name}>
								{config.placeholder
									? config.placeholder
									: renderPrice()}
							</span>
							<MDTypography
								display="inline"
								component="small"
								variant="h5"
								color="inherit"
							>
								{price?.type ? `/${getPriceType()}` : ""}
							</MDTypography>
						</MDTypography>
					</MDBox>
				</MDBox>
				<MDBox
					width="100%"
					textAlign="center"
					sx={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<MDBox width="80%" textAlign="center" mb={2}>
						{!hideSlider && (
							<MDTypography
								sx={{
									mb: -2,
								}}
								textTransform="uppercase"
								variant="h6"
								color={color === "dark" ? "white" : "inherit"}
							>
								{numberal(config.monthlyPlayerSessions).format(
									"0,0"
								)}
							</MDTypography>
						)}
						{hideSlider ? null : (
							<PrettoSlider
								size="medium"
								defaultValue={
									config.minimumMonthlyPlayerSessions
								}
								aria-label="Small"
								// valueLabelDisplay="auto"
								// color="primary"
								disabled={sliderConfig.disabled}
								value={config.monthlyPlayerSessions}
								min={config.minimumMonthlyPlayerSessions}
								sx={{
									mb: -2,
								}}
								step={1000}
								max={config.maxPlayers}
								color="secondary"
								onChange={(event, value) =>
									onChangeSlider(config.name, value)
								}
							/>
						)}
						<MDTypography
							textTransform="uppercase"
							variant="overline"
							color={color === "white" ? "inherit" : "white"}
						>
							{subtitle || "Monthly Active players "}
							{!subtitle && (
								<Tooltip
									placement="right-start"
									title="Unique app visitors who land on Goama platform and starts playing a game are called Active Players."
								>
									<img src={ICON_HELP} alt="" />
								</Tooltip>
							)}
						</MDTypography>
					</MDBox>
				</MDBox>
				<MDBox
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
					height="100%"
					pb={3}
					px={3}
				>
					<MDBox flexGrow={1}>{renderSpecifications}</MDBox>
					<MDTypography variant="subtitle2" sx={{ fontWeight: 400 }}>
						{note}
					</MDTypography>
					{action.type === "internal" ? (
						<MDBox mt={3}>
							<MDButton
								disabled={action.disabled}
								// component={Link}
								component="Button"
								to={action.route}
								variant="gradient"
								color={action.color}
								fullWidth={false}
								size="large"
								onClick={handlePopupMessage}
							>
								{action.label}&nbsp;
								<Icon sx={{ fontWeight: "bold" }}>
									arrow_forward
								</Icon>
							</MDButton>
						</MDBox>
					) : (
						<MDBox mt={3}>
							<MDButton
								disabled={action.disabled}
								component="a"
								rel="noreferrer"
								variant="gradient"
								color={action.color}
								fullWidth={false}
								size="large"
								href={action.route}
								target="_blank"
							>
								{action.label}&nbsp;
								<Icon sx={{ fontWeight: "bold" }}>
									arrow_forward
								</Icon>
							</MDButton>
						</MDBox>
					)}
				</MDBox>
				{openAlert && (
					<AlertStyle>
						<MDAlert
							onDismiss={() => {
								setOpenAlert(false);
							}}
							dismissible
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<MDTypography
									variant="h6"
									color="white"
									fontWeight="regular"
								>
									Avail 30-days free trial, before enrolling
									for this pack
								</MDTypography>
							</div>
						</MDAlert>
					</AlertStyle>
				)}
			</MDBox>
		</Card>
	);
}

// Declaring default props for PricingCard
PricingCard.defaultProps = {
	color: "white",
	shadow: true,
	hideSlider: false,
	config: {},
	subtitle: null,
	discountConfig: null,
	sliderConfig: {
		disabled: false,
	},
	note: null,
	// period: "monthly",
};

export default PricingCard;
