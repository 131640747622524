/* eslint-disable camelcase */
/* eslint-disable no-async-promise-executor */
import {
	setTokens,
	clearTokens,
	onSignInBegin,
	onSignInFail,
	setUserInfo,
} from "slices/authSlice";
import {
	startFullScreenLoading,
	stopFullScreenLoading,
} from "slices/modalSlice";
import { resetTournamentBuilderForm } from "slices/tournamentBuilderSlice";
import { AppThunk } from "store";
import axiosClient from "utils/axios";
import History from "utils/history/History";

import analytics, {
	defaultEvents,
	AmplitudeAnalyticsEngine,
	GLOBAL_AMPLITUDE_KEY,
} from "utils/analytics";
import { clearPlatforms } from "slices/platformsSlice";
import { resetPlatformIntegratorForm } from "slices/platformIntegratorSlice";
import { clearWorkspaces } from "slices/workspaceSlice";

/**
 * User Login
 * @param email
 * @param password
 * @returns Promise<void>
 */
export const login =
	(email: string, password: string): AppThunk =>
	(dispatch): Promise<void> =>
		new Promise(async (resolve, reject) => {
			try {
				dispatch(onSignInBegin());
				const response: any = await axiosClient.post("auth/token/", {
					email,
					password,
				});

				analytics.addProvider({
					name: "default",
					enabledEvents: defaultEvents,
					engines: [
						new AmplitudeAnalyticsEngine(
							GLOBAL_AMPLITUDE_KEY,
							"default"
						),
					],
				});
				analytics.setUserId(email);

				dispatch(setTokens(response.data));
				resolve(response.data);
			} catch (error: any) {
				dispatch(onSignInFail());
				// console.log("error login", error);
				// error.handleGlobally(error);
				reject(error.data);
			}
		});

/**
 * User Log Out
 * @returns Promise<void>
 */
export const logout =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(clearTokens());
		dispatch(resetTournamentBuilderForm());
		dispatch(clearPlatforms());
		dispatch(resetPlatformIntegratorForm());
		dispatch(clearWorkspaces());
		History.navigate("/login");
	};

export const signUp =
	({
		first_name,
		last_name = "",
		email,
		password,
	}: {
		first_name: string;
		last_name: string;
		email: string;
		password: string;
	}): AppThunk =>
	// eslint-disable-next-line no-unused-vars
	async (dispatch): Promise<void> =>
		new Promise(async (resolve, reject) => {
			try {
				const response: any = await axiosClient.post("auth/register/", {
					first_name,
					last_name,
					email,
					password,
				});
				resolve(response.data);
			} catch (error: any) {
				reject(error);
			}
		});

/**
 * Google login url to be redirected to
 * @returns Promise<void>
 */
export const fetchGoogleAuthUrl = async () => {
	try {
		const response: any = await axiosClient.post("auth/google-oauth2/");
		return response.data;
	} catch (error: any) {
		return error;
	}
};

/**
 * Google login
 * @param params
 * @returns
 */
export const authorizeGoogle =
	(params: any): AppThunk =>
	async (dispatch): Promise<void> => {
		// console.log(params);
		// dispatch(onSignInBegin());
		await dispatch(startFullScreenLoading());
		try {
			const response: any = await axiosClient.post(
				"auth/google-oauth2/authorize/",
				{ ...params }
			);
			// eslint-disable-next-line no-console
			console.log({ response });
			dispatch(
				setTokens({
					access: response.data.token,
				})
			);
			await dispatch(stopFullScreenLoading());
			History.navigate("/dashboard");
			return response.data;
		} catch (error: any) {
			dispatch(stopFullScreenLoading());
			History.navigate("/login");
			// dispatch(onSignInFail());
			// reject(error);
			return error;
		}
	};

export const fetchUserInfo =
	(): AppThunk =>
	async (dispatch): Promise<void> =>
		// eslint-disable-next-line no-new
		new Promise(async (resolve, reject) => {
			try {
				const response: any = await axiosClient.get("users/me/");
				dispatch(setUserInfo(response.data));
				resolve(response.data);
			} catch (error: any) {
				reject(error);
			}
		});

export const updateUserInfo =
	(params: any): AppThunk =>
	async (dispatch): Promise<void> =>
		// eslint-disable-next-line no-new
		new Promise(async (resolve, reject) => {
			try {
				const response: any = await axiosClient.patch(
					"users/me/",
					params
				);
				dispatch(setUserInfo(response.data));
				resolve(response.data);
			} catch (error: any) {
				reject(error);
			}
		});

export const updateUserPassword =
	({
		old_password,
		new_password,
	}: {
		old_password: string;
		new_password: string;
	}): AppThunk =>
	async (): Promise<void> =>
		// eslint-disable-next-line no-new
		new Promise(async (resolve, reject) => {
			try {
				const response: any = await axiosClient.patch(
					"users/password/reset/",
					{
						old_password,
						new_password,
					}
				);
				// dispatch(setUserInfo(response.data));
				resolve(response.data);
			} catch (error: any) {
				reject(error);
			}
		});
