import { Dispatch } from "@reduxjs/toolkit";
import {
	fetchGamesBegin,
	fetchGamesFailure,
	fetchGamesSuccessful,
} from "slices/gamesSlice";
import axiosClient from "utils/axios";

export const fetchGames =
	(): any =>
	async (dispatch: Dispatch): Promise<void> =>
		// eslint-disable-next-line no-new
		new Promise((resolve, reject) => {
			dispatch(fetchGamesBegin());
			axiosClient
				.get("tournament-games/")
				.then((response: any) => {
					dispatch(fetchGamesSuccessful(response.data));
					resolve(response.data);
				})
				.catch((error: any) => {
					dispatch(fetchGamesFailure());
					reject(error);
				});
		});

export default fetchGames;
