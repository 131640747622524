import axiosInstance from "axios";
import { handleAjaxError } from "./ajax-error-handler";
import { getBaseUrl } from "./base-url";
import { store } from "../store";

/**
 * Create Axios Instance
 * @type {AxiosInstance}
 */
const axios = axiosInstance.create({
	baseURL: `${getBaseUrl()}/api/v1/`,
	timeout: 10000,
	params: {}, // do not remove this, its added to add params later in the config
});

/**
 * Provides Http Headers
 * @returns {{Authorization: string, 'Content-Type': string}|{}}
 */
function authHeaderProvider() {
	// * return authorization header with jwt token
	try {
		// let token = null;
		const {
			auth: {
				token: { access = null },
			},
		} = store.getState();
		if (access) {
			return {
				Authorization: `Bearer ${access}`,
			};
		}
		return {};
	} catch (e) {
		return {};
	}
}

// * declare a request interceptor
axios.interceptors.request.use(
	(config: any) => {
		const cfg = config;
		const header = authHeaderProvider();
		if (header && header.Authorization) {
			cfg.headers.Authorization = header.Authorization;
		}
		cfg.headers["Content-Type"] = "application/json";
		return config;
	},
	(error: any) => {
		// Promise.reject(error);
		throw error;
	}
);

// * declare a response interceptor
axios.interceptors.response.use(
	(response: any) => response, // do something with the response data
	(error: any) => Promise.reject(handleAjaxError(error.response))
); // handle the response error

/* export const get = async (url) => {
  try {
    return await axios.get(url);
  } catch (error) {
    // handleError(error);
    throw error.response;
  }
};

export const post = async (url, params) => {
  try {
    return await axios.post(url, { ...params });
  } catch (error) {
    // handleError(error);
    throw error.response;
  }
};

export const patch = async (url, params) => {
  try {
    return await axios.patch(url, { ...params });
  } catch (error) {
    // handleError(error);
    throw error.response;
  }
};

export const remove = async (url) => {
  try {
    return await axios.delete(url);
  } catch (error) {
    // handleError(error);
    throw error.response;
  }
}; */
const axiosClient = axios;
export default axiosClient;
