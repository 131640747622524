import React from "react";
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";

function PageBanner({ backgroundImage = "", color = "secondary", title = "" }) {
	return (
		<MDBox
			display="flex"
			alignItems="center"
			minHeight="11.75rem"
			borderRadius="xl"
			mb={2}
			sx={{
				backgroundImage: ({
					functions: { rgba, linearGradient },
					palette: { gradients },
				}) =>
					`${linearGradient(
						rgba(gradients[color].main, 0.6),
						rgba(gradients[color].state, 0.6)
					)}, url(${backgroundImage})`,
				backgroundSize: "cover",
				backgroundPosition: "50%",
				overflow: "hidden",
			}}
		>
			<MDBox ml={3}>
				<MDTypography variant="h3" fontWeight="bold" color="light">
					{title}
				</MDTypography>
			</MDBox>
		</MDBox>
	);
}

export default PageBanner;
