/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

interface IModals {
	loading: boolean;
	games: [];
	categories: string[];
}

const initialState: IModals = {
	loading: false,
	games: [],
	categories: [],
};

const slice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		fetchGamesBegin(state: IModals): void {
			state.loading = true;
		},
		fetchGamesSuccessful(state: IModals, action: any): void {
			state.loading = false;
			state.games = action.payload;
			const cat: string[] = [];
			action.payload.forEach((game: any) => {
				if (!cat.includes(game.category)) {
					cat.push(game.category);
				}
			});
			state.categories = [...cat];
		},
		fetchGamesFailure(state: IModals): void {
			state.loading = false;
			state.games = [];
		},
	},
});

export const { actions, reducer } = slice;

export const { fetchGamesBegin, fetchGamesSuccessful, fetchGamesFailure } =
	actions;

export default reducer;
