/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/order */
import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import Confetti from "react-confetti";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
import MDButton from "template/components/MDButton";

// NewUser layout schemas for form and form feilds
import { useWindowSize } from "react-use";
import validations from "./schemas/validations";

// NewUser page components
import Workspace from "./steps/Workspace";
import Platform from "./steps/Platform";
import Objective from "./steps/Objective";
import CongratulationsMessage from "../../../components/modals/CongratulationsMessage";
import avatarThumbnail from "assets/images/avatars/dummy_user.png";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axiosClient from "utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkspaces } from "actions/workspaces";
import { styled } from "@mui/system";
import analytics from "utils/analytics";

const StepperStyle = styled("div")(({ theme }) => ({
	"& .MuiStepper-root": {
		background: `linear-gradient(180deg, ${theme.palette.gradients.primary.main} 0%, ${theme.palette.gradients.primary.state} 100%)`,
		".MuiStepIcon-root": {
			width: " 0.8125rem",
			height: "0.8125rem",
		},
	},
	// ".MuiSt"
}));

function getSteps(): string[] {
	return ["WORKSPACE", "PLATFORM", "OBJECTIVE"];
}

function WorkspaceSteps(props: any): JSX.Element {
	const { width, height } = useWindowSize();
	const dispatch = useDispatch();
	const [activeStep, setActiveStep] = useState(0);
	const [loading, setLoading] = useState<boolean>(false);
	const { token } = useSelector((state: any) => state.auth);
	const steps = getSteps();
	// const { formId, formField } = form;
	const currentValidation = validations[activeStep];
	const isLastStep = activeStep === steps.length - 1;
	const [finishStep, setFinishStep] = useState<boolean>(false);
	// const handleFinishStep = () => setFinishStep(true);

	const { handleClose } = props;

	const handleBack = () => setActiveStep(activeStep - 1);

	const form = useForm<{
		name: string;
		profile: string;
		industry: string;
		role: string;
		size: string;
		platform_type: string;
		usages: string[];
	}>({
		defaultValues: {
			name: "",
			profile: "",
			industry: "",
			role: "",
			size: "",
			platform_type: "",
			usages: [],
		},
		resolver: yupResolver(currentValidation),
	});

	const onSubmit = (data: any) => {
		// eslint-disable-next-line no-console
		// console.log({ data });
		const formData = new FormData();
		formData.append("name", data.name);
		formData.append("profile", data.profile);
		formData.append("industry", data.industry);
		formData.append("creator_role", data.role);
		formData.append("size", data.size);
		formData.append("platform_type", data.platform_type);
		formData.append("usages", data.usages);

		if (activeStep === 0) {
			analytics.complete_workspace_page_1({
				workspace: data.name,
				company_size: data.size,
				industry: data.industry,
				department: data.role,
			});
		}

		if (activeStep === 1) {
			analytics.selectPlatform({
				platform_type: data.platform_type,
				mobile_app_url: data.mobile_app_url,
				both_apple_google: data.both_apple_google,
				website_url: data.website_url,
				reason: data.reason,
				phone_number: data.phone_number,
			});
		}

		if (activeStep === 2) {
			analytics.selectObjectives({ objectives: data.usages });
		}

		if (activeStep === steps.length - 1) {
			setLoading(true);
			axiosClient
				.post("workspaces/", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${token}`,
					},
				})
				.then(() => {
					dispatch(fetchWorkspaces());
					// eslint-disable-next-line no-console
					// console.log({ res });
					setFinishStep(true);
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			setActiveStep(activeStep + 1);
		}
	};

	function getStepContent(stepIndex: number): JSX.Element {
		switch (stepIndex) {
			case 0:
				return <Workspace form={form} />;
			case 1:
				return <Platform form={form} />;
			case 2:
				return <Objective form={form} />;
			default:
				return null;
		}
	}
	const { user } = useSelector((state: any) => state.auth);

	/* const {
		formState: { errors },
	} = form; */

	return (
		<FormProvider {...form}>
			{finishStep ? (
				<>
					<Confetti
						width={width}
						height={height}
						recycle={false}
						numberOfPieces={1000}
						style={{
							position: "absolute" as "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
						}}
					/>
					<CongratulationsMessage
						buttonText="GO TO DASHBOARD"
						description="Your Workspace is already set"
						header={
							<span>
								Congratulations <b>{user?.first_name}!</b>
							</span>
						}
						closeModal={handleClose}
						imageSrc={user?.profile_pic || avatarThumbnail}
					/>
				</>
			) : (
				<Card>
					<MDBox mx={2} mt={-3}>
						<StepperStyle>
							<Stepper activeStep={activeStep} alternativeLabel>
								{steps.map((label) => (
									<Step key={label}>
										<StepLabel>{label}</StepLabel>
									</Step>
								))}
							</Stepper>
						</StepperStyle>
					</MDBox>
					<MDBox m={3}>
						{getStepContent(activeStep)}
						<MDBox
							mt={2}
							display="flex"
							justifyContent="space-between"
						>
							{activeStep === 0 ? (
								<MDBox />
							) : (
								<MDButton
									variant="gradient"
									color="light"
									onClick={handleBack}
									style={{ maxHeight: "40px" }}
								>
									Prev
								</MDButton>
							)}
							{isLastStep ? (
								<MDButton
									form={steps[activeStep]}
									loading={loading}
									disabled={loading}
									// disabled={Object.keys(errors).length !== 0}
									// disabled={isSubmitting}
									type="submit"
									variant="gradient"
									color="info"
									onClick={form.handleSubmit(onSubmit)}
									// onClick={handleFinishStep}
								>
									Finish
								</MDButton>
							) : (
								<MDButton
									sx={{
										mr: 2,
										mb: 2,
									}}
									onClick={form.handleSubmit(onSubmit)}
									form="WORKSPACE"
									// disabled={Object.keys(errors).length !== 0}
									type="submit"
									variant="gradient"
									color="info"
								>
									Next
								</MDButton>
							)}
						</MDBox>
					</MDBox>
				</Card>
			)}
		</FormProvider>
	);
}

export default WorkspaceSteps;
