import { useEffect, useState } from "react";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "template/components/MDBox";
import MDButton from "template/components/MDButton";
// import Icon from "@mui/material/Icon";
import DashboardLayout from "template/examples/LayoutContainers/DashboardLayout";
import bannerImg from "assets/images/banners/unsplash_CzShzocZtW0.png";
import helpImg from "assets/images/help.jpg";
import PageBanner from "components/Banner/PageBanner";
import LockBlock from "components/elements/LockBlock";
// import ActionCard from "components/cards/ActionCard";
import DESKTOP_ICON from "assets/images/icons/desktop-color.png";
import modalImg from "assets/images/next.png";
import { Grid, Icon } from "@mui/material";
import { useDispatch } from "react-redux";
import {
	openPlatformIntegratorModal,
	openTournamentBuilderModal,
} from "slices/modalSlice";
import { fetchPlatforms, unlockPlatform } from "actions/platformActions";
import { useSelector } from "store";
import MDTypography from "template/components/MDTypography";
import { fetchTournaments } from "actions/tournamentActions";
//  { pluralize } from "utils";
import { useNavigate } from "react-router-dom";
import {
	resetPlatformIntegratorForm,
	updatePlatformIntegratorForm,
} from "slices/platformIntegratorSlice";
//  { TOURNAMENT_STATUS } from "constants/constants";
import { dateFormat, removeTimezoneFromDateString } from "utils/date-format";
import analytics, { EVENT_TYPES } from "utils/analytics";
import AlertMessage from "components/modals/AlertMessage";
import VideoCard from "components/cards/VideoCard";
import IntegrationCard from "components/cards/IntegrationCard";
import TrialDurationAlert from "components/alerts/TrialDurationAlert";
import WelcomeModal from "components/modals/WelcomeModal";

// const pdfLink =
// 	"https://games.gogames.io/pp-media/Platform_Integration_Doc_v1.pdf";

export default function Integrations() {
	const navigate = useNavigate();
	const [openDialog, setOpenDialog] = useState(false);
	const { user } = useSelector((state: any) => state.auth);
	const { platforms, loading: platformLoading } = useSelector(
		(state: any) => state.platformsReducer
	);
	const { tournaments } = useSelector(
		(state: any) => state.tournamentsReducer
	);
	const { workspaces } = useSelector((state: any) => state.workspaces);
	const dispatch = useDispatch();
	useEffect(() => {
		analytics.clickIntegration({});
		dispatch(fetchPlatforms((workspaces && workspaces[0]?.alias) || ""));
		dispatch(fetchTournaments());
	}, []);
	const platform =
		platforms && platforms.length > 0 && platforms[0] ? platforms[0] : null;
	const workspace =
		workspaces && workspaces.length > 0 && workspaces[0]
			? workspaces[0]
			: null;
	// const countDraftTournaments = () => {
	// 	const list = tournaments.filter(
	// 		(tournament: any) => tournament.status === TOURNAMENT_STATUS.DRAFT
	// 	);
	// 	return list.length;
	// };
	// const countScheduledTournaments = () => {
	// 	const list = tournaments.filter(
	// 		(tournament: any) =>
	// 			tournament.status === TOURNAMENT_STATUS.SCHEDULED
	// 	);
	// 	return list.length;
	// };
	// const numberOfDraftTournament = countDraftTournaments();
	// const numberOfScheduledTournament = countScheduledTournaments();
	// const [userLock, setUserUnlock] = useState<boolean>(true);

	const [open, setOpen] = useState<boolean>(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const bookACallUrl =
		"https://meetings.hubspot.com/aakanksha-singh/need-help";

	const bookAcallAction = () => {
		window.open(bookACallUrl, "_blank");
	};

	return (
		<>
			<AlertMessage
				title="Please create a tournament first"
				message="You have to create a tournament before you can add a platform."
				openDialog={openDialog}
				handleConfirm={() => {
					setOpenDialog(false);
					dispatch(openTournamentBuilderModal());
				}}
				handleCancel={() => setOpenDialog(false)}
				cancelButtonText="cancel"
				successButtonText="create"
			/>

			<WelcomeModal
				open={open}
				handleClose={handleClose}
				title="Congratulations! You have succesfully unlocked your Unique URL Can’t wait to see you go live! "
				subTitle="Now, Choose how you wish to Integrate."
				image={modalImg}
				buttonText="ok"
				modalButtonAction={() => {
					handleClose();
				}}
			/>

			<DashboardLayout>
				<DashboardNavbar />
				{/* <TournamentBuilderModal /> */}
				<MDBox>
					<TrialDurationAlert />
					<PageBanner
						backgroundImage={bannerImg}
						title="Integrations"
					/>
					<MDBox
						sx={{
							mt: -2,
							mb: 1,
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
							cursor: "pointer",
						}}
						onClick={() => {
							navigate("/dashboard");
						}}
					>
						<MDTypography color="secondary">
							STEPS GUIDE
						</MDTypography>
						<Icon fontSize="small" color="inherit">
							keyboard_double_arrow_right
						</Icon>
					</MDBox>
					<MDBox mt={5} py={5}>
						<Grid container>
							<Grid item xl={6} lg={8} md={9} sm={12} xs={12}>
								{(!platform && (
									<IntegrationCard
										color="secondary"
										image={DESKTOP_ICON}
										title="My Integration Details"
										description="Claim your unique URL using
											which you can integrate
											Games/Tournaments in your Mobile
											App/Website."
										footerSubtitle="See video to Know more"
										footerButton={{
											label: "GET INTEGRATION DETAILS",
											color: "secondary",
											onClick: () => {
												dispatch(
													resetPlatformIntegratorForm()
												);
												if (tournaments.length > 0) {
													analytics.triggerEvent(
														EVENT_TYPES.GET_INTEGRATION_DETAILS_BUTTON,
														{}
													);
													analytics.enterIntegration(
														{}
													);
													dispatch(
														openPlatformIntegratorModal()
													);
												} else {
													setOpenDialog(true);
												}
											},
										}}
										// actionButtons={{
										// 	disabledButtons: {
										// 		delete: true,
										// 	},
										// 	action: (type: string) => {
										// 		if (type) {
										// 			// eslint-disable-next-line no-console
										// 			console.log(type);
										// 			switch (type) {
										// 				case "edit":
										// 					dispatch(
										// 						openPlatformIntegratorModal()
										// 					);
										// 					if (platform) {
										// 						dispatch(
										// 							updatePlatformIntegratorForm(
										// 								platform as any
										// 							)
										// 						);
										// 					}
										// 					break;
										// 				case "help":
										// 					window.open(
										// 						"https://goama.com/goama/contact/",
										// 						"_blank"
										// 					);
										// 					break;
										// 				case "download":
										// 					window.open(
										// 						"https://games.gogames.io/pp-media/Platform_Integration_Doc_v1.pdf",
										// 						"_blank"
										// 					);
										// 					break;
										// 				default:
										// 					break;
										// 			}
										// 		}
										// 	},
										// }}
									/>
								)) || (
									<IntegrationCard
										color="secondary"
										image={DESKTOP_ICON}
										title={`${
											user?.unlock_platform
												? `${platform?.name}`
												: "Unlock your unique URL"
										}`}
										description={
											<LockBlock
												unlock={user?.unlock_platform}
												url={`${process.env.REACT_APP_TOURNAMENT_PLATFORM_URL}/tournament?app=${platform?.slug}&country=${platform?.country}`}
											/>
										}
										subtitle={
											platform?.is_trial &&
											user?.unlock_platform && (
												<MDTypography variant="caption">
													This platform will stop
													running on{" "}
													{dateFormat(
														new Date(
															removeTimezoneFromDateString(
																platform?.trial_end_date
															)
														),
														"dd MMMM yyyy"
													) || ""}{" "}
													if you don&apos;t&nbsp;
													<MDTypography
														onClick={() => {
															navigate(
																"/pricing"
															);
														}}
														variant="caption"
														color="info"
														sx={{
															cursor: "pointer",
														}}
													>
														{/* <Link to="/pricing"> */}
														upgrade
														{/* </Link> */}
													</MDTypography>
												</MDTypography>
											)
										}
										footerTitle="Choose how you wish to Integrate."
										footerSubtitle="See video to Know more"
										footerButton={{
											label: "START 30 DAYS FREE TRIAL",
											color: "warning",
											disabled: platformLoading,
											onClick: () => {
												analytics.triggerEvent(
													EVENT_TYPES.START_30_DAYS_FREE_TRIAL,
													{}
												);
												dispatch(
													unlockPlatform(
														workspace?.alias
													) as any
												).then(() => {
													handleOpen();
												});
												// setUserUnlock(false);
												// dispatch(
												// 	resetPlatformIntegratorForm()
												// );
												// setUserUnlock(false);
												// if (tournaments.length > 0) {
												// 	analytics.enterIntegration(
												// 		{}
												// 	);
												// 	dispatch(
												// 		openPlatformIntegratorModal()
												// 	);
												// } else {
												// 	setOpenDialog(true);
												// }
											},
										}}
										platformUnlocked={user?.unlock_platform}
										actionButtons={{
											disabledButtons: {
												delete: true,
											},
											action: (type: string) => {
												if (type) {
													switch (type) {
														case "edit":
															dispatch(
																openPlatformIntegratorModal()
															);
															if (platform) {
																dispatch(
																	updatePlatformIntegratorForm(
																		platform as any
																	)
																);
															}
															break;
														default:
															break;
													}
												}
											},
										}}
									/>
								)}
							</Grid>
							<Grid
								item
								xl={6}
								lg={4}
								md={4}
								sm={12}
								sx={{ paddingTop: { md: 5, sm: 5, xs: 5 } }}
							>
								<div
									className="help-widget"
									style={{
										display: "flex",
										justifyContent: "center",
										textAlign: "center",
									}}
								>
									<div>
										<div>
											<img
												src={helpImg}
												alt="need help"
											/>
											<MDTypography
												variant="body2"
												color="dark"
												fontSize="small"
												fontWeight="light"
												sx={{
													textAlign: "left",
													paddingBottom: "5px",
												}}
											>
												Need Help?
											</MDTypography>
										</div>
										<MDButton
											variant="gradient"
											color="info"
											size="large"
											onClick={() => {
												bookAcallAction();
											}}
										>
											<Icon fontSize="large">
												calendar_month
											</Icon>
											&nbsp; BOOK A CALL WITH US
										</MDButton>
									</div>
								</div>
							</Grid>
						</Grid>
					</MDBox>
					<MDBox mt={2} mb={1}>
						<MDTypography variant="h3">
							Integrating with us is super easy. Check all details
							below
						</MDTypography>
					</MDBox>
					<hr />
					<MDBox mt={2}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6} lg={4}>
								<MDBox mt={3}>
									<VideoCard
										type="iframe"
										title="React Native"
										description="Integration Guide"
										href="https://www.youtube.com/embed/-PnkslfUFuc"
									/>
								</MDBox>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<MDBox mt={3}>
									<VideoCard
										type="iframe"
										title="Native Android"
										description="Integration Guide"
										href="https://www.youtube.com/embed/65Oudj5bsYk"
									/>
								</MDBox>
							</Grid>
							{/* <Grid item xs={12} md={6} lg={4}>
							<MDBox mt={3}>
								<BlogCard
									image={booking1}
									title="What are Hyper-Casual Games?"
									description="Mobile gaming has taken a quantum leap in recent years, becoming the predominant force in the gaming industry. Hyper-casual games are behind this phenomenal success, roping in even non-gamers to put in as much as a few hours every week playing them. These easy-to-learn games have offered many respites from an increasingly stressful outside world, especially during the recent pandemic. Games like trivia and puzzles have integrated a social ecosystem around them that allows people to interact and compete."
									price="Author: David"
									href="https://goama.com/goama/what-are-hyper-casual-games/"
									// location="Barcelona, Spain"
									// action={actionButtons}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mt={3}>
								<BlogCard
									image={booking2}
									title="Gaming Trends in 2022 and beyond"
									description="As the pandemic slowly wears thin, many industries still struggle to find their footing back. Not gaming – among a handful of markets that saw a spectacular rise in the past two years. Globally, the evolution of gaming off late has significantly impacted game dynamics, but some trends have become more apparent than others. The genre-defining shifts in gaming that we have seen in 2021 are likely to continue to 2022 and beyond, shaping the games we know, how we play and interact with them, and even in-game milestones and rewards."
									price="Author: David"
									href="https://goama.com/goama/gaming-trends-in-2022-and-beyond/"
									// location="London, UK"
									// action={actionButtons}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mt={3}>
								<BlogCard
									image={booking3}
									title="How Gamification Can Boost App Sales"
									description="The mobile app market is booming. The skyrocketing number of smartphone users has propelled most marketing, innovation, and cutting-edge technologies to focus on the mobile sector. As a result, 2.5 million Android apps, 1.8 million iOS apps, and another 1.2 million apps between Amazon and Windows stores are used by roughly 5.5 billion smartphone users. With so many apps vying for attention, gamification can grant your app the edge it needs to stand out."
									price="Author: David"
									href="https://goama.com/goama/how-gamification-can-boost-app-sales/"
									// location="Milan, Italy"
									// action={actionButtons}
								/>
							</MDBox>
						</Grid> */}
						</Grid>
					</MDBox>
					{/* <MDBox
						sx={{
							mt: 3,
						}}
					>
						<MDBox
							sx={{
								flexGrow: 1,
							}}
						>
							<iframe
								style={{
									// width: "100%",
									// padding: 10,
									border: 0,
									minHeight: 500,
									minWidth: "100%",
								}}
								title="pdf"
								src={`${pdfLink}#zoom=FitH`}
							/>
						</MDBox>
					</MDBox> */}
				</MDBox>
			</DashboardLayout>
		</>
	);
}
