import { Card, Stepper, Step, StepLabel } from "@mui/material";
import { useState, useEffect } from "react";
import MDBox from "template/components/MDBox";
import MDButton from "template/components/MDButton";
import { styled } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// import axiosClient from "utils/axios";
// import { sleep } from "utils/sleep";
import { useAppDispatch } from "store";
// import { createPlatform } from "actions/platformActions";
import { useSelector } from "react-redux";
// import GAME from "assets/images/dummy/game.png";
import {
	createPlatform,
	fetchPlatforms,
	updatePlatform,
} from "actions/platformActions";
import { resetPlatformIntegratorForm } from "slices/platformIntegratorSlice";
import { useNavigate } from "react-router-dom";
// import CongratulationsMessage from "components/modals/CongratulationsMessage";
// import Documentation from "containers/Integration/components/Documentation";
import { fetchUserInfo } from "actions/auth";
import validations, { editValidations } from "../schemas/validations";
import PlatformTypeForm from "../PlatformTypeForm";
import PlatformInfoForm from "../PlatformInfoForm";
import IntegrationModeForm from "../IntegrationModeForm";

const StepperStyle = styled("div")(({ theme }) => ({
	"& .MuiStepper-root": {
		background: `linear-gradient(180deg, ${theme.palette.gradients.primary.main} 0%, ${theme.palette.gradients.primary.state} 100%)`,
		".MuiStepIcon-root": {
			width: " 0.8125rem",
			height: "0.8125rem",
		},
	},
	// ".MuiSt"
}));

function getSteps(): string[] {
	return ["SELECT TYPE", "PLATFORM INFO", "INTEGRATION DETAILS"];
}

function getUpdateSteps(): string[] {
	return ["PLATFORM INFO", "INTEGRATION DETAILS"];
}

function PlatformIntegratorSteps(props: any): JSX.Element {
	const { formData } = useSelector(
		(state: any) => state.platformIntegratorReducer
	);
	const dispatch = useAppDispatch();
	const steps = !formData?.alias ? getSteps() : getUpdateSteps();
	const navigate = useNavigate();
	const [activeStep, setActiveStep] = useState(0);
	// const [finishStep, setFinishStep] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const workspaceReducer = useSelector((state: any) => state.workspaces);
	const currentValidation = formData?.alias
		? editValidations[activeStep]
		: validations[activeStep];
	const isLastStep = activeStep === steps.length - 1;
	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	const { handleClose, onActiveStep } = props;

	useEffect(() => {
		onActiveStep(isLastStep);
	}, [activeStep]);

	const form = useForm<{
		name: string;
		country: string;
		mode: string;
		integration_mode: string;
		technology: string;
		comment?: string;
		platform_type: string;
		usages: string[];
	}>({
		defaultValues: formData,
		resolver: yupResolver(currentValidation),
	});

	function getStepContent(stepIndex: number): JSX.Element {
		if (!formData?.alias) {
			switch (stepIndex) {
				case 0:
					return <PlatformTypeForm form={form} />;
				case 1:
					return <PlatformInfoForm form={form} />;
				case 2:
					return <IntegrationModeForm form={form} />;
				// case 3:
				// 	return <Documentation form={form} />;
				default:
					return null;
			}
		} else {
			switch (stepIndex) {
				case 0:
					return <PlatformInfoForm form={form} />;
				case 1:
					return <IntegrationModeForm form={form} />;
				// case 2:
				// 	return <Documentation form={form} />;
				default:
					return null;
			}
		}
	}

	const onSubmit = async (data: any) => {
		// eslint-disable-next-line no-console
		const params = data;
		delete params.mode;
		delete params.platform_type;
		if (activeStep === steps.length - 1) {
			setLoading(true);
			if (formData?.alias) {
				dispatch(
					updatePlatform(
						workspaceReducer?.workspaces[0]?.alias,
						params
					)
				)
					.then(() => {
						// setFinishStep(true);
					})
					.finally(() => {
						setLoading(false);
						dispatch(resetPlatformIntegratorForm());
						dispatch(fetchUserInfo());
						dispatch(
							fetchPlatforms(
								workspaceReducer?.workspaces[0]?.alias || ""
							)
						);
						navigate("/integration");
						handleClose();
					});
			} else {
				dispatch(
					createPlatform(
						workspaceReducer?.workspaces[0]?.alias,
						params
					)
				)
					.then(() => {
						// setFinishStep(true);
					})
					.finally(() => {
						setLoading(false);
						dispatch(resetPlatformIntegratorForm());
						dispatch(fetchUserInfo());
						dispatch(
							fetchPlatforms(
								workspaceReducer?.workspaces[0]?.alias || ""
							)
						);
						navigate("/integration");
						handleClose();
					});
			}
		} else {
			setActiveStep(activeStep + 1);
		}
	};

	const {
		formState: { errors },
	} = form;

	// if (finishStep) {
	// 	return (
	// 		<CongratulationsMessage
	// 			closeModal={() => {
	// 				dispatch(resetPlatformIntegratorForm());
	// 				handleClose();
	// 				navigate("/integration");
	// 				// throw new Error("Function not implemented.");
	// 			}}
	// 			buttonText="GO TO INTEGRATION"
	// 			header={`${
	// 				formData?.alias
	// 					? "Platform successfully updated!"
	// 					: "Congratulations! Your 30 days Free trial has started!."
	// 			}`}
	// 			description={`${
	// 				formData?.alias
	// 					? "Start Publishing Tournaments to make them live on your app"
	// 					: "Please note: Once integration is completed, start Publishing Tournaments to make them live on your app"
	// 			}`}
	// 			imageSrc={GAME}
	// 			imageShape="square"
	// 			imageSize="xxl"
	// 		/>
	// 	);
	// }

	/* const {
		formState: { errors },
	} = form; */

	return (
		<MDBox
			sx={{
				// flexGrow: 0.5,
				mx: 2,
				// maxWidth: "60vw",
				// minWidth: "60vw",
				maxHeight: "90vh",
			}}
		>
			<Card style={{ minHeight: 554 }}>
				<MDBox mx={2} mt={-3}>
					<StepperStyle>
						<Stepper activeStep={activeStep} alternativeLabel>
							{steps.map((label, index: number) =>
								label !== "" ? (
									<Step key={label}>
										<StepLabel>{label}</StepLabel>
									</Step>
								) : (
									// eslint-disable-next-line react/no-array-index-key
									<span key={`${label}${index}`}>{null}</span>
								)
							)}
						</Stepper>
					</StepperStyle>
				</MDBox>
				<MDBox
					// m={3}
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "space-between",
						justifyContent: "center",
						flexGrow: 1,
						height: "100%",
						width: "100%",
					}}
				>
					<MDBox
						className="step-content-container"
						width="100%"
						sx={{
							height: "100%",
							flexGrow: 1,
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							// alignItems: "center",
						}}
					>
						{getStepContent(activeStep)}
					</MDBox>
					<MDBox
						width="100%"
						display="flex"
						justifyContent="space-between"
						px={2}
						py={2}
					>
						{activeStep === 0 ? (
							<MDBox />
						) : (
							<MDButton
								variant="outlined"
								color="dark"
								onClick={handleBack}
								style={{ maxHeight: "40px" }}
							>
								Prev
							</MDButton>
						)}
						{isLastStep ? (
							<MDButton
								// form={steps[activeStep]}
								loading={loading}
								disabled={loading}
								// disabled={isSubmitting}
								type="button"
								// variant="gradient"
								color="info"
								onClick={form.handleSubmit(onSubmit)}
								// onClick={handleFinishStep}
							>
								Save and close
							</MDButton>
						) : (
							<MDButton
								onClick={form.handleSubmit(onSubmit)}
								form="tournament-builder-form"
								// disabled={isSubmitting}
								disabled={
									Object.keys(errors).length !== 0 &&
									activeStep === 1
								}
								type="submit"
								// variant="gradient"
								color="warning"
							>
								Next
							</MDButton>
						)}
					</MDBox>
				</MDBox>
			</Card>
		</MDBox>
	);
}

export default PlatformIntegratorSteps;
