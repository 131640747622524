import { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";

import ToggleButton from "components/elements/ToggleButton";

const optionsList = [
	{
		label: "Data & analytics",
		value: "Data & analytics",
	},
	{
		label: "Increase traffic",
		value: "Increase traffic",
	},
	{
		label: "Increase sales",
		value: "Increase sales",
	},
	{
		label: "Engagement",
		value: "Engagement",
	},
	{
		label: "GMV",
		value: "GMV",
	},
	{
		label: "Loyalty strategies",
		value: "Loyalty strategies",
	},
	{
		label: "Marketing Innovation",
		value: "Marketing Innovation",
	},
	{
		label: "Lower CHURN",
		value: "Lower CHURN",
	},
	{
		label: "More time on platform",
		value: "More time on platform",
	},
];

function Objective({ form }: any): JSX.Element {
	const { setValue, getValues } = form;
	const [objectiveValues, setObjectiveValues] = useState(
		getValues("usages") || []
	);

	const updateValues = (data: any) => {
		const currentIndex = objectiveValues.indexOf(data);
		const newObjectiveValues = [...objectiveValues];

		if (currentIndex === -1) {
			newObjectiveValues.push(data);
		} else {
			newObjectiveValues.splice(currentIndex, 1);
		}

		setObjectiveValues(newObjectiveValues);
		setValue("usages", newObjectiveValues);
	};

	const isActive = (value: string) => objectiveValues.includes(value);

	return (
		<MDBox>
			<MDBox width="80%" textAlign="center" mx="auto" my={4}>
				<MDBox mb={1}>
					<MDTypography variant="h5" fontWeight="regular">
						What are the objectives that you have more interest in?
					</MDTypography>
				</MDBox>
				<MDTypography variant="body2" color="text">
					Choose one or multiple options
				</MDTypography>
			</MDBox>
			<MDBox mt={2}>
				<Grid container spacing={3} mx="auto" justifyContent="center">
					<Grid item xs={12} md={12} lg={10}>
						<div
							style={{
								display: "flex",
								flexWrap: "wrap",
								justifyContent: "center",
							}}
						>
							{optionsList.map((option) => (
								<button
									type="button"
									style={{
										margin: "6px",
										backgroundColor: "transparent",
										border: "0",
									}}
									onClick={() => updateValues(option.value)}
								>
									<ToggleButton
										active={isActive(option.value)}
										title={option.label}
									/>
								</button>
							))}
						</div>
					</Grid>
				</Grid>
			</MDBox>
		</MDBox>
	);
}

export default Objective;
