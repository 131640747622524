import Grid from "@mui/material/Grid";
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import TextField from "@mui/material/TextField";
import CustomTooltip from "components/elements/CustomTooltip";
import { styled } from "@mui/system";
import { ErrorMessage } from "@hookform/error-message";
import whatImg from "assets/images/icons/what.png";

const InputStyles = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	"& .input-item": {
		minWidth: "350px",
		[theme.breakpoints.down("md")]: {
			minWidth: "100%",
			marginLeft: "0 !important",
		},
		"&.first-input-item": {
			[theme.breakpoints.down("md")]: {
				minWidth: "calc(100% + 32px)",
				marginLeft: "8px !important",
			},
		},
	},
	"& .form-group": {
		position: "relative",
		display: "flex",
		justifyContent: "center",
		[theme.breakpoints.down("md")]: {
			marginLeft: "-30px !important",
		},
		"& input[type='text']": {
			width: "100%",
		},
		"& .MuiBox-root": {
			width: "100%",
		},
		"& .tooltip-wrapper": {
			"& button": {
				background: "linear-gradient(180deg, #7721E5 0%, #3E06D6 100%)",
				width: "30px",
				height: "30px",
				marginRight: "10px",
				marginTop: "6px",
				"& img": {
					maxWidth: "9px",
				},
			},
		},
	},
}));

export default function BasicInfoOne({ form }: any) {
	const {
		register,
		formState: { errors },
	} = form;

	return (
		<form>
			<MDBox>
				<MDBox width="82%" textAlign="center" mx="auto" mt={5} pt={5}>
					<MDBox mb={1}>
						<MDTypography variant="h4" fontWeight="regular">
							Tournament info
						</MDTypography>
					</MDBox>

					<MDTypography variant="h6" fontWeight="regular">
						Set the basic information for your tournament, this
						information will be public.
					</MDTypography>
					<MDTypography
						variant="body2"
						color="text"
						fontStyle="italic"
					>
						You can always change this later
					</MDTypography>
				</MDBox>
				<MDBox mt={5} pt={2}>
					<Grid container mx="auto">
						<Grid item lg={5} md={12} sm={12} mx="auto">
							<InputStyles>
								<div>
									<TextField
										{...register("title")}
										label="Tournament Title"
										name="title"
										placeholder="The Weekly Free Tournament!"
										className="input-item first-input-item"
										style={{
											marginBottom: errors?.title
												? 0
												: 20,
											minHeight: "42px",
											marginLeft: 40,
										}}
									/>
									<MDBox
										sx={{
											marginLeft: 6,
											width: "100%",
											mb: 2,
										}}
									>
										<ErrorMessage
											errors={errors}
											name="title"
											render={({ message }) => (
												<MDTypography
													variant="caption"
													color="secondary"
												>
													{message}
												</MDTypography>
											)}
										/>
									</MDBox>
								</div>
								<div className="form-group">
									<div className="tooltip-wrapper">
										<CustomTooltip
											title="This subtitle will be displayed in the preview of the game, try to engage users by telling them"
											placement="left-end"
											iconImg={whatImg}
										/>
									</div>
									<MDBox
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<TextField
											sx={{
												maxWidth: 250,
												minWidth: 250,
											}}
											{...register("subtitle")}
											label="Tournament Sub Title"
											placeholder="Win 5,000 points this week"
											name="subtitle"
											className="input-item"
											style={{
												marginBottom: errors?.subtitle
													? 0
													: 10,
												minHeight: "42px",
											}}
										/>
										<MDBox>
											<ErrorMessage
												errors={errors}
												name="subtitle"
												render={({ message }) => (
													<MDTypography
														variant="caption"
														color="secondary"
														style={{
															marginLeft: 10,
														}}
													>
														{message}
													</MDTypography>
												)}
											/>
										</MDBox>
									</MDBox>
								</div>
							</InputStyles>
						</Grid>
					</Grid>
				</MDBox>
			</MDBox>
		</form>
	);
}
