import { Dispatch } from "@reduxjs/toolkit";
import {
	fetchGraphBegin,
	fetchGraphSuccessful,
	fetchGraphFailure,
} from "slices/graphSlice";
import axiosClient from "utils/axios";

export const fetchGraph =
	(platformSlug: string): any =>
	async (dispatch: Dispatch): Promise<void> =>
		// eslint-disable-next-line no-new
		new Promise((resolve, reject) => {
			dispatch(fetchGraphBegin());
			axiosClient
				.get(`platform/${platformSlug}/dashboard/`)
				.then((response: any) => {
					dispatch(fetchGraphSuccessful(response.data));
					resolve(response.data);
				})
				.catch((error: any) => {
					dispatch(fetchGraphFailure());
					reject(error);
				});
		});

export default fetchGraph;
