import { useState } from "react";
import MDTypography from "template/components/MDTypography";
import MDBox from "template/components/MDBox";
// import MDInput from "template/components/MDInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/system";
import MDButton from "template/components/MDButton";
import Icon from "@mui/material/Icon";
import { openTournamentBuilderModal } from "slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import analytics from "utils/analytics";
import { useNavigate } from "react-router-dom";
// import { TOURNAMENT_STATUS } from "constants/constants";

const ViewFilterStyle = styled("div")(() => ({
	"& .tournaments-filter-container": {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
	},
	"& .labelTitle": {
		marginRight: "20px",
	},
}));

export default function TournamentViewFilter() {
	const navigate = useNavigate();
	const { isDesktop } = useSelector((state: any) => state.layoutReducer);
	const dispatch = useDispatch();
	const { platforms } = useSelector((state: any) => state.platformsReducer);
	const platform =
		platforms && platforms.length > 0 && platforms[0] ? platforms[0] : null;
	const [selectedPlatform, setSelectedPlatform] = useState<any>(platform);
	const handleChange = (event: SelectChangeEvent) => {
		const platform = platforms.find(
			(item: any) => item.alias === event.target.value
		);
		setSelectedPlatform(platform);
	};
	/* const { tournaments } = useSelector(
		(state: any) => state.tournamentsReducer
	);
	const countActiveTournaments = () => {
		const activeTournaments = tournaments.filter(
			(tournament: any) => tournament.status === TOURNAMENT_STATUS.ON
		);
		return activeTournaments.length;
	}; */
	return (
		<ViewFilterStyle>
			<MDBox
				className="tournaments-filter-container"
				sx={{
					justifyContent: !isDesktop && "center",
				}}
			>
				<MDTypography variant="h6" className="labelTitle">
					All Tournaments
				</MDTypography>
				{/* <MDBox pr={1}>
					<MDInput label="Search here" />
				</MDBox> */}
				<MDBox sx={{ minWidth: "224px", marginLeft: "20px" }}>
					<FormControl fullWidth>
						<InputLabel id="demo-simple-select-label">
							App Platform
						</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={selectedPlatform?.alias}
							label="App Platform"
							onChange={handleChange}
							style={{ minHeight: 45 }}
							fullWidth
						>
							<MenuItem value={selectedPlatform?.alias}>
								<MDTypography
									variant="inherit"
									fontWeight="normal"
									textTransform="capitalize"
								>
									{selectedPlatform?.name}
								</MDTypography>
							</MenuItem>
							{/* <MenuItem value={20}>Desktop</MenuItem> */}
						</Select>
					</FormControl>
				</MDBox>
				<MDBox
					sx={{
						mt: !isDesktop && 1,
						mr: !isDesktop && "auto",
					}}
					ml="auto"
				>
					{!platform && (
						<MDButton
							color="success"
							onClick={() => {
								navigate("/integration");
							}}
							style={{ marginRight: 15 }}
						>
							INTEGRATION
						</MDButton>
					)}
					<MDButton
						// disabled={countActiveTournaments() >= 2}
						onClick={() => {
							analytics.clickNewTournament({});
							dispatch(openTournamentBuilderModal());
						}}
						color="secondary"
					>
						<Icon>add</Icon>&nbsp; CREATE NEW TOURNAMENT
					</MDButton>
				</MDBox>
			</MDBox>
		</ViewFilterStyle>
	);
}
