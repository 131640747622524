// import { styled } from "@mui/system";

import { ReactNode } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
// import MDBox from "template/components/MDBox";

// Material Dashboard 2 PRO React TS examples components
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "template/layouts/LayoutContainers/PageLayout";
// import loginBg from "assets/images/login_bg.jpg"
// Material Dashboard 2 PRO React page layout routes
// import pageRoutes from "page.routes";

// Authentication pages components
// import Footer from "template/layouts/authentication/components/Footer";

// Declaring props types for BasicLayout
interface Props {
	children: ReactNode;
}

function SimpleLayout({ children }: Props): JSX.Element {
	return (
		<PageLayout>
			<Grid
				container
				spacing={0}
				minHeight="100vh"
				justifyContent="center"
				alignItems="center"
				height="100%"
				maxWidth="100%"
			>
				<Grid item xs={11} sm={9} md={5} lg={3} xl={3}>
					{children}
				</Grid>
			</Grid>
		</PageLayout>
	);
}

export default SimpleLayout;
