/* eslint-disable no-unused-vars */
/* eslint-disable max-classes-per-file */
import amplitude from "amplitude-js";
import { v4 as uuid4 } from "uuid";

export const EVENT_TYPES = {
	ENTER_LANDING_PAGE: "Enter Landing Page",
	CLICK_LOGIN: "Click Login",
	CLICK_SIGNUP: "Click Signup",
	CLICK_TRY_FOR_FREE: "Click Try For Free",
	ENTER_LOGIN_PAGE: "Enter Login Page",
	ENTER_WORKSPACES: "Enter Workspaces",
	SELECT_PLATFORM: "Select Platform",
	SELECT_OBJECTIVES: "Select Objectives",
	CLICK_GOTO_DASHBOARD: "Click Go to Dashboard",
	CLICK_NEW_TOURNAMENT: "Click New Tournament",
	CLICK_DASHBOARD: "Click Dashboard",
	CLICK_TOURNAMENT: "Click Tournament",
	CLICK_INTEGRATION: "Click Integration",
	CLICK_PRICING_PLANS: "Click Pricing & Plans",
	CLICK_LOGOUT: "Click Logout",
	SELECT_TEMPLATE: "Select Template",
	SELECT_GAME: "Select Game",
	ENTER_TOURNAMENT_INFO_PAGE: "Enter Tournament Info Page",
	ENTER_TOURNAMENT_TYPE: "Enter Tournament Type",
	ENTER_TIME_DURATION: "Enter Time & Duration",
	ENTER_REWARD_PAGE: "Enter Reward Page",
	ENTER_PLAYERS_SESSIONS: "Enter Player & Sessions",
	CLICK_SAVE_TOURNAMENT: "Click Save Tournament",
	CLICK_EDIT_TOURNAMENT: "Click Edit Tournament",
	CLICK_EDIT_DRAFT_TOURNAMENT: "Click Edit Draft Tournament",
	ENTER_DASHBOARD: "Enter Dashboard",
	ENTER_INTEGRATION: "Enter Integration",
	ENTER_PRICING_PLANS: "Enter Pricing & Plans",
	ENTER_PRIVACY_POLICY: "Enter Privacy Policy",
	CONNECT_TO_PLATFORM: "Connect To Platform",
	WORKSPACE_PAGE_1_CROSSED: "Workspace Page 1 Crossed",
	PREVIEW_CLICKED: "Preview Clicked",
	ADD_TOURNAMENT_STEP_GUIDE: "Add Tournament Step Guide",
	GET_INTEGRATION_DETAILS_GUIDE: "Get Integration Details Guide",
	PUBLISH_TOURNAMENT_STEP_GUIDE: "Publish Tournament Step Guide",
	BOOK_CALL_STEP_GUIDE: "Book Call Step Guide",
	KNOW_MORE_STEP_GUIDE: "Know More Step Guide",
	GET_INTEGRATION_DETAILS_BUTTON: "Get Integration Details Button",
	START_30_DAYS_FREE_TRIAL: "START 30 DAYS FREE TRIAL",
	// WORKSPACE_BUILDER_FINISH: "WORKSPACE BUILDER FINISH",
};

export const defaultEvents = [
	EVENT_TYPES.ENTER_LANDING_PAGE,
	EVENT_TYPES.CLICK_LOGIN,
	EVENT_TYPES.CLICK_SIGNUP,
	EVENT_TYPES.CLICK_TRY_FOR_FREE,
	EVENT_TYPES.ENTER_LOGIN_PAGE,
	EVENT_TYPES.ENTER_WORKSPACES,
	EVENT_TYPES.SELECT_PLATFORM,
	EVENT_TYPES.SELECT_OBJECTIVES,
	EVENT_TYPES.CLICK_GOTO_DASHBOARD,
	EVENT_TYPES.CLICK_NEW_TOURNAMENT,
	EVENT_TYPES.CLICK_DASHBOARD,
	EVENT_TYPES.CLICK_TOURNAMENT,
	EVENT_TYPES.CLICK_INTEGRATION,
	EVENT_TYPES.CLICK_PRICING_PLANS,
	EVENT_TYPES.CLICK_LOGOUT,
	EVENT_TYPES.SELECT_TEMPLATE,
	EVENT_TYPES.SELECT_GAME,
	EVENT_TYPES.ENTER_TOURNAMENT_INFO_PAGE,
	EVENT_TYPES.ENTER_TOURNAMENT_TYPE,
	EVENT_TYPES.ENTER_TIME_DURATION,
	EVENT_TYPES.ENTER_REWARD_PAGE,
	EVENT_TYPES.ENTER_PLAYERS_SESSIONS,
	EVENT_TYPES.CLICK_SAVE_TOURNAMENT,
	EVENT_TYPES.CLICK_EDIT_TOURNAMENT,
	EVENT_TYPES.ENTER_DASHBOARD,
	EVENT_TYPES.ENTER_INTEGRATION,
	EVENT_TYPES.ENTER_PRICING_PLANS,
	EVENT_TYPES.ENTER_PRIVACY_POLICY,
	EVENT_TYPES.CONNECT_TO_PLATFORM,
	EVENT_TYPES.WORKSPACE_PAGE_1_CROSSED,
	EVENT_TYPES.PREVIEW_CLICKED,
	EVENT_TYPES.ADD_TOURNAMENT_STEP_GUIDE,
	EVENT_TYPES.GET_INTEGRATION_DETAILS_GUIDE,
	EVENT_TYPES.PUBLISH_TOURNAMENT_STEP_GUIDE,
	EVENT_TYPES.BOOK_CALL_STEP_GUIDE,
	EVENT_TYPES.KNOW_MORE_STEP_GUIDE,
	EVENT_TYPES.GET_INTEGRATION_DETAILS_BUTTON,
	EVENT_TYPES.START_30_DAYS_FREE_TRIAL,
	// EVENT_TYPES.WORKSPACE_BUILDER_FINISH,
];

export const GLOBAL_AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY ?? "";

abstract class AnalyticsEngine {
	engineKey: string;

	constructor(engineKey: string) {
		this.engineKey = engineKey;
	}

	abstract logEvent(eventType: string, eventData: any): void;

	abstract setUserId(userId: string): void;
}

export class AmplitudeAnalyticsEngine extends AnalyticsEngine {
	instanceName: string;

	constructor(engineKey: string, instanceName: string) {
		super(engineKey);
		this.instanceName = instanceName;
		amplitude.getInstance(instanceName).init(engineKey, undefined, {
			includeUtm: true,
			includeReferrer: true,
			includeGclid: true,
		});
	}

	logEvent = (eventType: string, eventData: any = {}) => {
		amplitude.getInstance(this.instanceName).logEvent(eventType, eventData);
	};

	setUserId = (userId: string) => {
		amplitude.getInstance(this.instanceName).setUserId(userId);
	};
}

interface IProvider {
	name: string;
	enabledEvents: string[];
	engines: AnalyticsEngine[];
}

interface IAnalyticsProviderConfig {
	providers: IProvider[];
}

export class AnalyticsProvider {
	providers: IProvider[];

	workspace: string;

	platform: string;

	userId: string = "";

	constructor({ providers = [] }: IAnalyticsProviderConfig) {
		this.providers = providers;
	}

	addProvider = (provider: IProvider) => this.providers.push(provider);

	logEvent = (eventType: string, eventData: any = {}) => {
		const newEventData = eventData;
		newEventData.email = this.userId;
		newEventData.workspace = this.workspace;
		newEventData.platform = this.platform;
		this.providers.map((provider) =>
			provider.engines.map(
				(engine) =>
					provider.enabledEvents.includes(eventType) &&
					engine.logEvent(eventType, newEventData)
			)
		);
	};

	setUserId = (userId: string | null) => {
		this.userId = userId || `${uuid4()}__anonymous__`;
		this.providers.map((provider) =>
			provider.engines.map((engine) => engine.setUserId(this.userId))
		);
	};

	setWorkspace = (workspace: any | string) => {
		this.workspace = workspace;
	};

	setPlatform = (platform: any | string) => {
		this.platform = platform;
	};

	enterWorkspace = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.ENTER_WORKSPACES, eventData);
	};

	selectPlatform = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.SELECT_PLATFORM, eventData);
	};

	selectObjectives = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.SELECT_OBJECTIVES, eventData);
	};

	gotoDashboard = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.CLICK_GOTO_DASHBOARD, eventData);
	};

	clickNewTournament = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.CLICK_NEW_TOURNAMENT, eventData);
	};

	clickDashboard = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.CLICK_DASHBOARD, eventData);
	};

	clickTournament = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.CLICK_TOURNAMENT, eventData);
	};

	clickIntegration = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.CLICK_INTEGRATION, eventData);
	};

	clickPricingPlans = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.CLICK_PRICING_PLANS, eventData);
	};

	clickLogout = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.CLICK_LOGOUT, eventData);
	};

	selectTemplate = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.SELECT_TEMPLATE, eventData);
	};

	selectGame = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.SELECT_GAME, eventData);
	};

	enterTournamentInfoPage = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.ENTER_TOURNAMENT_INFO_PAGE, eventData);
	};

	enterTournamentType = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.ENTER_TOURNAMENT_TYPE, eventData);
	};

	enterPlayerSessions = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.ENTER_PLAYERS_SESSIONS, eventData);
	};

	enterRewardPage = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.ENTER_REWARD_PAGE, eventData);
	};

	enterTimeDuration = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.ENTER_TIME_DURATION, eventData);
	};

	clickSaveTournament = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.CLICK_SAVE_TOURNAMENT, eventData);
	};

	clickEditTournament = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.CLICK_EDIT_TOURNAMENT, eventData);
	};

	clickEditDraftTournament = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.CLICK_EDIT_DRAFT_TOURNAMENT, eventData);
	};

	connectPlatform = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.CONNECT_TO_PLATFORM, eventData);
	};

	enterIntegration = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.ENTER_INTEGRATION, eventData);
	};

	complete_workspace_page_1 = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.WORKSPACE_PAGE_1_CROSSED, eventData);
	};

	preview_clicked = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.PREVIEW_CLICKED, eventData);
	};

	triggerEvent = (eventType: string, eventData: any = {}) => {
		this.logEvent(eventType, eventData);
	};

	/* clickFinishWorkspaceBuilder = (eventData: any = {}) => {
		this.logEvent(EVENT_TYPES.WORKSPACE_BUILDER_FINISH, eventData);
	}; */
}

export default new AnalyticsProvider({
	providers: [
		{
			name: "default",
			enabledEvents: defaultEvents,
			engines: [
				new AmplitudeAnalyticsEngine(GLOBAL_AMPLITUDE_KEY, "default"),
			],
		},
	],
});
