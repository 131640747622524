/* eslint-disable no-unused-vars */

import { ReactNode } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import MDAvatar from "template/components/MDAvatar";
import MDButton from "template/components/MDButton";

// Declaring prop types for the ActionCard
interface Props {
	color?:
		| "primary"
		| "secondary"
		| "info"
		| "success"
		| "warning"
		| "error"
		| "dark"
		| "light";
	image: string;
	title: string;
	dateTime?: string;
	description: ReactNode;
	members?: string[];
	dropdown?: {
		action?: (...arg: any) => void;
		menu?: ReactNode;
	};
	actionButtons?: {
		disabledButtons:
			| {
					delete?: boolean;
			  }
			| undefined
			| null;
		action?: (...arg: any) => void;
	};
	[key: string]: any;
}

// Custom styles for ActionCard
function ActionCard({
	footerTitle = "",
	footerSubtitle = "",
	footerButton = null,
	color,
	image,
	imageOnly = false,
	title,
	subtitle,
	dateTime,
	description,
	members,
	dropdown,
	actionButtons,
}: Props): JSX.Element {
	const renderMembers = members.map((member, key) => {
		const memberKey = `member-${key}`;

		return (
			<MDAvatar
				key={memberKey}
				src={member}
				alt="member profile"
				size="xs"
				sx={({ borders: { borderWidth }, palette: { white } }) => ({
					border: `${borderWidth[2]} solid ${white.main}`,
					cursor: "pointer",
					position: "relative",

					"&:not(:first-of-type)": {
						ml: -1.25,
					},

					"&:hover, &:focus": {
						zIndex: "10",
					},
				})}
			/>
		);
	});

	return (
		<Card>
			<MDBox p={2}>
				<MDBox display="flex" alignItems="center">
					<MDAvatar
						src={image}
						alt={title}
						size="xl"
						variant="rounded"
						bgColor={color}
						sx={{
							"& .MuiAvatar-img": {
								width: imageOnly ? "100%" : "80%",
							},
							p: imageOnly ? 0 : 1,
							mt: -6,
							borderRadius: ({ borders: { borderRadius } }) =>
								borderRadius.xl,
						}}
					/>
					<MDBox
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							width: "100%",
						}}
						ml={2}
						mt={-2}
						lineHeight={0}
					>
						<MDTypography
							variant="h6"
							textTransform="capitalize"
							fontWeight="medium"
						>
							{title}
						</MDTypography>
						{subtitle && (
							<MDBox
								sx={{
									width: "70%",
									pt: 1,
									direction: "rtl",
								}}
							>
								{subtitle}
							</MDBox>
						)}
					</MDBox>
					{dropdown && (
						<MDTypography
							color="secondary"
							onClick={dropdown.action}
							sx={{
								ml: "auto",
								mt: -1,
								alignSelf: "flex-start",
								py: 1.25,
							}}
						>
							<Icon
								sx={{ cursor: "pointer", fontWeight: "bold" }}
							>
								more_vert
							</Icon>
						</MDTypography>
					)}
					{dropdown.menu}
				</MDBox>
				<MDBox my={2} lineHeight={1} width="50%">
					<MDTypography
						variant="button"
						fontWeight="light"
						color="text"
					>
						{description}
					</MDTypography>
				</MDBox>
				<Divider />
				<MDBox
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<MDBox display="flex" flexDirection="column" lineHeight={0}>
						<MDTypography variant="button" fontWeight="medium">
							{footerTitle}
						</MDTypography>
						<MDTypography
							variant="button"
							fontWeight="regular"
							color="secondary"
						>
							{footerSubtitle}
						</MDTypography>
					</MDBox>
					{footerButton && (
						<MDBox
							display="flex"
							flexDirection="column"
							lineHeight={0}
						>
							{/* <MDTypography variant="button" fontWeight="medium">
							{dateTime}
						</MDTypography> */}
							<MDButton
								onClick={footerButton.onClick}
								variant="gradient"
								color={footerButton.color || "info"}
							>
								<MDTypography
									variant="button"
									fontWeight="regular"
									color="inherit"
									textTransform="uppercase"
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Icon
										sx={{
											cursor: "pointer",
											fontWeight: "bold",
										}}
									>
										add
									</Icon>
									{footerButton?.label}
								</MDTypography>
							</MDButton>
						</MDBox>
					)}
					{actionButtons && (
						<MDBox
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
								alignItems: "center",
								flexWrap: "wrap",
							}}
						>
							<MDTypography
								color="dark"
								onClick={() => actionButtons.action("download")}
								sx={{
									ml: "auto",
									mr: 2,
									mt: -1,
									alignSelf: "flex-start",
									py: 1.25,
								}}
							>
								<Icon
									sx={{
										cursor: "pointer",
										fontWeight: "bold",
									}}
								>
									file_download
								</Icon>
							</MDTypography>
							<MDTypography
								color="dark"
								onClick={() => actionButtons.action("edit")}
								sx={{
									ml: "auto",
									mr: 2,
									mt: -1,
									alignSelf: "flex-start",
									py: 1.25,
								}}
							>
								<Icon
									sx={{
										cursor: "pointer",
										fontWeight: "bold",
									}}
								>
									edit
								</Icon>
							</MDTypography>
							<MDTypography
								color="dark"
								onClick={() => actionButtons.action("help")}
								sx={{
									ml: "auto",
									mr: 2,
									mt: -1,
									alignSelf: "flex-start",
									py: 1.25,
								}}
							>
								<Icon
									sx={{
										cursor: "pointer",
										fontWeight: "bold",
									}}
								>
									question_mark
								</Icon>
							</MDTypography>
							{!actionButtons?.disabledButtons?.delete && (
								<MDTypography
									color="dark"
									onClick={() =>
										actionButtons.action("delete")
									}
									sx={{
										mt: -1,
										alignSelf: "flex-end",
										py: 1.25,
									}}
								>
									<Icon
										sx={{
											cursor: "pointer",
											fontWeight: "bold",
										}}
									>
										delete
									</Icon>
								</MDTypography>
							)}
						</MDBox>
					)}
				</MDBox>
			</MDBox>
		</Card>
	);
}

// Declaring default props for ActionCard
ActionCard.defaultProps = {
	color: "dark",
	dateTime: "",
	members: [],
	dropdown: false,
	actionButtons: false,
};

export default ActionCard;
