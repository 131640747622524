import { useState } from "react";
import Modal from "@mui/material/Modal";
import CloseButton from "components/elements/CloseButton";
import PlatformIntegratorSteps from "containers/Integration/form/steps/PlatformIntegratorSteps";
import { useDispatch, useSelector } from "react-redux";
import { closePlatformIntegratorModal } from "slices/modalSlice";
// import VideoIframe from "components/integration/VideoIframe";
// import VideoCard from "components/cards/VideoCard";
import MDBox from "template/components/MDBox";
import ModalWrapper from "./ModalWrapper";
import ModalWrapperTwo from "./ModalWrapperTwo";

export default function PlatformIntegratorModal() {
	// const { isDesktop } = useSelector((state: any) => state.layoutReducer);
	const dispatch = useDispatch();
	const { showPlatformIntegratorModal } = useSelector(
		(state: any) => state.modal
	);
	const [lastStep, setLastStep] = useState<boolean>(false);
	const handleClose = () => {
		dispatch(closePlatformIntegratorModal());
	};

	const onActiveStep = (getStep: any) => {
		setLastStep(getStep);
	};

	return (
		<div className="modal-wrapper">
			<Modal
				onBackdropClick={() => {}}
				BackdropProps={{
					style: { backgroundColor: "#191919", opacity: 0.85 },
				}}
				// onClose={handleClose}
				open={showPlatformIntegratorModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<div>
					<ModalWrapper>
						<MDBox
							sx={{
								display: "flex",
								width: "100vw",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								flexWrap: "wrap",
							}}
						>
							<MDBox
								sx={{
									// flexGrow: 0.5,
									minWidth: "60vw",
									// maxWidth: "50vw",
									// width: "50%",
									// minHeight: "60vh",
								}}
							>
								<PlatformIntegratorSteps
									handleClose={handleClose}
									onActiveStep={onActiveStep}
								/>
							</MDBox>
							{/* {lastStep && isDesktop && (
								<MDBox
									sx={{
										mt: 2,
									}}
								>
									<VideoCard
										type="iframe"
										title="Integration Guide"
										description="React Native"
										href="https://www.youtube.com/embed/-PnkslfUFuc"
										hideFooter
									/>
								</MDBox>
							)} */}
						</MDBox>
					</ModalWrapper>
					{lastStep && (
						<ModalWrapperTwo>
							{/* <VideoIframe videoUrl="https://games.gogames.io/pp-media/React_native_Integration.mp4" /> */}
							{/* <VideoCard
								type="iframe"
								title="Integration Guide"
								description="React Native"
								href="https://www.youtube.com/embed/-PnkslfUFuc"
							/> */}
						</ModalWrapperTwo>
					)}
					<CloseButton handleClose={handleClose} buttonText="Close" />
				</div>
			</Modal>
		</div>
	);
}
