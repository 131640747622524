import { styled } from "@mui/system";
import MDTypography from "template/components/MDTypography";
import MDButton from "template/components/MDButton";
import MDAvatar from "template/components/MDAvatar";

// images
import { useSelector } from "react-redux";
import DUMMY_USER from "assets/images/avatars/dummy_user.png";
import { useNavigate } from "react-router-dom";
import {
	dateFormat,
	// getDifferenceInDays,
	removeTimezoneFromDateString,
} from "utils/date-format";

const StatusStyle = styled("div")(({ theme }) => ({
	"& .status-container": {
		display: "flex",
		justifyContent: "space-between",
		boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
		borderRadius: "12px",
		padding: "2rem 1.5rem",
		backgroundColor: theme.palette.white.main,
	},
	"& .author-status": {
		display: "flex",
		alignItems: "center",
	},
	"& .primary-btn": {
		marginLeft: "15px",
	},
	"& .author-info": {
		display: "flex",
		"& .author-pic": {
			marginRight: "15px",
		},
	},
}));

export default function ProfileStatus() {
	const navigate = useNavigate();
	const { user } = useSelector((state: any) => state.auth);
	const { workspaces } = useSelector((state: any) => state.workspaces);
	const workspace =
		workspaces && workspaces.length > 0 && workspaces[0]
			? workspaces[0]
			: null;
	const { platforms } = useSelector((state: any) => state.platformsReducer);
	const platform =
		platforms && platforms.length > 0 && platforms[0] ? platforms[0] : null;
	return (
		<StatusStyle>
			<div className="status-container">
				<div className="author-info">
					<div className="author-pic">
						<MDAvatar src={user?.profile_pic || DUMMY_USER} />
					</div>
					<div className="profile-data">
						<MDTypography variant="h6">
							{user?.first_name}&nbsp;{user?.last_name}
						</MDTypography>
						<MDTypography
							variant="body2"
							fontSize="small"
							fontWeight="regular"
						>
							{workspace?.creator_role}
						</MDTypography>
					</div>
				</div>
				{platform?.is_trial && user?.unlock_platform && (
					<div className="author-status">
						<MDTypography variant="subtitle2" fontWeight="medium">
							Your free trial will expire on{" "}
							{dateFormat(
								new Date(
									removeTimezoneFromDateString(
										platform?.trial_end_date
									)
								),
								"dd MMMM yyyy"
							) || ""}
							{/* {getDifferenceInDays(
								platform?.trial_end_date,
								new Date().toISOString()
							)}{" "}
							days */}
						</MDTypography>
						<MDButton
							onClick={() => navigate("/pricing")}
							color="info"
							className="primary-btn"
							textTransform="uppercase"
							size="large"
							style={{ minWidth: 150, minHeight: 40 }}
						>
							Upgrade
						</MDButton>
					</div>
				)}
			</div>
		</StatusStyle>
	);
}
