/* eslint-disable camelcase */
import { styled } from "@mui/system";

import MDTypography from "template/components/MDTypography";

// images

import premiumImg from "assets/images/icons/premium.png";

import { ReactComponent as PeopleIcon } from "assets/images/shapes/user.svg";
import { ReactComponent as TriangleBackground } from "assets/images/shapes/browse-game-background.svg";
import { ReactComponent as Play } from "assets/images/shapes/play.svg";
import { Typography } from "@mui/material";

const GameStyle = styled("div")(({ theme }) => ({
	position: "relative",
	display: "flex",
	justifyContent: "center",
	"& .single-game-wrapper": {
		maxWidth: "120px",
		position: "relative",
		height: "100%",
		// minHeight: "185px",
		padding: "0 1px",
	},
	"& .position-relative": {
		position: "relative",
		"& .game-meta": {
			display: "flex",
			alignItems: "flex-start",
			color: theme.palette.white.main,
			// paddingTop: "5px",
		},
	},
	"& .image-style": {
		position: "absolute",
		marginBottom: "0!important",
		width: "120px",
		height: "auto",
		maxHeight: "110px",
		borderRadius: "10px",
		cursor: "pointer",
		zIndex: "9",
	},
	"& .info-container": {
		position: "absolute",
		bottom: 25,
		zIndex: 1,
		margin: "0",
		paddingLeft: "8px",
	},
	"& .triangle-bg": {
		position: "relative",
		// top: "0",
		filter: "drop-shadow( 10px 10px 15px rgba(0,0,0,0.05) )",
		// left: "0",
		width: "120px",
		height: "auto",
	},
	"& .game-info": {
		width: "100%",
		marginBottom: "0",
		paddingTop: "2.2px",
		"& .arcade-icon": {
			marginRight: "5px",
		},
	},
	"& .triangle-div": {
		position: "absolute",
		zIndex: "1",
		right: -5,
		bottom: 25,
		"& button": {
			borderRadius: "8px",
			background: theme.palette.warning.main,
			color: theme.palette.light.main,
			boxShadow: "rgb(231 28 47 / 25%) 0px 5px 5px",
			fontWeight: "500",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "50px",
			height: "30px",
			border: "0",
			cursor: "pointer",
			"& svg": {
				maxWidth: "13px",
			},
		},
	},
	"& .premium-icon": {
		position: "absolute",
		zIndex: "10",
		top: "-5px",
		left: "-5px",
		width: "25px",
		height: "25px",
	},
}));

export default function SingleGame(props: any) {
	const { game } = props;
	const { background_gradient_stop = "" } = game || {};
	return (
		<GameStyle>
			<div className="single-game-wrapper">
				<div className="position-relative game-info desktop-view">
					<div className="position-relative">
						{game?.service === "premium" && (
							<img
								src={premiumImg}
								alt={game.service}
								className="premium-icon"
							/>
						)}
						<img
							src={game?.thumbnail_image || game?.cover_image}
							alt={game?.title}
							className="image-style border-2 border-light mb-1"
						/>
						<TriangleBackground
							className="triangle-bg"
							fill={background_gradient_stop || "#5F18A8"}
						/>
					</div>

					<div className="triangle-div">
						<button className="triangle-button" type="button">
							<Play fill="#fff" className="icon-play" />
						</button>
					</div>
					<div className="info-container">
						<div className="game-name title-xs">
							<MDTypography
								sx={{
									fontSize: "0.5em",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									overflow: "hidden",
								}}
								color="white"
							>
								{game?.name}
							</MDTypography>
						</div>
						<div className="game-meta">
							<PeopleIcon
								height="0.5em"
								fill="#fff"
								className="arcade-icon"
							/>
							<Typography fontSize="0.5em" variant="caption">
								{game?.global_active_playser}
							</Typography>
						</div>
					</div>
				</div>
			</div>
		</GameStyle>
	);
}
