import * as yup from "yup";
// import checkout from "./form";

/* const {
	formField: { companyName, companySize, industryName, role, platformName },
} = checkout; */

const validations = [
	yup.object().shape({
		platform_type: yup.string().required("Platform type is required"),
	}),
	yup.object().shape({
		name: yup.string().required("Platform is required"),
		country: yup
			.string()
			.matches(/^(?!.*none).*$/, "Country is required")
			.required("Country is required"),
	}),
	yup.object().shape({
		integration_mode: yup
			.string()
			// .matches(/^(?!.*none).*$/, "Integration Mode is required")
			.required("Integration Mode is required"),
		technology: yup
			.string()
			// .matches(/^(?!.*none).*$/, "Technology is required")
			.required("Technology is required"),
		comment: yup.string().nullable(), // .required("Please specify technology"),
	}),
];

export const editValidations = [
	yup.object().shape({
		name: yup.string().required("Platform is required"),
		country: yup
			.string()
			.matches(/^(?!.*none).*$/, "Country is required")
			.required("Country is required"),
	}),
	yup.object().shape({
		integration_mode: yup
			.string()
			// .matches(/^(?!.*none).*$/, "Integration Mode is required")
			.required("Integration Mode is required"),
		technology: yup
			.string()
			// .matches(/^(?!.*none).*$/, "Technology is required")
			.required("Technology is required"),
		comment: yup.string().nullable(), // .required("Please specify technology"),
	}),
];

export default validations;
