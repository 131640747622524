/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IWorkspace } from "types/data";

export interface IWorkspaces {
	workspaces: IWorkspace[];
	loading: boolean;
	error?: null | any;
}

const initialState: IWorkspaces = {
	workspaces: [],
	loading: false,
	error: null,
};

const slice = createSlice({
	name: "workspaces",
	initialState,
	reducers: {
		fetchWorkspacesBegin: (state: IWorkspaces) => {
			state.loading = true;
		},
		fetchWorkspacesSuccess(
			state: IWorkspaces,
			action: PayloadAction<IWorkspace[]>
		): void {
			state.workspaces = action.payload;
			state.loading = false;
			state.error = false;
		},
		createWorkspaceSuccess: (
			state: IWorkspaces,
			action: PayloadAction<IWorkspace>
		) => {
			state.workspaces = [...state.workspaces, action.payload];
			state.loading = false;
		},
		fetchWorkspacesFail: (state: IWorkspaces) => {
			state.workspaces = [];
			state.loading = false;
			state.error = true;
		},
		clearWorkspaces: (state: IWorkspaces) => {
			state.workspaces = [];
			state.loading = false;
			state.error = null;
		},
	},
});

export const { actions, reducer } = slice;

export const {
	fetchWorkspacesBegin,
	fetchWorkspacesSuccess,
	fetchWorkspacesFail,
	createWorkspaceSuccess,
	clearWorkspaces,
} = actions;

export default reducer;
