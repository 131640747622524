/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import MDButton from "template/components/MDButton";

// Declaring props types for DefaultPricingCard
interface Props {
	color?:
		| "primary"
		| "secondary"
		| "info"
		| "success"
		| "warning"
		| "error"
		| "light"
		| "dark"
		| "white";
	badge: {
		color:
			| "primary"
			| "secondary"
			| "info"
			| "success"
			| "warning"
			| "error"
			| "light"
			| "grey-400"
			| "dark";
		label: string;
	};
	price: {
		currency: string;
		value: string;
		type: string;
	};
	specifications: {
		label: string;
		includes?: boolean;
	}[];
	action: {
		type: "external" | "internal";
		route: string;
		label: string;
		color:
			| "primary"
			| "secondary"
			| "info"
			| "success"
			| "warning"
			| "error"
			| "light"
			| "dark";
	};
	shadow?: boolean;
	[key: string]: any;
}

function PricingCardCurrentPlan({
	color,
	badge,
	price,
	specifications,
	action,
	shadow,
}: Props): JSX.Element {
	const renderSpecifications = specifications.map(({ label, includes }) => (
		<MDBox key={label} display="flex" alignItems="center" p={1}>
			<MDBox
				display="flex"
				justifyContent="center"
				alignItems="center"
				width="1.5rem"
				height="1.5rem"
				mr={2}
				mt={-0.125}
			>
				<MDTypography
					variant="body1"
					color={color === "white" ? "text" : "white"}
					sx={{ lineHeight: 0 }}
				>
					<Icon>{includes ? "done" : "remove"}</Icon>
				</MDTypography>
			</MDBox>
			<MDTypography
				variant="body2"
				color={color === "white" ? "text" : "white"}
				fontWeight="regular"
			>
				{label}
			</MDTypography>
		</MDBox>
	));

	return (
		<Card
			sx={{
				boxShadow: ({ boxShadows: { lg } }) => (shadow ? lg : "none"),
			}}
		>
			<MDBox
				bgColor={color}
				variant={color === "white" ? "contained" : "gradient"}
				borderRadius="xl"
			>
				<MDBox
					bgColor={badge.color}
					width="max-content"
					px={4}
					pt={0}
					pb={0.5}
					mx="auto"
					mt={-1.375}
					borderRadius="section"
					lineHeight={1}
					sx={{
						boxShadow: ({ boxShadows: { xs } }) => xs,
					}}
				>
					<MDTypography
						variant="caption"
						textTransform="capitalize"
						fontWeight="medium"
						color={badge.color === "light" ? "light" : "light"}
					>
						{badge.label}
					</MDTypography>
				</MDBox>

				<MDTypography
					sx={{
						fontSize: "0.7rem",
						paddingY: "0.2rem",
						paddingX: "0.3rem",
						position: "absolute",
						top: 30,
						background: ({ palette: { primary } }: any) =>
							primary?.main,
					}}
					color="white"
					variant="caption"
				>
					CURRENT PLAN
				</MDTypography>
				<MDBox pt={3} pb={2} px={2} textAlign="center">
					<MDBox>
						<MDTypography
							variant="h1"
							color={color === "white" ? "dark" : "white"}
						>
							{price.value}
							<MDTypography
								display="inline"
								component="small"
								variant="h5"
								color="inherit"
							>
								&nbsp;days
							</MDTypography>
							<MDTypography
								variant="body1"
								color="text"
								fontWeight="regular"
								sx={{
									marginBottom: 3,
								}}
							>
								1,000 Active players
							</MDTypography>
						</MDTypography>
					</MDBox>
					{/* <MDBox>
						<MDTypography
							variant="subtitle2"
							fontWeight="medium"
							color={badge.color === "light" ? "text" : "white"}
						>
							1,000 active players
						</MDTypography>
					</MDBox> */}
				</MDBox>
				<MDBox
					pb={3}
					px={3}
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<MDBox>{renderSpecifications}</MDBox>
					{action.type === "internal" ? (
						<MDBox
							mt={3}
							sx={{
								alignSelf: "flex-end",
							}}
						>
							<MDButton
								component={Link}
								to={action.route}
								variant="outlined"
								color={action.color}
								// fullWidth
							>
								{action.label}&nbsp;
								{/* <Icon sx={{ fontWeight: "bold" }}>
									arrow_forward
								</Icon> */}
							</MDButton>
						</MDBox>
					) : (
						<MDBox
							mt={3}
							sx={{
								alignSelf: "flex-end",
							}}
						>
							<MDButton
								component="a"
								href={action.route}
								target="_blank"
								rel="noreferrer"
								variant="outlined"
								color={action.color}
								// fullWidth
							>
								{action.label}&nbsp;
								{/* <Icon sx={{ fontWeight: "bold" }}>
									arrow_forward
								</Icon> */}
							</MDButton>
						</MDBox>
					)}
				</MDBox>
			</MDBox>
		</Card>
	);
}

// Declaring default props for DefaultPricingCard
PricingCardCurrentPlan.defaultProps = {
	color: "white",
	shadow: true,
};

export default PricingCardCurrentPlan;
