/* eslint-disable react/jsx-no-useless-fragment */
import {
	useState,
	useEffect,
	useMemo,
	JSXElementConstructor,
	Key,
	ReactElement,
} from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";

// import MDBox from "template/components/MDBox";
import Sidenav from "components/dashboard/Sidenav";
import Configurator from "components/dashboard/Configurator";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React TS routes
import routes, { unAuthenticatedRoutes } from "routes";
import { useMediaQuery } from "react-responsive";
// Material Dashboard 2 PRO React TS contexts
import {
	useMaterialUIController,
	setMiniSidenav,
	// setOpenConfigurator,
} from "context";

// Images
import brandWhite from "assets/images/logos/small_logo.png";
import brandDark from "assets/images/logo-ct-dark.png";

import { ToastContainer } from "react-toastify";

import FullScreenLoader from "components/loader/FullScreenLoader";
import { useSelector } from "react-redux";
import { fetchUserInfo } from "actions/auth";
import { fetchWorkspaces } from "actions/workspaces";
import WorkspaceModal from "components/modals/WorkspaceModal";
import {
	closeGetStartedModal,
	closeTournamentBuilderModal,
	openWorkspaceModal,
} from "slices/modalSlice";
import { useAppDispatch } from "store";
import PlatformIntegratorModal from "components/modals/PlatformIntegratorModal";
import TournamentBuilderModal from "components/modals/TournamentBuilderModal";
import { fetchTournaments } from "actions/tournamentActions";
import { fetchPlatforms } from "actions/platformActions";
// import { hotjar } from "react-hotjar";
import "react-toastify/dist/ReactToastify.css";
import AlertMessage from "components/modals/AlertMessage";
import { setIsDesktop } from "slices/layoutSlice";
import GetStartedModal from "components/modals/GetStartedModal";
import PreviewModal from "components/modals/PreviewModal";

export default function App() {
	const [controller, dispatch] = useMaterialUIController();
	const appDispatch = useAppDispatch();
	const {
		miniSidenav,
		direction,
		layout,
		// openConfigurator,
		sidenavColor,
		transparentSidenav,
		whiteSidenav,
		darkMode,
	} = controller;
	const [onMouseEnter, setOnMouseEnter] = useState(false);
	const [rtlCache, setRtlCache] = useState(null);
	const { pathname } = useLocation();
	const isDesktop = useMediaQuery({
		query: "(min-width: 1024px)",
	});

	useEffect(() => {
		appDispatch(setIsDesktop(isDesktop));
	}, [isDesktop]);

	// hotjar
	useEffect(() => {
		// hotjar.initialize(2946029, 6);
		// hotjar.identify("USER_ID", { userProperty: "value" });
	}, []);

	// Cache for the rtl
	useMemo(() => {
		const pluginRtl: any = rtlPlugin;
		const cacheRtl = createCache({
			key: "rtl",
			stylisPlugins: [pluginRtl],
		});

		setRtlCache(cacheRtl);
	}, []);

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatch, false);
			setOnMouseEnter(true);
		}
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatch, true);
			setOnMouseEnter(false);
		}
	};

	// Change the openConfigurator state
	/* const handleConfiguratorOpen = () =>
		setOpenConfigurator(dispatch, !openConfigurator); */

	// Setting the dir attribute for the body element
	useEffect(() => {
		document.body.setAttribute("dir", direction);
	}, [direction]);

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	const getRoutes = (allRoutes: any[]): any =>
		allRoutes.map(
			(route: {
				collapse?: any;
				route: string;
				component: ReactElement<
					any,
					string | JSXElementConstructor<any>
				>;
				key?: Key;
			}) => {
				if (route.collapse) {
					return getRoutes(route.collapse);
				}

				if (route.route) {
					return (
						<Route
							path={route.route}
							element={route.component}
							key={route.key}
						/>
					);
				}
				return null;
			}
		);

	/* const configsButton = (
		<MDBox
			display="flex"
			justifyContent="center"
			alignItems="center"
			width="3.25rem"
			height="3.25rem"
			bgColor="white"
			shadow="sm"
			borderRadius="50%"
			position="fixed"
			right="2rem"
			bottom="2rem"
			zIndex={99}
			color="dark"
			sx={{ cursor: "pointer" }}
			onClick={handleConfiguratorOpen}
		>
			<Icon fontSize="small" color="inherit">
				settings
			</Icon>
		</MDBox>
	); */

	const { fullScreenLoading, showWorkspaceModal, showPreviewAppModal } =
		useSelector((state: any) => state.modal);
	const { token } = useSelector((state: any) => state.auth);
	// const { workspaces } = useSelector((state: any) => state.workspaces);
	/* useEffect(() => {
		hotjar.identify("USER_ID", {
			userProperty: "value",
		});
	}, [user]); */

	useEffect(() => {
		if (token) {
			appDispatch(fetchUserInfo());
			appDispatch(fetchWorkspaces())
				.then((res: any) => {
					const workspaces = res;
					appDispatch(
						fetchPlatforms(
							(workspaces && workspaces[0]?.alias) || ""
						)
					);
					if (res && res.length === 0) {
						appDispatch(openWorkspaceModal());
					}
				})
				.catch(() => {
					// appDispatch(openWorkspaceModal());
				});
			appDispatch(fetchTournaments());
		}
	}, [token]);

	useEffect(() => {
		appDispatch(closeTournamentBuilderModal());
		appDispatch(closeGetStartedModal());
		return () => {
			appDispatch(closeTournamentBuilderModal());
			appDispatch(closeGetStartedModal());
		};
	}, []);

	const [usePC, setUsePC] = useState(false);

	useEffect(() => {
		if (window.innerWidth < 1024 && token) {
			setUsePC(true);
		}
	}, [token]);

	const { workspaces } = useSelector((state: any) => state.workspaces);
	const workspace =
		workspaces && workspaces.length > 0 ? workspaces[0] : null;

	const { user } = useSelector((state: any) => state.auth);

	const renderModals = () => (
		<>
			<AlertMessage
				// title="Please create a tournament first"
				message="For best experience, open it on your PC through Web browser"
				openDialog={usePC}
				handleConfirm={() => {
					setUsePC(false);
					// setOpenDialog(false);
					// dispatch(openTournamentBuilderModal());
				}}
				// handleCancel={() => setOpenDialog(false)}
				// cancelButtonText="OKAY"
				successButtonText="I understand"
			/>
			{showWorkspaceModal && token && <WorkspaceModal />}
			{fullScreenLoading && <FullScreenLoader />}
			<TournamentBuilderModal />
			<PlatformIntegratorModal />
			<GetStartedModal />
			{workspace && token && showPreviewAppModal && isDesktop && (
				<PreviewModal
					link={`${process.env.REACT_APP_TOURNAMENT_PLATFORM_URL}/tournament?app=${workspace?.alias}&country=ZZ&userid=${user?.email}`}
				/>
			)}
		</>
	);

	return (
		<>
			{direction === "rtl" ? (
				<CacheProvider value={rtlCache}>
					<ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
						<CssBaseline />
						{layout === "dashboard" && (
							<>
								<Sidenav
									color={sidenavColor}
									brand={
										(transparentSidenav && !darkMode) ||
										whiteSidenav
											? brandDark
											: brandWhite
									}
									brandName="Goama"
									routes={routes}
									onMouseEnter={handleOnMouseEnter}
									onMouseLeave={handleOnMouseLeave}
								/>
								<Configurator />
								{/* {configsButton} */}
							</>
						)}
						{layout === "vr" && <Configurator />}
						<Routes>
							{getRoutes(token ? routes : unAuthenticatedRoutes)}
							{token && (
								<Route
									path="*"
									element={<Navigate to="/dashboard" />}
								/>
							)}
							{!token && (
								<Route
									path="*"
									element={<Navigate to="/login" />}
								/>
							)}
						</Routes>
						{renderModals()}
					</ThemeProvider>
				</CacheProvider>
			) : (
				<ThemeProvider theme={darkMode ? themeDark : theme}>
					<CssBaseline />
					{layout === "dashboard" && (
						<>
							<Sidenav
								color={sidenavColor}
								brand={
									(transparentSidenav && !darkMode) ||
									whiteSidenav
										? brandDark
										: brandWhite
								}
								brandName="Goama"
								routes={routes}
								onMouseEnter={handleOnMouseEnter}
								onMouseLeave={handleOnMouseLeave}
							/>
							<Configurator />
							{/* {configsButton} */}
						</>
					)}
					{layout === "vr" && <Configurator />}
					<Routes>
						{getRoutes(token ? routes : unAuthenticatedRoutes)}
						{token && (
							<Route
								path="*"
								element={<Navigate to="/dashboard" />}
							/>
						)}
						{!token && (
							<Route
								path="*"
								element={<Navigate to="/login" />}
							/>
						)}
					</Routes>
					{renderModals()}
				</ThemeProvider>
			)}
			<ToastContainer />
		</>
	);
}
