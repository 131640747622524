/* eslint-disable import/prefer-default-export */
import { Dispatch } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
	fetchTournamentsBegin,
	fetchTournamentsFail,
	fetchTournamentsSuccess,
} from "slices/tournamentsSlice";
import { AppThunk } from "store";
// import { IPlatform } from "types/data";
import axiosClient from "utils/axios";

export const fetchTournaments =
	(): AppThunk =>
	async (dispatch: Dispatch): Promise<void> =>
		// eslint-disable-next-line no-new
		new Promise((resolve, reject) => {
			dispatch(fetchTournamentsBegin());
			axiosClient
				.get(`tournaments/`)
				.then((response: any) => {
					dispatch(fetchTournamentsSuccess(response.data));
					resolve(response.data);
				})
				.catch((error: any) => {
					dispatch(fetchTournamentsFail());
					reject(error);
				});
		});

export const updateTournamentStatus =
	(params: any): AppThunk =>
	async (dispatch: Dispatch): Promise<void> =>
		// eslint-disable-next-line no-new
		new Promise((resolve, reject) => {
			// dispatch(fetchTournamentsBegin());
			axiosClient
				.get(
					`tournaments/connect/?alias=${params.alias}&status=${params.status}`
				)
				.then((response: any) => {
					// dispatch(fetchTournamentsSuccess(response.data));
					dispatch(fetchTournaments() as any);
					resolve(response.data);
				})
				.catch((error: any) => {
					const { data = {} } = error;
					toast(data?.reason || "Something went wrong", {
						autoClose: 5000,
						type: "error",
						hideProgressBar: false,
						theme: "light",
					});
					// dispatch(fetchTournamentsFail());
					reject(error);
				});
		});

export const deleteTournament =
	(alias: string): AppThunk =>
	async (dispatch: Dispatch): Promise<void> =>
		// eslint-disable-next-line no-new
		new Promise((resolve, reject) => {
			// dispatch(fetchTournamentsBegin());
			axiosClient
				.delete(`/tournaments/${alias}/`)
				.then((response: any) => {
					// dispatch(fetchTournamentsSuccess(response.data));
					dispatch(fetchTournaments() as any);
					resolve(response.data);
				})
				.catch((error: any) => {
					// dispatch(fetchTournamentsFail());
					reject(error);
				});
		});
