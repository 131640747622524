import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import MDInput from "template/components/MDInput";

// Declaring props types for FormField
interface Props {
	label: string;
	name: string;
	[key: string]: any;
}

function FormField({
	label,
	name,
	register,
	error,
	...rest
}: Props): JSX.Element {
	return (
		<MDBox mb={1.5}>
			<MDInput
				{...rest}
				name={name}
				variant="outlined"
				label={label}
				fullWidth
				{...register(name)}
				error={!!error}
			/>
			<MDBox mt={0.75}>
				<MDTypography
					component="div"
					variant="caption"
					color="error"
					fontWeight="regular"
				>
					{error && `* ${error}`}
				</MDTypography>
			</MDBox>
		</MDBox>
	);
}

export default FormField;
