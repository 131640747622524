import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "template/components/MDButton";

export default function AlertMessage(props: any) {
	const {
		title,
		message,
		openDialog,
		handleCancel,
		handleClose,
		handleConfirm,
		cancelButtonText,
		successButtonText,
		successButtonColor,
	} = props;
	return (
		<Dialog
			open={openDialog}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<div style={{ padding: "1rem" }}>
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{message}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<MDButton
						onClick={handleCancel}
						variant="text"
						color="dark"
					>
						{cancelButtonText}
					</MDButton>
					<MDButton
						onClick={handleClose || handleConfirm}
						color={successButtonColor || "success"}
					>
						{successButtonText}
					</MDButton>
				</DialogActions>
			</div>
		</Dialog>
	);
}
