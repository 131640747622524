import React, { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
// import MDTypography from "template/components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
// import DashboardLayout from "template/examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "template/examples/Navbars/DashboardNavbar";
// import Footer from "template/examples/Footer";
// import ReportsBarChart from "template/examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "template/examples/Charts/LineCharts/ReportsLineChart";
// import ComplexStatisticsCard from "template/examples/Cards/StatisticsCards/ComplexStatisticsCard";
// import BookingCard from "template/examples/Cards/BookingCard";

// Anaytics dashboard components
// import SalesByCountry from "template/layouts/dashboards/analytics/components/SalesByCountry";

// Data
// import reportsBarChartData from "template/layouts/dashboards/analytics/data/reportsBarChartData";
// import reportsLineChartData from "template/layouts/dashboards/analytics/data/reportsLineChartData";

// Images
// import booking1 from "assets/images/backgrounds/hyper-casual-games.png";
// import booking2 from "assets/images/backgrounds/gaming-trends.png";
// import booking3 from "assets/images/backgrounds/gamification-strategy.png";
import DashboardLayout from "template/layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
// import ConnectFirstPlatformCard from "containers/Integration/components/ConnectFirstPlatformCard";
// import TournamentCard from "containers/Tournaments/components/TournamentCard";
import { useDispatch, useSelector } from "react-redux";
import {
	// openPlatformIntegratorModal,
	openTournamentBuilderModal,
} from "slices/modalSlice";
import AlertMessage from "components/modals/AlertMessage";
// import { TOURNAMENT_STATUS } from "constants/constants";
// import BlogCard from "components/cards/BlogCard";
import VideoCard from "components/cards/VideoCard";
import TrialDurationAlert from "components/alerts/TrialDurationAlert";
// import { useNavigate } from "react-router-dom";
// import StepCard from "components/cards/StepCard";
import MDTypography from "template/components/MDTypography";
import StepsGuide from "components/shared/StepsGuide";

function Home() {
	// const navigate = useNavigate();
	const [openTournamentRequiredDialog, setOpenTournamentRequiredDialog] =
		useState(false);
	const dispatch = useDispatch();
	const { tournaments } = useSelector(
		(state: any) => state.tournamentsReducer
	);
	const hasTournaments = tournaments && tournaments.length > 0;
	const { platforms } = useSelector((state: any) => state.platformsReducer);
	const platform =
		platforms && platforms.length > 0 && platforms[0] ? platforms[0] : null;

	// const countActiveTournaments = () => {
	// 	const activeTournaments = tournaments.filter(
	// 		(tournament: any) => tournament.status === TOURNAMENT_STATUS.ON
	// 	);
	// 	return activeTournaments.length;
	// };
	// Action buttons for the BookingCard
	/* const actionButtons = (
		<>
			<Tooltip title="Refresh" placement="bottom">
				<MDTypography
					variant="body1"
					color="primary"
					lineHeight={1}
					sx={{ cursor: "pointer", mx: 3 }}
				>
					<Icon color="inherit">refresh</Icon>
				</MDTypography>
			</Tooltip>
			<Tooltip title="Edit" placement="bottom">
				<MDTypography
					variant="body1"
					color="info"
					lineHeight={1}
					sx={{ cursor: "pointer", mx: 3 }}
				>
					<Icon color="inherit">edit</Icon>
				</MDTypography>
			</Tooltip>
		</>
	); */
	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox py={3}>
				<TrialDurationAlert />
				<MDBox pb={1} pt={1}>
					<MDTypography variant="h5" color="secondary">
						STEPS GUIDE
					</MDTypography>
				</MDBox>
				<StepsGuide />
				{(!hasTournaments || !platform) && (
					<MDBox mt={2}>
						{/* <Grid container>
							<Grid item lg={4} mb={5} pb={2}>
								<CustomSelect />
							</Grid>
						</Grid> */}
						<Grid container spacing={3}>
							{!hasTournaments && (
								<>
									{/* <Grid item xs={12} md={6} lg={6}>
										<ListCard
											title="Upcoming Tournaments"
											tournamnetsList={tournamnetsList}
										/>
									</Grid> */}
									{/* <Grid item xs={12} md={6} lg={6}>
										<MDBox mb={3}>
											<TournamentCard />
										</MDBox>
									</Grid> */}
								</>
							)}
							{/* {!platform && (
								<Grid item xs={12} md={6} lg={6}>
									<MDBox mb={3}>
										<ConnectFirstPlatformCard />
									</MDBox>
								</Grid>
							)} */}
						</Grid>
						{/* start graphs tab */}
						{/* <Grid container my={3}>
							<Grid item lg={10}>
								<GraphsTab />
							</Grid>
						</Grid> */}
						{/* end graphs tab */}
					</MDBox>
				)}
				{/* {!(hasTournaments || !platform) && ( */}
				{/* <MDBox mt={6}>
						<Grid container spacing={3}>
							{!hasTournaments && (
								<Grid item xs={12} md={6} lg={6}>
									<MDBox mb={3}>
										<TournamentCard />
									</MDBox>
								</Grid>
							)}
							{!platform && (
								<Grid item xs={12} md={6} lg={6}>
									<MDBox mb={3}>
										<ConnectFirstPlatformCard />
									</MDBox>
								</Grid>
							)}
						</Grid>
					</MDBox> */}
				{/* )} */}
				{/* <MDBox mt={3}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="success"
									icon="sports_esports"
									title="Active Tournaments"
									count={countActiveTournaments()}
									action={
										!hasTournaments && {
											color: "secondary",
											label: "SELECT GAMES FOR MY APP",
											handleClick: () => {
												dispatch(
													openTournamentBuilderModal()
												);
											},
										}
									}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="dark"
									icon="devices"
									title="Platforms Connected"
									count={`${platforms?.length || 0}`}
									action={
										!platform && {
											color: "secondary",
											label: "CONNECT YOUR FIRST PLATFORM",
											handleClick: () => {
												if (!hasTournaments) {
													dispatch(
														setOpenTournamentRequiredDialog(
															true
														)
													);
												} else {
													navigate("/integration");
												}
											},
										}
									}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="secondary"
									icon="group"
									title="Today's Users"
									count="0"
									percentage={{
										color: "success",
										amount: "+0%",
										label: "No data yet",
									}}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="info"
									icon="pending_actions"
									title="Average Gameplay"
									count="00:00"
									percentage={{
										color: "success",
										amount: "+0%",
										label: "No data yet",
									}}
								/>
							</MDBox>
						</Grid>
					</Grid>
				</MDBox> */}
				<MDBox mt={2}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mt={3}>
								<VideoCard
									type="iframe"
									title="Getting Started"
									description="Beginner's Guide"
									href="https://www.youtube.com/embed/SnfhZvUWNCc"
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mt={3}>
								<VideoCard
									type="iframe"
									title="React Native"
									description="Integration Guide"
									href="https://www.youtube.com/embed/-PnkslfUFuc"
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mt={3}>
								<VideoCard
									type="iframe"
									title="Native Android"
									description="Integration Guide"
									href="https://www.youtube.com/embed/65Oudj5bsYk"
								/>
							</MDBox>
						</Grid>
						{/* <Grid item xs={12} md={6} lg={4}>
							<MDBox mt={3}>
								<BlogCard
									image={booking1}
									title="What are Hyper-Casual Games?"
									description="Mobile gaming has taken a quantum leap in recent years, becoming the predominant force in the gaming industry. Hyper-casual games are behind this phenomenal success, roping in even non-gamers to put in as much as a few hours every week playing them. These easy-to-learn games have offered many respites from an increasingly stressful outside world, especially during the recent pandemic. Games like trivia and puzzles have integrated a social ecosystem around them that allows people to interact and compete."
									price="Author: David"
									href="https://goama.com/goama/what-are-hyper-casual-games/"
									// location="Barcelona, Spain"
									// action={actionButtons}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mt={3}>
								<BlogCard
									image={booking2}
									title="Gaming Trends in 2022 and beyond"
									description="As the pandemic slowly wears thin, many industries still struggle to find their footing back. Not gaming – among a handful of markets that saw a spectacular rise in the past two years. Globally, the evolution of gaming off late has significantly impacted game dynamics, but some trends have become more apparent than others. The genre-defining shifts in gaming that we have seen in 2021 are likely to continue to 2022 and beyond, shaping the games we know, how we play and interact with them, and even in-game milestones and rewards."
									price="Author: David"
									href="https://goama.com/goama/gaming-trends-in-2022-and-beyond/"
									// location="London, UK"
									// action={actionButtons}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mt={3}>
								<BlogCard
									image={booking3}
									title="How Gamification Can Boost App Sales"
									description="The mobile app market is booming. The skyrocketing number of smartphone users has propelled most marketing, innovation, and cutting-edge technologies to focus on the mobile sector. As a result, 2.5 million Android apps, 1.8 million iOS apps, and another 1.2 million apps between Amazon and Windows stores are used by roughly 5.5 billion smartphone users. With so many apps vying for attention, gamification can grant your app the edge it needs to stand out."
									price="Author: David"
									href="https://goama.com/goama/how-gamification-can-boost-app-sales/"
									// location="Milan, Italy"
									// action={actionButtons}
								/>
							</MDBox>
						</Grid> */}
					</Grid>
				</MDBox>
			</MDBox>
			<AlertMessage
				title="Please create a tournament first"
				message="You have to create a tournament before you can add a platform."
				openDialog={openTournamentRequiredDialog}
				handleConfirm={() => {
					setOpenTournamentRequiredDialog(false);
					dispatch(openTournamentBuilderModal());
				}}
				handleCancel={() => setOpenTournamentRequiredDialog(false)}
				cancelButtonText="cancel"
				successButtonText="create"
			/>
		</DashboardLayout>
	);
}

export default Home;
