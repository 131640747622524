import { useState } from "react";

import MDButton from "template/components/MDButton";

export default function ToggleButton(props: any) {
	const { title, active = false, size = "medium" } = props;
	const [activeButton, setActiveButton] = useState(active);
	const handleClick = () => {
		setActiveButton(!activeButton);
	};
	return (
		<MDButton
			variant={activeButton ? "gradient" : "outlined"}
			color={activeButton ? "secondary" : "secondary"}
			size={size}
			onClick={handleClick}
		>
			{title}
		</MDButton>
	);
}
