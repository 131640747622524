import { styled } from "@mui/system";
import { fetchGoogleAuthUrl } from "actions/auth";

const ButtonContainer = styled("button")({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "291.69px",
	maxWidth: "100%",
	height: "53.3px",
	borderRadius: "23px",
	margin: "0 auto",
	cursor: "pointer",
	borderColor: "rgba(52, 71, 103,0.2)", // TODO: change to theme.colors.primary when theme is ready or use proper color from theme
	borderWidth: "0.5px",
	background: "#fff", // TODO: change to theme.palette.primary.main when theme is ready or use proper color from theme
	img: {
		maxWidth: "16px",
		marginRight: "15px",
	},
	span: {
		fontWeight: "800",
		fontSize: ".9rem",
	},
});

type buttonType = {
	icon: string;
	title: string;
	authProvider: string;
};

export default function AuthButton({ icon, title, authProvider }: buttonType) {
	const onClick = async () => {
		if (authProvider === "google") {
			const url = await fetchGoogleAuthUrl();
			const { login } = url || null;
			if (login) {
				window.location.href = login;
			}
			// console.log({ url });
		} else {
			// console.log("signup");
		}
	};
	return (
		<ButtonContainer type="button" onClick={onClick}>
			<img src={icon} alt={title} />
			<span>{title}</span>
		</ButtonContainer>
	);
}
