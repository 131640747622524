import { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import { Theme } from "@mui/material/styles";
import DESKTOP_ICON from "assets/images/icons/desktop-color.png";
import MOBILE_ICON from "assets/images/icons/cellphone.png";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
import MDButton from "template/components/MDButton";
import MDTypography from "template/components/MDTypography";

import FormField from "components/elements/FormField";

const customButtonStyles = ({
	functions: { pxToRem, rgba },
	borders: { borderWidth },
	palette: { transparent, secondary },
	typography: { size },
}: Theme) => ({
	width: pxToRem(154),
	height: pxToRem(120),
	borderWidth: borderWidth[2],
	mb: 1,
	ml: 0.5,

	"&.MuiButton-contained, &.MuiButton-contained:hover": {
		boxShadow: "none",
		border: `${borderWidth[2]} solid ${transparent.main}`,
	},

	"&:hover": {
		backgroundColor: `${transparent.main} !important`,
		border: `${borderWidth[2]} solid ${secondary.main} !important`,
		color: rgba(secondary.main, 0.75),
	},

	"& .MuiSvgIcon-root": {
		fontSize: `${size["2xl"]} !important`,
	},
});

const OptionButtonStyle = styled("div")(() => ({
	position: "relative",
	"& .radio-container": {
		opacity: "1",
		zIndex: "99",
		cursor: "pointer",
		fontSize: "0",
		"& input[type='radio']": {
			position: "absolute",
			left: "0",
			top: "-5px",
			width: "100%",
			height: "150px",
			zIndex: "9",
			opacity: "0.01",
			fontSize: "0",
		},
	},
	"& .MuiInputLabel-root, fieldset": {
		display: "none",
	},
}));

function PlatformTypeForm({ form }: any): JSX.Element {
	const { register, getValues } = form;

	const [website, setWebsite] = useState(false);
	const [mobileApps, setMobileApps] = useState(false);
	const handleSetWebsite = () => {
		setWebsite(!website);
	};
	const handleSetMobileApps = () => setMobileApps(!mobileApps);

	return (
		<MDBox>
			<MDBox width="80%" textAlign="center" mx="auto" my={4}>
				<MDBox width="82%" textAlign="center" mx="auto" mt={5} pt={5}>
					<MDTypography variant="h5" fontWeight="regular">
						What kind of platform you want to connect?
					</MDTypography>
				</MDBox>
				<MDTypography variant="body2" color="text">
					Select one option
				</MDTypography>
			</MDBox>
			<MDBox mt={2}>
				<Grid container spacing={3} justifyContent="center">
					<Grid item xs={12} xl={3} md={4} lg={3} sm={4}>
						<OptionButtonStyle onClick={handleSetWebsite}>
							<div className="radio-container">
								<FormField
									register={register}
									type="radio"
									label="Platform type"
									name="platform_type"
									value="website"
									placeholder="Platform type"
								/>
							</div>

							<MDBox textAlign="center">
								<MDButton
									color="secondary"
									variant={
										getValues("platform_type") === "website"
											? "contained"
											: "outlined"
									}
									sx={customButtonStyles}
								>
									<img
										src={DESKTOP_ICON}
										alt="desktop"
										width="50%"
									/>
								</MDButton>
								<MDTypography variant="h6" sx={{ mt: 1 }}>
									Website
								</MDTypography>
							</MDBox>
						</OptionButtonStyle>
					</Grid>
					<Grid item xs={12} xl={3} lg={3} md={4} sm={4}>
						<OptionButtonStyle onClick={handleSetMobileApps}>
							<div className="radio-container">
								<FormField
									register={register}
									type="radio"
									label="Platform type"
									name="platform_type"
									value="mobile"
									placeholder="Platform type"
									checked
								/>
							</div>
							<MDBox textAlign="center">
								<MDButton
									color="secondary"
									variant={
										getValues("platform_type") === "mobile"
											? "contained"
											: "outlined"
									}
									sx={customButtonStyles}
								>
									<img
										src={MOBILE_ICON}
										alt="mobile"
										width="30%"
									/>
								</MDButton>
								<MDTypography variant="h6" sx={{ mt: 1 }}>
									Mobile App
								</MDTypography>
							</MDBox>
						</OptionButtonStyle>
					</Grid>
				</Grid>
			</MDBox>
		</MDBox>
	);
}

export default PlatformTypeForm;
