const form = {
	formId: "tournament-builder-form",
	formField: {
		templateName: {
			name: "templateName",
			label: "templat Name",
			type: "radio",
			errorMsg: "template name is required.",
		},
		selectGame: {
			name: "selectGame",
			label: "Select Game",
			type: "select",
			errorMsg: "template name is required.",
		},
		tournamentTitle: {
			name: "tournamentTitle",
			label: "Tournament title",
			type: "text",
			errorMsg: "tournament title is required.",
		},
		tournamentSubtitle: {
			name: "tournamentSubtitle",
			label: "Tournament sub title",
			type: "text",
			errorMsg: "tournament sub title is required.",
		},
		tournamentType: {
			name: "tournamentType",
			label: "Tournament type",
			type: "text",
			errorMsg: "tournament type is required.",
		},
		gameMode: {
			name: "gameMode",
			label: "game mode",
			type: "text",
			errorMsg: "game mode is required.",
		},
		tournamentStartDate: {
			name: "tournamentStartDate",
			label: "tournament start date",
			type: "text",
			errorMsg: "tournament start date is required.",
		},
		tournamentEndDate: {
			name: "tournamentEndDate",
			label: "tournament end date",
			type: "text",
			errorMsg: "tournament end date is required.",
		},
		gameReward: {
			name: "gameReward",
			label: "game reward",
			type: "text",
			errorMsg: "game reward is required.",
		},
		gameRewardData: {
			name: "gameRewardData",
			label: "game reward data",
			type: "text",
			errorMsg: "game reward data is required.",
		},
		playerSessions: {
			name: "playerSessions",
			label: "player sessions",
			errorMsg: "player sessions is required.",
		},
	},
};

export default form;
