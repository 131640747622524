import { parseISO, format } from "date-fns";
import { format as tzFormat, utcToZonedTime } from "date-fns-tz";

/* eslint-disable import/prefer-default-export */
export const dateFormat = (date: Date, pattern: string): string => {
	try {
		return date ? format(new Date(date), pattern) : null;
	} catch (e) {
		return "Invalid date";
	}
};

export const removeTimezoneFromDateString = (date: string): string => {
	if (!date) return "";
	const parsedTime = parseISO(date);
	const formattedTime = tzFormat(
		utcToZonedTime(parsedTime, "UTC"),
		"yyyy-MM-dd'T'HH:mm:ss",
		{
			timeZone: "UTC",
		}
	);
	return formattedTime;
};

export const getDifferenceInDays = (
	date1: string,
	date2: string
): number | string => {
	try {
		if (!date1 || !date2) return "";
		const parsedTime1 = parseISO(date1);
		const parsedTime2 = parseISO(date2);
		const diff = Math.abs(parsedTime1.getTime() - parsedTime2.getTime());
		return Math.ceil(diff / (1000 * 3600 * 24));
	} catch (e) {
		return "";
	}
};
