import { styled } from "@mui/system";
import MDTypography from "template/components/MDTypography";

type optionType = {
	icon: string;
	title: string;
	selected: boolean;
};

const OptionBoxStyle = styled("div")(({ theme }) => ({
	position: "relative",
	"& .opt-thumb": {
		cursor: "pointer",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		maxWidth: "100%",
		minHeight: "120px",
		backgroundColor: theme.palette.dark.light,
		marginBottom: "18px",
		textAlign: "center",
		boxSizing: "border-box",
		borderRadius: "7.18641px",
		border: `3.2px solid ${theme.palette.secondary.main}`,
		transition: "all .4s linear 0s",
		"& img": {
			width: "65px",
			height: "65px",
			objectFit: "cover",
		},
	},
	"& .opt-content": {
		textAlign: "center",
	},
	"& .opt-container": {
		width: "152px",
		marginRight: "12px",
		position: "relative",
		transition: "all .4s linear 0s",
		"&.active-opt": {
			"& .opt-thumb": {
				background: `${theme.palette.dark.light} linear-gradient(180deg, ${theme.palette.secondary.main} 0%,  ${theme.palette.secondary.light} 100%)`,
				transition: "all .4s linear 0s",
			},
			"& .opt-content": {
				"& .option-title": {
					color: theme.palette.secondary.main,
					whiteSpace: "wrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
					wordBreak: "break-word",
				},
			},
		},
	},
}));

export default function OptionBox(props: optionType) {
	const { icon, title, selected } = props;
	return (
		<OptionBoxStyle>
			<div
				className={
					selected ? "opt-container active-opt" : "opt-container"
				}
			>
				<div className="opt-thumb">
					<img src={icon} alt={title} />
				</div>
				<div className="opt-content text-center">
					<MDTypography className="option-title" variant="body2">
						{title}
					</MDTypography>
				</div>
			</div>
		</OptionBoxStyle>
	);
}
