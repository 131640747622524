// import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import TextField from "@mui/material/TextField";

// Material Dashboard 2 PRO React TS components
import MDAvatar from "template/components/MDAvatar";
import MDButton from "template/components/MDButton";
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";

import FormField from "components/elements/FormField";

// Images
// eslint-disable-next-line import/no-duplicates
import rappiLogo from "assets/images/avatars/dummy_user.png";
// eslint-disable-next-line import/no-duplicates
import avatar from "assets/images/avatars/dummy_user.png";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axiosClient from "utils/axios";
import { useAppDispatch } from "store";
import { fetchUserInfo } from "actions/auth";

// import { useForm } from "react-hook-form";

const AvatarContainer = styled("div")(() => ({
	position: "relative",
	maxWidth: "80px",
	margin: "0 auto",
}));

const companies = [
	{
		value: "1-15 employees",
		label: "1-15 employees",
	},
	{
		value: "15-100 employees",
		label: "15-100 employees",
	},
	{
		value: "101-500 employees",
		label: "101-500 employees",
	},
	{
		value: "501-1500 employees",
		label: "501-1500 employees",
	},
	{
		value: "1501+ employees",
		label: "1501+ employees",
	},
];
const industries = [
	{
		value: "Ecommerce",
		label: "Ecommerce/Retail/Super App",
	},
	{
		value: "Mobility",
		label: "Mobility",
	},
	{
		value: "Food & Delivery",
		label: "Food & Delivery",
	},
	{
		value: "Fintech",
		label: "Fintech (E-Banks/E-Wallets)",
	},
	{
		value: "Entertainment",
		label: "Entertainment",
	},
	{
		value: "Technology /Telecommunications",
		label: "Technology /Telecommunications",
	},
	{
		value: "Gaming Software/Hardware",
		label: "Gaming Software/Hardware",
	},
	{
		value: "Digital Saas/Services",
		label: "Digital Saas/Services",
	},
	{
		value: "Health & Wellness",
		label: "Health & Wellness",
	},
	{
		value: "Travel and tourism",
		label: "Travel and tourism",
	},
	{
		value: "News",
		label: "News",
	},
	{
		value: "Other",
		label: "Other",
	},
];

const allRoles = [
	{
		value: "Technical",
		label: "Technical",
	},
	{
		value: "Innovation",
		label: "Innovation",
	},
	{
		value: "Marketing",
		label: "Marketing",
	},
	{
		value: "Growth",
		label: "Growth",
	},
	{
		value: "New Business",
		label: "New Business",
	},
	{
		value: "Other",
		label: "Other",
	},
];

function Workspace({ form }: any): JSX.Element {
	const dispatch = useAppDispatch();
	const { user, token } = useSelector((state: any) => state.auth);
	const [profileImage, setProfileImage] = useState(null);
	const [workspaceImage, setWorkspaceImage] = useState(null);
	const fileInput = useRef<any>();
	const fileWorkspaceInput = useRef<any>();
	const {
		register,
		formState: { errors },
		setValue,
		getValues,
	} = form;

	useEffect(() => {
		setWorkspaceImage(getValues("profile"));
	}, [getValues("profile")]);

	return (
		<form>
			<MDBox>
				<MDBox width="82%" textAlign="center" mx="auto" my={2}>
					<MDBox mb={1}>
						<MDBox mb={2}>
							<AvatarContainer>
								<MDAvatar
									src={
										profileImage
											? URL.createObjectURL(profileImage)
											: user?.profile_pic || avatar
									}
									alt="profile picture"
									size="xl"
									variant="circular"
								/>
								<MDBox
									alt="spotify logo"
									position="absolute"
									right={8}
									bottom={0}
									mr={-1}
									mb={-1}
								>
									<Tooltip title="Edit" placement="top">
										<MDButton
											variant="gradient"
											color="secondary"
											size="small"
											className="avatar-edit-btn"
											iconOnly
											onClick={() =>
												fileInput &&
												fileInput?.current &&
												fileInput?.current?.click()
											}
										>
											<Icon>edit</Icon>
											<input
												key="user_pic"
												onChange={(e: any) => {
													const formData =
														new FormData();
													formData.append(
														"profile_pic",
														e.target.files[0]
													);
													axiosClient
														.patch(
															"/users/me/",
															formData,
															{
																headers: {
																	Authorization: `Bearer ${token}`,
																	"Content-Type":
																		"multipart/form-data",
																},
															}
														)
														.then(() => {
															setProfileImage(
																e.target
																	.files[0]
															);
															dispatch(
																fetchUserInfo()
															);
														});
													/* setValue(
														"profile",
														e.target.files[0]
													); */
												}}
												ref={fileInput}
												type="file"
												style={{ display: "none" }}
											/>
										</MDButton>
									</Tooltip>
								</MDBox>
							</AvatarContainer>
						</MDBox>
						<MDTypography variant="h5" fontWeight="regular">
							Welcome <b>{user?.first_name}!</b> Let’s create your
							Workspace
						</MDTypography>
					</MDBox>
					<MDTypography variant="body2" color="text">
						Let us know your name and email address. Use an address
						you don&apos;t mind other users contacting you at
					</MDTypography>
				</MDBox>
				<MDBox mt={1.625}>
					<Grid
						item
						sm={12}
						xs={12}
						lg={12}
						container
						spacing={2}
						mx="auto"
						justifyContent="center"
					>
						<Grid
							lg={3}
							md={3}
							xs={12}
							sm={12}
							container
							mt={2}
							justifyContent="center"
						>
							<MDBox
								position="relative"
								height="max-content"
								mb={2}
							>
								<MDAvatar
									src={
										workspaceImage
											? URL.createObjectURL(
													workspaceImage
											  )
											: rappiLogo
									}
									alt="profile picture 2"
									size="xxl"
									variant="rounded"
								/>
								<MDBox
									alt="spotify logo"
									position="absolute"
									right={-8}
									bottom={0}
									mb={-1}
									mx="auto"
								>
									<Tooltip title="Edit" placement="top">
										<MDButton
											className="avatar-edit-btn"
											variant="gradient"
											color="secondary"
											size="small"
											iconOnly
											onClick={() =>
												fileWorkspaceInput &&
												fileWorkspaceInput?.current &&
												fileWorkspaceInput?.current?.click()
											}
										>
											<Icon>edit</Icon>
											<input
												onChange={(e: any) => {
													setValue(
														"profile",
														e.target.files[0]
													);
													setWorkspaceImage(
														e.target.files[0]
													);
												}}
												ref={fileWorkspaceInput}
												type="file"
												style={{ display: "none" }}
											/>
										</MDButton>
									</Tooltip>
								</MDBox>
							</MDBox>
						</Grid>

						<Grid item lg={8} xs={10} container spacing={3}>
							<Grid item xs={12} sm={6}>
								<FormField
									error={errors?.name?.message}
									register={register}
									label="Company Name"
									name="name"
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									{...register("size")}
									select
									label="Company Size"
									name="size"
									placeholder="Company Size"
									error={!!errors?.size?.message}
									SelectProps={{
										native: true,
									}}
									fullWidth
								>
									<option value="none" disabled>
										Select company size:
									</option>
									{companies.map((option) => (
										<option
											key={option.value}
											value={option.value}
										>
											{option.label}
										</option>
									))}
								</TextField>
								<MDBox mt={0.75}>
									<MDTypography
										component="div"
										variant="caption"
										color="error"
										fontWeight="regular"
									>
										{errors?.size?.message &&
											`* ${errors?.size?.message}`}
									</MDTypography>
								</MDBox>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									{...register("industry")}
									select
									label="Industry"
									name="industry"
									// defaultValue={industryNameV}
									placeholder="Select industry"
									error={!!errors?.industry?.message}
									SelectProps={{
										native: true,
									}}
									// required
									fullWidth
								>
									<option value="none" disabled>
										Select industry:
									</option>
									{industries.map((option) => (
										<option
											key={option.value}
											value={option.value}
										>
											{option.label}
										</option>
									))}
								</TextField>
								<MDBox mt={0.75}>
									<MDTypography
										component="div"
										variant="caption"
										color="error"
										fontWeight="regular"
									>
										{errors?.industry?.message &&
											`* ${errors?.industry?.message}`}
									</MDTypography>
								</MDBox>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									{...register("role")}
									select
									label="Department"
									name="role"
									// defaultValue={roleV}
									// placeholder="Select department"
									error={!!errors?.role?.message}
									SelectProps={{
										native: true,
									}}
									// required
									fullWidth
								>
									<option value="none" disabled>
										Select department:
									</option>
									{allRoles.map((option) => (
										<option
											key={option.value}
											value={option.value}
										>
											{option.label}
										</option>
									))}
								</TextField>
								<MDBox mt={0.75}>
									<MDTypography
										component="div"
										variant="caption"
										color="error"
										fontWeight="regular"
									>
										{errors?.role?.message &&
											`* ${errors?.role?.message}`}
									</MDTypography>
								</MDBox>
							</Grid>
						</Grid>
					</Grid>
				</MDBox>
			</MDBox>
		</form>
	);
}

export default Workspace;
