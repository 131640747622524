import { ReactNode } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import MDButton from "template/components/MDButton";

// Declaring props types for CompleStatisticsCard
interface Props {
	color?:
		| "primary"
		| "secondary"
		| "info"
		| "success"
		| "warning"
		| "error"
		| "light"
		| "dark";
	title: string;
	description: ReactNode;
	actionBottomLeft?: {
		handleClick: () => void;
	};
	actionBottomRight?: {
		color: // | "inherit"
		// | "disabled"
		// | "action"
		"info" | "success" | "primary" | "secondary" | "warning" | "error";
		label: string;
		disabled?: boolean;
		handleClick: () => void;
	};
	icon: ReactNode;
	[key: string]: any;
}

function StepCard({
	color,
	title,
	subtitle,
	description,
	actionBottomLeft,
	icon,
	actionBottomRight,
}: Props): JSX.Element {
	return (
		<Card
			sx={{
				height: "100%",
			}}
		>
			<MDBox
				position="relative"
				display="flex"
				justifyContent="space-between"
				pt={1}
				px={2}
				sx={{
					flexGrow: 1,
				}}
			>
				<MDBox
					variant="gradient"
					bgColor={color}
					color={color === "light" ? "dark" : "white"}
					coloredShadow={color}
					borderRadius="xl"
					display="flex"
					justifyContent="center"
					alignItems="center"
					width="4rem"
					height="4rem"
					mt={-3}
					sx={{
						position: "absolute",
					}}
				>
					<Icon fontSize="medium" color="inherit">
						{icon}
					</Icon>
				</MDBox>
				<MDBox textAlign="right" lineHeight={1.25}>
					<MDBox
						sx={{
							display: "flex",
							flexDirection: "column",
							flexWrap: "wrap",
							justifyContent: "center",
							alignItems: "flex-end",
						}}
					>
						<MDTypography color={color} variant="h6">
							{title}
						</MDTypography>
						<MDTypography
							sx={{
								maxWidth: "70%",
							}}
							color={color}
							variant="h6"
						>
							{subtitle}
						</MDTypography>
					</MDBox>
					<MDTypography
						variant="button"
						fontWeight="light"
						color="text"
						sx={{
							fontSize: "0.6em",
						}}
					>
						{description}
					</MDTypography>
				</MDBox>
			</MDBox>
			<Divider />
			<MDBox
				sx={{
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					flexDirection: "row",
					flexWrap: "wrap",
				}}
			>
				{actionBottomLeft && (
					<MDBox
						onClick={actionBottomLeft?.handleClick}
						pb={2}
						px={2}
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
							textAlign: "right",
							cursor: "pointer",
						}}
					>
						<MDTypography
							color="text"
							component="p"
							variant="button"
							fontWeight="bold"
							display="flex"
							sx={{
								textAlign: "right",
								textDecoration: "underline",
							}}
						>
							Know More
						</MDTypography>
						{/* <Icon color={actionBottomLeft?.color}>
							arrow_right_alt
						</Icon> */}
					</MDBox>
				)}
				{actionBottomRight?.label && (
					<MDBox
						onClick={
							!actionBottomRight.disabled &&
							actionBottomRight?.handleClick
						}
						pb={2}
						px={2}
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
							textAlign: "right",
							cursor: "pointer",
						}}
					>
						<MDButton
							disabled={actionBottomRight?.disabled}
							variant="gradient"
							color={
								!actionBottomRight?.disabled
									? actionBottomRight?.color
									: "light"
							}
						>
							{actionBottomRight?.label}
						</MDButton>
					</MDBox>
				)}
			</MDBox>
		</Card>
	);
}

// Declaring defualt props for ComplexStatisticsCard
StepCard.defaultProps = {
	color: "info",
	actionBottomLeft: {
		handleClick: () => {},
	},
	actionBottomRight: {
		disabled: false,
		color: "success",
		text: "",
		label: "",
		handleClick: () => {},
	},
};

export default StepCard;
