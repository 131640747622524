/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
export enum TOURNAMENT_STATUS {
	ON = 1,
	OFF = 2,
	SCHEDULED = 3,
	COMPLETED = 4,
	DRAFT = 5,
}

export enum PRIZE_TYPE {
	PHYSICAL_PRIZE = 1,
	TICKET = 2,
	COIN = 3,
	RAZER_GOLD = 4,
	RAZER_SILVER = 5,
	CASH = 6,
	ASTRO_COIN = 7,
	PEPSICO_POINT = 8,
	GIZER_BOOST = 9,
}

export enum GENDER {
	MALE = 1,
	FEMALE = 2,
	OTHERS = 3,
	PREFER_NOT_SAY = 4,
}

export const BOOK_A_CALL_URL =
	"https://meetings.hubspot.com/aakanksha-singh/need-help";
export const MEETING_CALL_URL = "https://meetings.hubspot.com/aakanksha-singh";
