import { useNavigate } from "react-router-dom";
import History from "./History";

function NavigateSetter(): JSX.Element {
	History.navigate = useNavigate();

	return null;
}

export default NavigateSetter;
