import { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import { ReactComponent as CalendarIcon } from "assets/images/icons/calendar.svg";
import TimezoneSelect, { ITimezone } from "react-timezone-select";
// import FormField from "components/elements/FormField";
// import TextField from "@mui/material/TextField";

import { styled } from "@mui/system";

const InputStyles = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "Center",
	marginTop: "30px",
	[theme.breakpoints.down("md")]: {
		minWidth: "100%",
	},
	"& .date-fiels-container": {
		marginBottom: "25px",
		"& input": {
			minWidth: "225px",
		},
	},
}));

const TimezoneSelectContainerStyle = styled("div")(() => ({
	fontSize: "14px !important",
}));

export default function TimeDuration({ form }: any) {
	const { register, getValues, setValue } = form;
	const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(
		Intl.DateTimeFormat().resolvedOptions().timeZone
	);
	const [startDatePicker, setStartDatePicker] = useState<boolean>(false);
	const [endDatePicker, setEndtDatePicker] = useState<boolean>(false);
	const [startDateTime, setStartDateTime] = useState<string | Date | null>(
		getValues("start_time")
	);
	const [endDateTime, setEndDateTime] = useState<string | Date | null>(
		getValues("end_time") ? new Date(getValues("end_time")) : null
	);

	const handleStartTimeChange = (e: any) => {
		setValue("start_time", new Date(e));
		setStartDateTime(e);
	};
	const handleEndTimeChange = (e: any) => {
		setValue("end_time", new Date(e));
		setEndDateTime(e);
	};

	const handleStartDatePicker = () => {
		setStartDatePicker(true);
	};
	const handleEndDatePicker = () => {
		setEndtDatePicker(true);
	};

	return (
		<form>
			<MDBox>
				<MDBox width="82%" textAlign="center" mx="auto" mt={4} pt={5}>
					<MDBox mb={1}>
						<MDTypography variant="h4" fontWeight="regular">
							Time and duration
						</MDTypography>
						<MDTypography variant="h6" fontWeight="regular">
							Set time and duration for this tournament
						</MDTypography>
					</MDBox>
					<MDTypography
						variant="body2"
						color="text"
						fontStyle="italic"
					>
						You can always change this later
					</MDTypography>
				</MDBox>
				<MDBox mt={4}>
					<Grid container mx="auto">
						<Grid item lg={5} mx="auto" px={2}>
							<InputStyles>
								<LocalizationProvider
									dateAdapter={AdapterDateFns}
								>
									<div className="date-fiels-container">
										<MobileDateTimePicker
											{...register("start_time")}
											inputFormat="MM/dd/yyyy, hh:mm a"
											mask="__/__/___ __:__ _M"
											name="start_time"
											open={startDatePicker}
											onOpen={handleStartDatePicker}
											onClose={() =>
												setStartDatePicker(false)
											}
											// minDate={new Date()}
											renderInput={(props) => (
												<TextField
													{...props}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	edge="end"
																	onClick={
																		handleStartDatePicker
																	}
																>
																	<CalendarIcon />
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
											)}
											label="Starting date and time"
											value={startDateTime}
											onChange={handleStartTimeChange}
											onClick={handleStartDatePicker}
											components={{
												OpenPickerIcon: CalendarIcon,
											}}
										/>
									</div>
									<div className="date-fiels-container">
										<MobileDateTimePicker
											{...register("end_time")}
											name="end_time"
											open={endDatePicker}
											onOpen={handleEndDatePicker}
											onClose={() =>
												setEndtDatePicker(false)
											}
											inputFormat="MM/dd/yyyy, hh:mm a"
											mask="__/__/___ __:__ _M"
											minDateTime={startDateTime}
											renderInput={(props) => (
												<TextField
													{...props}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	edge="end"
																	onClick={
																		handleEndDatePicker
																	}
																>
																	<CalendarIcon />
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
											)}
											label="Ending date and time"
											value={endDateTime}
											onChange={handleEndTimeChange}
											components={{
												OpenPickerIcon: CalendarIcon,
											}}
										/>
									</div>
								</LocalizationProvider>
							</InputStyles>
						</Grid>
					</Grid>
				</MDBox>
				<MDBox textAlign="center" mx="auto" width={300}>
					<TimezoneSelectContainerStyle>
						<TimezoneSelect
							menuPlacement="top"
							className="timezone-select"
							value={
								getValues("timezone_label") || selectedTimezone
							}
							onChange={(value) => {
								setValue("timezone", value);
								setValue("timezone_offset", value.offset);
								setValue("timezone_label", value.value);
								setSelectedTimezone(value);
							}}
						/>
					</TimezoneSelectContainerStyle>
				</MDBox>
			</MDBox>
		</form>
	);
}
