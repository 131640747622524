import DashboardLayout from "template/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "template/components/MDBox";
import backgroundImage from "assets/images/backgrounds/pricing-header.png";
import MDTypography from "template/components/MDTypography";
import { Grid } from "@mui/material";
import BRANDS from "assets/images/brands/brands.png";
import { useMaterialUIController } from "context";
import PricingCardCurrentPlan from "containers/pricing/components/PricingCardCurrentPlan";
import Divider from "@mui/material/Divider";
import PricingCards from "containers/pricing/components/PricingCards";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import analytics from "utils/analytics";
import TrialDurationAlert from "components/alerts/TrialDurationAlert";

function PricingPage(): JSX.Element {
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	const { platforms } = useSelector((state: any) => state.platformsReducer);
	const platform =
		platforms && platforms.length > 0 && platforms[0] ? platforms[0] : null;

	useEffect(() => {
		analytics.clickPricingPlans({});
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<TrialDurationAlert />
			<MDBox
				display="flex"
				alignItems="center"
				minHeight="11.75rem"
				borderRadius="xl"
				mb={2}
				sx={{
					backgroundImage: ({
						functions: { rgba, linearGradient },
						palette: { gradients },
					}) =>
						`${linearGradient(
							rgba(gradients.secondary.main, 0.6),
							rgba(gradients.secondary.state, 0.6)
						)}, url(${backgroundImage})`,
					backgroundSize: "cover",
					backgroundPosition: "50%",
					overflow: "hidden",
				}}
			>
				<MDBox ml={3}>
					<MDTypography variant="h3" fontWeight="bold" color="light">
						Pricing and plans
					</MDTypography>
				</MDBox>
			</MDBox>
			<Grid container spacing={2} mt={2}>
				{platform?.is_trial && (
					<Grid item xs={12} sm={12} md={5} lg={5}>
						<PricingCardCurrentPlan
							color={darkMode ? "dark" : "white"}
							badge={{
								color: darkMode ? "dark" : "info",
								label: "free trial",
							}}
							price={{
								currency: "$",
								value: "30",
								type: "mo",
							}}
							specifications={[
								{
									label: "1 Platform (App or Web)",
									includes: true,
								},
								{
									label: "2 Active Tournaments",
									includes: true,
								},
								{
									label: "1 Team Member",
									includes: true,
								},
								{
									label: "Basic games",
									includes: true,
								},
							]}
							action={{
								type: "internal",
								route: "/pricing",
								color: darkMode ? "info" : "info",
								label: "upgrade",
							}}
							shadow={darkMode}
						/>
					</Grid>
				)}
				<Grid
					item
					xs={12}
					sm={12}
					md={7}
					lg={7}
					sx={{
						marginTop: 2,
						overflowX: "scroll",
						width: "100%",
						flexGrow: 1,
					}}
				>
					<MDBox
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-start",
							justifyContent: "space-evenly",
							height: "100%",
						}}
					>
						<MDTypography
							variant="h2"
							color="dark"
							fontWeight="bold"
							sx={{
								marginBottom: 1,
							}}
						>
							Upgrade today
						</MDTypography>
						<MDTypography
							variant="body1"
							color="text"
							fontWeight="regular"
							sx={{
								marginBottom: 3,
							}}
						>
							Thousands of companies are having more than 3X in
							ROI today
						</MDTypography>
						<MDTypography
							variant="h6"
							color="primary"
							fontWeight="600"
							sx={{
								marginBottom: 2,
							}}
						>
							Join 3,000+ forward-thinking companies:
						</MDTypography>
						<MDBox>
							<img src={BRANDS} alt="" />
						</MDBox>
					</MDBox>
				</Grid>
			</Grid>
			<Divider
				sx={{
					mt: 4,
					mb: 3,
				}}
			/>
			<PricingCards />
		</DashboardLayout>
	);
}

export default PricingPage;
