import * as yup from "yup";
import checkout from "./form";

const {
	formField: {
		// eslint-disable-next-line no-unused-vars
		templateName,
		// eslint-disable-next-line no-unused-vars
		selectGame,
		// eslint-disable-next-line no-unused-vars
		tournamentTitle,
		// eslint-disable-next-line no-unused-vars
		tournamentSubtitle,
		// eslint-disable-next-line no-unused-vars
		tournamentType,
		// eslint-disable-next-line no-unused-vars
		gameMode,
		// eslint-disable-next-line no-unused-vars
		tournamentStartDate,
		// eslint-disable-next-line no-unused-vars
		tournamentEndDate,
		// eslint-disable-next-line no-unused-vars
		gameRewardData,
		// eslint-disable-next-line no-unused-vars
		playerSessions,
	},
} = checkout;

const validations = [
	yup.object().shape({
		goal: yup.string().required("Goal is required"),
	}),
	yup.object().shape({
		game_alias: yup.string().required("Game is required"),
	}),
	yup.object().shape({
		title: yup
			.string()
			.nullable()
			.max(64, "Tournament title can not be more than 64 characters")
			.required("Tournament title is required"),
		subtitle: yup
			.string()
			.nullable()
			.max(20, "Tournament subtitle can not be more than 20 characters")
			.required("Tournament subtitle is required"),
	}),
	yup.object().shape({
		tournament_type: yup.string().required("Tournament type is required"),
		game_mode: yup.string().required("Game mode is required"),
	}),
	yup.object().shape({
		start_time: yup.date().required("Tournament start time is required"),
		end_time: yup
			.date()
			.min(yup.ref("start_time"), "End date can't be before start date")
			.required("Tournament end date required"),
		timezone_label: yup.string().required("Timezone is required"),
		timezone_offset: yup.string().required("Timezone is required"),
		/* timezone: yup
			.object()
			.shape({})
			.nullable()
			.required("Tournament timezone is required"), */
	}),
	// yup.object().shape({
	// 	show_reward_setup: yup.string().required("Please select one option"),
	// }),
	// yup.object().shape({
	// 	no_rewards_checked: yup.boolean().nullable(),
	// }),
	/* yup.object().shape({
		prize: yup.array().of(yup.object().shape({})),
	}), */
	yup.object().shape({
		max_player: yup.number().required("Max player is required"),
	}),
	yup.object().shape({}),
];

export default validations;
