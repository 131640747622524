/* eslint-disable arrow-body-style */
import React from "react";
// import BasicLayout from "template/layouts/authentication/components/BasicLayout";
// import bgImage from "assets/images/backgrounds/reset-password.png";
import PageLayout from "template/layouts/LayoutContainers/PageLayout";
import PageBanner from "components/Banner/PageBanner";
import bannerImg from "assets/images/banners/unsplash_CzShzocZtW0.png";
import MDBox from "template/components/MDBox";
import { CopyBlock, hybrid as dracula } from "react-code-blocks";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { Card, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BOOK_A_CALL_URL } from "constants/constants";
import { styled } from "@mui/system";
import MDTypography from "template/components/MDTypography";

const reactNativeSampleCode = `import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Dimensions } from 'react-native';
import { WebView } from 'react-native-webview';
export default function App() {
  return (
    <View style={styles.container}>
      <WebView
        originWhitelist={['*']}
        source={{ uri: 'https://go-games.gg/tournament?app=goama&country=sg' }}
        style={{
          width: Dimensions.get('window').width,
          height: Dimensions.get('window').height,
          overflow: 'hidden',
        }}
      />
      <StatusBar style="auto" />
    </View>
  );
}
 
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});`;

const DocumentationContainer = styled("div")(() => ({
	code: {
		fontSize: "14px !important",
	},
}));

function DocumentationPage() {
	const navigate = useNavigate();
	return (
		<DocumentationContainer>
			<DashboardNavbar />
			<MDBox mx={2}>
				<PageLayout>
					<PageBanner
						backgroundImage={bannerImg}
						title="Documentation"
					/>
					<MDBox>
						<h3>QuickStart</h3>
						<Divider
							variant="fullWidth"
							sx={{
								margin: "0.5rem 0",
								border: "1px solid",
							}}
							light={false}
						/>
						<Card>
							<MDBox p={2}>
								<MDBox>
									<MDTypography
										variant="body1"
										sx={{
											display: "flex",
											flexDirection: "row",
											flexWrap: "wrap",
											alignItems: "center",
											alignCOntent: "center",
										}}
									>
										For loading the Goama tournament
										platform into your mobile app/website,
										you first need your unique url. If you
										do not have the unique url yet, you can
										unlock it from Integration menu.{" "}
										<MDTypography
											sx={{
												mx: 1,
												cursor: "pointer",
											}}
											onClick={() =>
												navigate("/integration")
											}
											color="info"
											variant="body1"
										>
											<b>Click here</b>
										</MDTypography>{" "}
										to unlock your unique URL!
									</MDTypography>
								</MDBox>
								<MDBox>
									<h4>Steps of integration process:</h4>
									<MDTypography
										variant="subtitle2"
										sx={{ mb: 1 }}
									>
										<ol
											style={{
												paddingLeft: 25,
												fontWeight: 600,
											}}
										>
											<li>
												Collect the unique url for goama
												tournament platform.
											</li>
											<li>
												{" "}
												Open your mobile or web app’s
												code base in your preferred IDE.
												Find an appropriate position
												where you want to load the
												platform.
											</li>
											<li>
												Load the url in a webview/iframe
											</li>
											<li>
												You should see the Goama
												tournament platform loaded into
												the webview/iframe and it should
												display your configured
												tournament in your app/website.
											</li>
										</ol>
									</MDTypography>
									<MDTypography
										sx={{
											display: "flex",
											flexDirection: "row",
											flexWrap: "wrap",
											alignItems: "center",
											alignCOntent: "center",
										}}
										variant="body1"
									>
										Not sure how? See detailed instructions
										below. Or, you can even{" "}
										<MDTypography
											sx={{
												mx: 1,
												cursor: "pointer",
											}}
											onClick={() =>
												window.open(
													BOOK_A_CALL_URL,
													"_blank"
												)
											}
											color="info"
											variant="body1"
										>
											<b>BOOK A CALL</b>
										</MDTypography>{" "}
										with us and we can help you out.
									</MDTypography>
								</MDBox>
								<MDBox>
									<h4>
										Integrate Goama Tournament Platform in
										your Application
									</h4>
									<MDTypography
										variant="subtitle2"
										sx={{ mb: 1 }}
									>
										<ul style={{ paddingLeft: 25 }}>
											<li>React Native</li>
											<li>Native Android</li>
											<li>Website</li>
										</ul>
									</MDTypography>
								</MDBox>
							</MDBox>
						</Card>
						<br />
						<h3>React Native</h3>
						<Divider
							variant="fullWidth"
							sx={{
								margin: "0.5rem 0",
								border: "1px solid",
							}}
							light={false}
						/>
						<Card>
							<MDBox p={2}>
								<p>
									Integrating Goama Tournament Platform in
									React Native requires installing
									react-native-webview library to use the
									WebView. The library is supported in both
									expo and react-native-cli. See the full
									documentation here.
								</p>
								<CopyBlock
									codeBlock
									text="expo install react-native-webview"
									language="bash"
									showLineNumbers
									theme={dracula}
								/>
								<p>For react-native-cli,</p>
								<CopyBlock
									codeBlock
									text="yarn add react-native-webview"
									language="bash"
									showLineNumbers
									theme={dracula}
								/>
								<p>Or, If you use npm</p>
								<CopyBlock
									codeBlock
									text="npm install --save react-native-webview"
									language="bash"
									showLineNumbers
									theme={dracula}
								/>
								<p>Next, import WebView inside the component</p>
								<CopyBlock
									codeBlock
									text="import { WebView } from 'react-native-webview';"
									language="javascript"
									showLineNumbers
									theme={dracula}
								/>
								<p>
									To keep the view fullscreen import
									Dimensions as well.
								</p>
								<CopyBlock
									codeBlock
									text={`import { WebView } from 'react-native-webview';
import { Dimensions } from 'react-native';`}
									language="javascript"
									showLineNumbers
									theme={dracula}
								/>
								<p>Now, just add the url in WebView</p>

								<CopyBlock
									codeBlock
									text={`<WebView
	originWhitelist={['*']}
	source={{ uri: 'https://go-games.gg/tournament?app=goama&country=sg' }}
	style={{
		width: Dimensions.get('window').width,
		height: Dimensions.get('window').height,
		overflow: 'hidden',
	}}
/>`}
									language="jsx"
									showLineNumbers
									theme={dracula}
								/>
								<p>Sample Code:</p>
								<CopyBlock
									codeBlock
									language="jsx"
									theme={dracula}
									showLineNumbers
									text={reactNativeSampleCode}
								/>
								<p>
									Here, replace
									https://go-games.gg/tournament?app=goama&country=sg
									with the url that you have collected from
									the partner portal.
									<br />
									Now, run the project and you should see the
									Goama tournament platform loaded into your
									webview. You can watch a video tutorial on
									it here.
								</p>
							</MDBox>
						</Card>
						<br />
						<h3>Native Android (Java)</h3>
						<Divider
							variant="fullWidth"
							sx={{
								margin: "0.5rem 0",
								border: "1px solid",
							}}
							light={false}
						/>
						<Card>
							<MDBox p={2}>
								<p>
									integrating the Goama Tournament Platform is
									pretty straightforward. You just need to
									load the Goama tournament platform in a
									WebView. First add a fragment in your
									project and add a WebView in the Fragment.
								</p>
								<CopyBlock
									codeBlock
									language="xml"
									theme={dracula}
									showLineNumbers
									text={`<WebView
	android:id="@+id/webview_game"
	android:layout_width="409dp"
	android:layout_height="460dp"
	tools:layout_editor_absoluteX="1dp"
	tools:layout_editor_absoluteY="1dp" />`}
								/>
								<p>
									Now, go to the code base of the Fragment,
									add the following code inside the{" "}
									<b>onViewCreated</b> method.
								</p>
								<CopyBlock
									codeBlock
									language="java"
									theme={dracula}
									showLineNumbers
									text={`public void onViewCreated(@NonNull View view, Bundle savedInstanceState) {
	super.onViewCreated(view, savedInstanceState);
	WebView myview = (WebView) view.findViewById(R.id.webview_game);
	WebSettings webSettings = myview.getSettings();
	webSettings.setJavaScriptEnabled(true);
	webSettings.setDomStorageEnabled(true);
	myview.loadUrl("https://go-games.gg/tournament?app=goama&country=sg");
}`}
								/>
								<p>
									To load the Goama Tournament Platform, we
									need to set the JavaScriptEnabled and
									DomStorageEnabled true. In the last line of
									the above code, please load your unique url
									instead of the sample one. <br />
									Finally, we need to set the uses-permission
									in the AndroidManifest file so that your app
									can access the Internet.
								</p>
								<CopyBlock
									codeBlock
									language="xml"
									theme={dracula}
									showLineNumbers
									text={`<uses-permission android:name="android.permission.INTERNET"/>`}
								/>
								<p>
									Now, run the project and you should see the
									Goama tournament platform loaded into your
									webview. You can watch video tutorial on it
									here
								</p>
							</MDBox>
						</Card>
						<br />
						<h3>
							Integrate Goama Tournament Platform in your Web
							Application
						</h3>
						<Divider
							variant="fullWidth"
							sx={{
								margin: "0.5rem 0",
								border: "1px solid",
							}}
							light={false}
						/>
						<Card>
							<MDBox p={2}>
								<p>
									Integrating the Goama tournament platform in
									any web application is even simpler than
									mobile integration. You will simply load
									your url in an iframe of an html page.
								</p>

								<CopyBlock
									codeBlock
									language="html"
									theme={dracula}
									showLineNumbers
									text={`<iframe
	id="goama"
	width="100%"
	height="100%"
	frameborder="0"
	src="https://go-games.gg/tournaments?app='goama'&country='sg'">
</iframe>`}
								/>
							</MDBox>
						</Card>
					</MDBox>
				</PageLayout>
			</MDBox>
		</DocumentationContainer>
	);
}

export default DocumentationPage;
