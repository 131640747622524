/* eslint-disable camelcase */
import * as yup from "yup";
// import checkout from "./form";

/* const {
	formField: { companyName, companySize, industryName, role, platformName },
} = checkout; */

const validations = [
	yup.object().shape({
		name: yup.string().required("Company name is required"),
		industry: yup.string().required("Industry is required"),
		role: yup.string().required("Department is required"),
		size: yup.string().required("Company Size is required"),
		profile: yup.mixed().nullable(true),
		// .test("file", "Photo is required", (file) => !!file),
	}),
	yup.object().shape({
		platform_type: yup.string().required("Please select one option"),
		mobile_app_url: yup.string().when("platform_type", {
			is: (platform_type: any) =>
				platform_type === "Both, Mobile App and Website" ||
				platform_type === "Integrate Games in Mobile App",
			then: yup.string().required("Please enter mobile app url"),
		}),
		website_url: yup.string().when("platform_type", {
			is: (platform_type: any) =>
				platform_type === "Both, Mobile App and Website" ||
				platform_type === "Integrate Games in Website",
			then: yup.string().required("Please enter website url"),
		}),
		both_apple_google: yup.boolean(),
		reason: yup.string().when("platform_type", {
			is: (platform_type: any) =>
				platform_type === "Don't need integration in App or Website",
			then: yup.string().required("Please specify your reason"),
		}),
		phone_number: yup.string(),
	}),
	yup.object().shape({
		usages: yup.array().notRequired(),
	}),
];

export default validations;
