import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import PersonIcon from "@mui/icons-material/Person";
import ArticleIcon from "@mui/icons-material/Article";
// import LockIcon from "@mui/icons-material/Lock";
// import DeleteIcon from "@mui/icons-material/Delete";

// import theme template

import ProfileStatus from "containers/UserProfilePage/components/ProfileStatus";

// inner elements
import BasicInfoForm from "containers/UserProfilePage/form/BasicInfoForm";
import DeactivateAccount from "./form/DeactivateAccount";
import PasswordChangeForm from "./form/PasswordChangeForm";

interface TabPanelProps {
	children: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

const TabsNavStyle = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.white.main,
	boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
	borderRadius: "8px",
	padding: "10px 15px",
	"& .MuiTabs-root": {
		backgroundColor: "transparent",
		border: "0",
	},
	"& .disabled-tab": {
		borderBottom: `1px solid ${theme.palette.dark.ultraLight}`,
		marginBottom: "18px",
	},
	"& button": {
		width: "100%",
		borderRadius: "0",
		minHeight: "40px",
		padding: "12px",
		"&.Mui-selected": {
			backgroundColor: theme.palette.primary.bold,
			color: `${theme.palette.white.main} !important`,
			opacity: "0.75 !important",
		},
	},
}));

export default function profilePageContent() {
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Grid container spacing={4}>
			<Grid item lg={3.5}>
				<TabsNavStyle>
					<Tab
						label="Profile"
						icon={<PersonIcon />}
						sx={{ justifyContent: "left" }}
						disabled
						className="disabled-tab"
					/>
					<Tabs
						orientation="vertical"
						variant="scrollable"
						value={value}
						onChange={handleChange}
						aria-label="Vertical tabs example"
						sx={{ borderRight: 1, borderColor: "divider" }}
					>
						<Tab
							label="Basic Info"
							icon={<ArticleIcon />}
							sx={{ justifyContent: "left" }}
							{...a11yProps(0)}
						/>
						{/* <Tab
							label="Change Password"
							icon={<LockIcon />}
							sx={{ justifyContent: "left" }}
							{...a11yProps(1)}
						/> */}
						{/* <Tab
							label="Deactivate Account"
							icon={<DeleteIcon />}
							sx={{ justifyContent: "left" }}
							{...a11yProps(2)}
						/> */}
					</Tabs>
				</TabsNavStyle>
			</Grid>
			<Grid item lg={8.5}>
				<div className="panel-header" style={{ marginBottom: 25 }}>
					<ProfileStatus />
				</div>
				<TabPanel value={value} index={0}>
					<BasicInfoForm />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<PasswordChangeForm />
				</TabPanel>
				<TabPanel value={value} index={2}>
					<DeactivateAccount />
				</TabPanel>
			</Grid>
		</Grid>
	);
}
