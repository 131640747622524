import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";

export function FormFieldErrorMessage({ message }: any) {
	return (
		<MDBox display="flex" alignItems="center" ml={1}>
			<MDTypography variant="button" fontWeight="regular" color="error">
				{message}
			</MDTypography>
		</MDBox>
	);
}

export default FormFieldErrorMessage;
