/* eslint-disable import/prefer-default-export */
/**
 * get json object from url query string
 * @param {string} search
 * @param search - search string
 * @returns
 */
export const getJSONfromQueryParams = (search: string) => {
	const pairs = search.slice(1).split("&");
	const result: any = {};
	pairs.forEach((pair: any) => {
		const p = pair.split("=");
		result[p[0] as keyof any] = decodeURIComponent(p[1] || "");
	});
	const params = JSON.parse(JSON.stringify(result));
	return params;
};
/* 
const PLURALS = {
	person: "people",
	radius: "radii",
};
const autoPluralize = pluralize(PLURALS);
console.log(autoPluralize(2, "person")); // 'people'
 */
export const pluralize = (val: number, word: string, plural = `${word}s`) => {
	if (val === 0) return `${word}`;
	const pluralize = (num: number, word: string, plural = `${word}s`) =>
		[1, -1].includes(Number(num)) ? word : plural;
	if (typeof val === "object")
		return (num: number, word: string) => pluralize(num, word, val[word]);
	return pluralize(val, word, plural);
};

export const truncateString = (str: string, num: number) => {
	const newStr = str;
	if (newStr.length > num) {
		return `${newStr.slice(0, num)}...`;
	}
	return newStr;
};
