// eslint-disable-next-line no-unused-vars
const getApiUrl = (prefix: string) =>
	// const baseUrl = "goama.com";
	process.env.REACT_APP_API_URL ?? "";
// "https://pp-api.goama.com";
// return process.env.NEXT_PUBLIC_BASE_URL;
// const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
/* if (process.env.NODE_ENV === "development") {
    return `https://${prefix}${"-dev"}.${baseUrl}`;
  } else {
    return `https://${prefix + "."}${baseUrl}`;
  } */
/* else if (window.location.host.includes(baseUrl)) {
  return `https://${prefix + "."}${window.location.host}`;
}  */

export const getBaseUrl = () => getApiUrl("pp-api");

export default getApiUrl;
