/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Props {
	loading: boolean;
	isDesktop: boolean;
}

const initialState: Props = {
	loading: false,
	isDesktop: true,
};

const slice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setIsDesktop(state: Props, payload: PayloadAction<any>): void {
			state.isDesktop = payload.payload;
		},
	},
});

export const { actions, reducer } = slice;

export const { setIsDesktop } = actions;

export default reducer;
