import { Grid, Icon } from "@mui/material";
import StepCard from "components/cards/StepCard";
import AlertMessage from "components/modals/AlertMessage";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openTournamentBuilderModal } from "slices/modalSlice";
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import { countActiveTournaments, hasTournaments } from "utils/tournament";
import analytics, { EVENT_TYPES } from "utils/analytics";

function StepsGuide() {
	const { user } = useSelector((state: any) => state.auth);
	const { platforms } = useSelector((state: any) => state.platformsReducer);
	const platform =
		platforms && platforms.length > 0 && platforms[0] ? platforms[0] : null;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showStepOneKnowMore, setShowStepOneKnowMore] = useState(false);
	const [showStepTwoKnowMore, setShowStepTwoKnowMore] = useState(false);
	const [showStepThreeKnowMore, setShowStepThreeKnowMore] = useState(false);
	const [showStepFourKnowMore, setShowStepFourKnowMore] = useState(false);
	const [showReadMoreAlert, setShowReadMoreAlert] = useState(false);
	return (
		<>
			<AlertMessage
				// title="Please create a tournament first"
				message="Coming Soon"
				openDialog={showStepOneKnowMore}
				handleConfirm={() => {
					setShowStepOneKnowMore(false);
					// setOpenDialog(false);
					// dispatch(openTournamentBuilderModal());
				}}
				// handleCancel={() => setOpenDialog(false)}
				// cancelButtonText="OKAY"
				successButtonText="I understand"
			/>
			<AlertMessage
				// title="Please create a tournament first"
				message="Claim your unique URL required for Integration and Start your 30 Days Free Trial. Integration is a one time process and once done you can just Add Tournament and Publish them in few clicks"
				openDialog={showStepTwoKnowMore}
				handleConfirm={() => {
					setShowStepTwoKnowMore(false);
					// setOpenDialog(false);
					// dispatch(openTournamentBuilderModal());
				}}
				// handleCancel={() => setOpenDialog(false)}
				// cancelButtonText="OKAY"
				successButtonText="I understand"
			/>
			<AlertMessage
				// title="Please create a tournament first"
				message="Choose how you wish to Integrate Gamification in your Mobile App/Website. This requires a few lines of code to be added. You can choose to Do it Yourself or Book a call with us and our expert Developers can do it on a live call. After Integration is done, do come back to our Platform and start Publishing Tournaments"
				openDialog={showStepThreeKnowMore}
				handleConfirm={() => {
					setShowStepThreeKnowMore(false);
					// setOpenDialog(false);
					// dispatch(openTournamentBuilderModal());
				}}
				// handleCancel={() => setOpenDialog(false)}
				// cancelButtonText="OKAY"
				successButtonText="I understand"
			/>
			<AlertMessage
				// title="Please create a tournament first"
				message="Once you have Integrated with us, come back and Publish the Tournaments on your Mobile App/Website"
				openDialog={showStepFourKnowMore}
				handleConfirm={() => {
					setShowStepFourKnowMore(false);
					// setOpenDialog(false);
					// dispatch(openTournamentBuilderModal());
				}}
				// handleCancel={() => setOpenDialog(false)}
				// cancelButtonText="OKAY"
				successButtonText="I understand"
			/>
			<AlertMessage
				// title="Please create a tournament first"
				message="Once done, then just ADD and PUBLISH Tournaments seamlessly through this platform"
				openDialog={showReadMoreAlert}
				handleConfirm={() => {
					setShowReadMoreAlert(false);
					// setOpenDialog(false);
					// dispatch(openTournamentBuilderModal());
				}}
				// handleCancel={() => setOpenDialog(false)}
				// cancelButtonText="OKAY"
				successButtonText="Close"
				successButtonColor="success"
			/>
			<MDBox pt={3} pb={5}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6} lg={3}>
						<MDBox
							mb={1.5}
							sx={{
								height: "100%",
								position: "relative",
							}}
						>
							<StepCard
								color="success"
								icon="sports_esports"
								title="Step 1"
								subtitle="ADD TOURNAMENT"
								description="Select the perfect game to run as 
									tournament for engaging your audience"
								count={countActiveTournaments()}
								actionBottomLeft={{
									handleClick: () => {
										analytics.triggerEvent(
											EVENT_TYPES.KNOW_MORE_STEP_GUIDE,
											{
												step: 1,
											}
										);
										setShowStepOneKnowMore(true);
									},
								}}
								actionBottomRight={{
									color: hasTournaments()
										? "success"
										: "warning",
									label: "ADD",
									// disabled: hasTournaments,
									handleClick: () => {
										analytics.triggerEvent(
											EVENT_TYPES.ADD_TOURNAMENT_STEP_GUIDE,
											{
												step: 1,
											}
										);
										dispatch(openTournamentBuilderModal());
									},
								}}
							/>
							<MDBox
								sx={{
									position: "absolute",
									right: -22,
									top: "45%",
								}}
							>
								<Icon fontSize="small" color="inherit">
									keyboard_double_arrow_right
								</Icon>
							</MDBox>
						</MDBox>
					</Grid>
					<Grid item xs={12} md={6} lg={3}>
						<MDBox
							mb={1.5}
							sx={{
								height: "100%",
								position: "relative",
							}}
						>
							<StepCard
								color="secondary"
								icon="devices"
								title="Step 2"
								subtitle="INTEGRATION"
								description="Claim your Unique URL, using which you can add
									Gamification capabilities in you App/Website"
								count={countActiveTournaments()}
								actionBottomLeft={{
									handleClick: () => {
										analytics.triggerEvent(
											EVENT_TYPES.KNOW_MORE_STEP_GUIDE,
											{
												step: 2,
											}
										);
										setShowStepTwoKnowMore(true);
									},
								}}
								actionBottomRight={{
									disabled: !platform && !hasTournaments(),
									color:
										platform &&
										hasTournaments() &&
										user?.unlock_platform
											? "success"
											: "warning",
									label: "get",
									handleClick: () => {
										analytics.triggerEvent(
											EVENT_TYPES.GET_INTEGRATION_DETAILS_GUIDE,
											{
												step: 2,
											}
										);
										navigate("/integration");
									},
								}}
							/>
							<MDBox
								sx={{
									position: "absolute",
									right: -22,
									top: "45%",
								}}
							>
								<Icon fontSize="small" color="inherit">
									keyboard_double_arrow_right
								</Icon>
							</MDBox>
						</MDBox>
					</Grid>
					<Grid item xs={12} md={6} lg={3}>
						<MDBox
							mb={1.5}
							sx={{
								height: "100%",
								position: "relative",
							}}
						>
							<StepCard
								color="dark"
								icon="pending_actions"
								title="Step 3"
								subtitle="COMPLETE THE INTEGRATION"
								actionBottomLeft={{
									handleClick: () => {
										analytics.triggerEvent(
											EVENT_TYPES.KNOW_MORE_STEP_GUIDE,
											{
												step: 3,
											}
										);
										setShowStepThreeKnowMore(true);
									},
								}}
								description={
									<MDBox
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<MDTypography variant="text">
											Integration is a one time process
											and takes hardly 30 minutes.{" "}
											<MDTypography
												onClick={() =>
													setShowReadMoreAlert(true)
												}
												sx={{
													cursor: "pointer",
												}}
												variant="text"
												color="primary"
											>
												<b>Read more...</b>
											</MDTypography>
										</MDTypography>
										<MDTypography variant="text">
											Choose to Do It Yourself or{" "}
											<a
												href="https://meetings.hubspot.com/aakanksha-singh"
												target="_blank"
												rel="noreferrer"
											>
												<MDTypography
													color="info"
													variant="text"
													onClick={() => {
														analytics.triggerEvent(
															EVENT_TYPES.BOOK_CALL_STEP_GUIDE,
															{
																step: 3,
															}
														);
													}}
												>
													<b>
														<i>BOOK A CALL</i>
													</b>
												</MDTypography>
											</a>{" "}
											with us and we can do it for you
											FREE OF COST
										</MDTypography>
									</MDBox>
								}
								count={countActiveTournaments()}
							/>
							<MDBox
								sx={{
									position: "absolute",
									right: -22,
									top: "45%",
								}}
							>
								<Icon fontSize="small" color="inherit">
									keyboard_double_arrow_right
								</Icon>
							</MDBox>
						</MDBox>
					</Grid>
					<Grid item xs={12} md={6} lg={3}>
						<MDBox
							mb={1.5}
							sx={{
								height: "100%",
							}}
						>
							<StepCard
								color="info"
								icon="group"
								title="Step 4"
								subtitle="PUBLISH TOURNAMENT"
								description="Once your Integration is completed, 
									come back to our page and start publishing 
									Games/Tournaments on your App/ Website"
								count={countActiveTournaments()}
								actionBottomLeft={{
									handleClick: () => {
										setShowStepFourKnowMore(true);
									},
								}}
								actionBottomRight={{
									disabled: !platform,
									color: "warning",
									label: "publish",
									handleClick: () => {
										analytics.triggerEvent(
											EVENT_TYPES.PUBLISH_TOURNAMENT_STEP_GUIDE,
											{
												step: 4,
											}
										);
										navigate("/tournaments");
									},
								}}
							/>
						</MDBox>
					</Grid>
				</Grid>
			</MDBox>
		</>
	);
}

export default StepsGuide;
