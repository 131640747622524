import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";
import DeviceEmulator from "react-device-emulator";
import "react-device-emulator/lib/styles/style.css";
import { useDispatch, useSelector } from "react-redux";

// import element
import CloseButton from "components/elements/CloseButton";
import MDBox from "template/components/MDBox";
import { closePreviewAppModal } from "slices/modalSlice";

const ModalStyle = styled("div")(({ theme }) => ({
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -48%)",
	boxShadow: "24",
	display: "flex",
	justifyContent: "space-evenly",
	flexDirection: "row",
	// flexWrap: "wrap",
	alignItems: "center",
	/* [theme.breakpoints.up("lg")]: {
		minWidth: "975px",
	}, */
	minWidth: "100vw",
	maxHeight: "100vh",
	border: "0",
	outline: "0",
	".tab-chrome": {
		height: "100vh",
	},
	".tab-frame": {
		height: "90vh",
	},
	[theme.breakpoints.down("xl")]: {
		minWidth: "85vw",
	},
	[theme.breakpoints.down("lg")]: {
		minWidth: "85vw",
	},
	[theme.breakpoints.down("md")]: {
		minWidth: "95vw",
		paddingTop: "10%",
		paddingBottom: "15%",
		overflow: "scroll",
		height: "100%",
	},
	"@media(max-width: 1023px)": {
		flexDirection: "column",
		width: "100vw",
		overflowY: "scroll",
		transform: "translate(-50%, -2%)",
		top: "20px",
		paddingTop: "800px",
		paddingBottom: "800px",
		"& .device-emulator-container": {
			paddingTop: "20px",
			paddingBottom: "20px",
		},
	},
}));

export default function PreviewModal({ link }: any) {
	const dispatch = useDispatch();
	const { showPreviewAppModal } = useSelector((state: any) => state.modal);

	const handleClose = () => {
		dispatch(closePreviewAppModal());
	};

	return (
		<div className="modal-wrapper">
			<Modal
				onBackdropClick={() => {}}
				BackdropProps={{
					style: { backgroundColor: "#191919", opacity: 0.85 },
				}}
				// onClose={handleClose}
				open={showPreviewAppModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<>
					<ModalStyle>
						{link && (
							<MDBox>
								<DeviceEmulator
									withoutChrome
									type="tab"
									withDeviceSwitch={false}
									withRotator={false}
									url={link || ""}
								/>
							</MDBox>
						)}
					</ModalStyle>
					<CloseButton
						handleClose={handleClose}
						buttonText="Close Preview"
					/>
				</>
			</Modal>
		</div>
	);
}
