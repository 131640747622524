import Card from "@mui/material/Card";
import { styled } from "@mui/system";

const CardStyle = styled("div")(({ theme }) => ({
	"& .card-wrapper": {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		flexDirection: "row",
		padding: "16px",
		boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
		minHeight: "115px",
	},
	"& .card-icon": {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		textALign: "center",
		background: `linear-gradient(180deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.state} 100%)`,
		flexBasis: "64px",
		height: "64px",
		borderRadius: "12px",
		"& div": {
			display: "inline-flex",
		},
	},
}));
export default function SmallCard(props: any) {
	const { icon, children } = props;

	return (
		<CardStyle>
			<Card className="card-wrapper">
				<div className="card-icon">
					<div>
						<img src={icon} alt={icon} />
					</div>
				</div>
				<div className="card-content">{children}</div>
			</Card>
		</CardStyle>
	);
}
