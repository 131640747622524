/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import MDButton from "template/components/MDButton";
import MDBadge from "template/components/MDBadge";
import { useSelector } from "react-redux";

// Declaring props types for the Breadcrumbs
interface Props {
	icon: ReactNode;
	title: string;
	route: string | string[];
	light?: boolean;
	[key: string]: any;
}

function Breadcrumbs({ icon, title, route, light }: Props): JSX.Element {
	const routes: string[] | any = route.slice(0, -1);
	const { workspaces } = useSelector((state: any) => state.workspaces);
	const workspace =
		workspaces && workspaces.length > 0 && workspaces[0]
			? workspaces[0]
			: null;
	return (
		<MDBox mr={{ xs: 0, xl: 8 }} style={{ display: "flex" }}>
			<MuiBreadcrumbs
				sx={{
					"& .MuiBreadcrumbs-separator": {
						color: ({ palette: { white, grey } }) =>
							light ? white.main : grey[600],
					},
				}}
			>
				<Link to="/">
					<MDTypography
						component="span"
						variant="body2"
						color={light ? "white" : "dark"}
						opacity={light ? 0.8 : 0.5}
						sx={{ lineHeight: 0 }}
					>
						<Icon>{icon}</Icon>
					</MDTypography>
				</Link>
				{workspace && (
					<Link to="/">
						<MDTypography
							component="span"
							variant="button"
							fontWeight="regular"
							textTransform="capitalize"
							color={light ? "white" : "dark"}
							opacity={light ? 0.8 : 0.5}
							sx={{ lineHeight: 0 }}
						>
							{workspace?.name}
						</MDTypography>
					</Link>
				)}
				{routes.map((el: string) => (
					<Link to={`/${el}`} key={el}>
						<MDTypography
							component="span"
							variant="button"
							fontWeight="regular"
							textTransform="capitalize"
							color={light ? "white" : "dark"}
							opacity={light ? 0.8 : 0.5}
							sx={{ lineHeight: 0 }}
						>
							{el}
						</MDTypography>
					</Link>
				))}
				<MDTypography
					variant="button"
					fontWeight="regular"
					textTransform="capitalize"
					color={light ? "white" : "dark"}
					sx={{ lineHeight: 0 }}
				>
					{title.replace("-", " ")}
				</MDTypography>
			</MuiBreadcrumbs>
			<MDBadge 
				badgeContent="Beta" 
				color="warning" 
				size="xs"
				container 
				style={{ marginLeft: "10px"}}
			/>
			{/* <MDTypography
				fontWeight="bold"
				textTransform="capitalize"
				variant="h6"
				color={light ? "white" : "dark"}
				noWrap
			>
				{title.replace("-", " ")}
			</MDTypography> */}
		</MDBox>
	);
}

// Declaring default props for Breadcrumbs
Breadcrumbs.defaultProps = {
	light: false,
};

export default Breadcrumbs;
