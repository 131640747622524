// import toast from "react-hot-toast";
// import useToastMessage from "src/hooks/use-toast-message";
// import { toast } from 'react-hot-toast';
// import { store } from '../redux/store';
// import authActionTypes from '../redux/reducers/Authentication/authActionTypes';
// import history from './history';
// import historyRoutes from '../routing/historyRoutes';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "store";
import { logout } from "actions/auth";
import { sleep } from "./sleep";

export const errorType = {
	single: 1,
	authentication: 2,
	form: 3,
	unknown: 4,
};

const handleGlobally = (error: any) => {
	// console.log(error, 19);

	switch (error.type) {
		case errorType.authentication:
			toast(error.message);
			// Router.replace("/authentication/login");
			if (error.message !== "Access Denied") {
				sleep(500).then(() => {
					try {
						const token: any = null;
						// store.dispatch({ type: authActionTypes.LOGOUT });
						/* const {
              authReducer: { token },
            } = store.getState(); */
						if (token) {
							//   store.dispatch({ type: 'RESET_APP' });
						}
						// history.push(historyRoutes.login);
					} catch (e) {
						// handle error
					}
				});
			}
			break;
		case errorType.unknown:
			toast(error.message);
			break;
		case errorType.single:
			toast(error.message);
			break;
		default:
			// console.log("default", { error });
			if (error.data.detail) {
				toast.error(error.data.detail);
			} else {
				toast.warn("'Server was unable to process the request!'");
			}
			break;
	}
};

export const handleAjaxError = (error: any) => {
	try {
		const { status } = error;
		if (status === 401) {
			const res = {
				...error,
				code: 401,
				type: errorType.authentication,
				// handleGlobally: () => handleGlobally(error),
			};
			store.dispatch(logout());
			// handleGlobally(res);
			return res;
		}
	} catch (err) {
		// handle error
		return err;
	}
	try {
		const res = {
			...error,
			// code: 401,
			// type: errorType.authentication,
			handleGlobally: () => handleGlobally(error),
		};
		return res;
	} catch (e) {
		return error;
	}
	/* try {
		const {
			status,
			statusText,
			data: { detail, messages },
		} = error;
		if (status === 401) {
			const res = {
				...error,
				code: 401,
				type: errorType.authentication,
				handleGlobally: () => handleGlobally(error),
			};
			handleGlobally(res);
			return res;
		}
	} catch (err) {
		// handle error
	} */
	/* try {
		if (error && error.data && error.data.detail) {
			return {
				type: errorType.single,
				statusText: error.statusText,
				message: error.data.detail,
				handleGlobally: () => handleGlobally(error),
			};
		}
		return {
			type: errorType.unknown,
			statusText: "Something went wrong!",
			message: "Server was unable to process the request.",
			handleGlobally: () => handleGlobally(error),
		};
	} catch (e) {
		if (error && error.data && error.data.detail) {
			return {
				type: errorType.single,
				statusText: error.statusText,
				message: error.data.detail,
				handleGlobally: () => handleGlobally(error),
			};
		}
		return {
			type: errorType.unknown,
			statusText: "Something went wrong!",
			message: "Server was unable to process the request.",
			handleGlobally: () => handleGlobally(error),
		};
	} */
};
