// import { useState } from "react";
// import { styled } from "@mui/system";
import Modal from "@mui/material/Modal";
import WorkspaceSteps from "containers/workspace/form/WorkspaceSteps";
// import CloseButton from "components/elements/CloseButton";
import { useDispatch, useSelector } from "react-redux";
import { closeWorkspaceModal, openGetStartedModal } from "slices/modalSlice";
import analytics from "utils/analytics";
import ModalWrapper from "./ModalWrapper";

// type modalType = {
// 	openModal: boolean;
// };

export default function WorkspaceModal() {
	const dispatch = useDispatch();
	const { showWorkspaceModal } = useSelector((state: any) => state.modal);
	const handleClose = () => {
		analytics.gotoDashboard({});
		dispatch(closeWorkspaceModal());
		dispatch(
			openGetStartedModal(
				"https://www.youtube.com/embed/3ztRM_LO8bI?autoplay=1"
			)
		);
	};

	if (showWorkspaceModal) {
		analytics.enterWorkspace({});
	}

	return (
		<div className="modal-wrapper">
			<Modal
				BackdropProps={{
					style: { backgroundColor: "#191919", opacity: 0.85 },
				}}
				// onClose={handleClose}
				open={showWorkspaceModal}
				aria-labelledby="workspace-modal"
				aria-describedby="create-workspace"
			>
				<>
					<ModalWrapper>
						<WorkspaceSteps handleClose={handleClose} />
					</ModalWrapper>
					{/* <CloseButton
						handleClose={handleClose}
						buttonText="Close workspace"
					/> */}
				</>
			</Modal>
		</div>
	);
}
