import { useState } from "react";
import Grid from "@mui/material/Grid";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
// import FormField from "components/elements/FormField";
// import TextField from "@mui/material/TextField";
import CustomTooltip from "components/elements/CustomTooltip";
import { styled } from "@mui/system";

// images
import lightImg from "assets/images/icons/light.png";
import { Tooltip } from "@mui/material";
import { FormProviderProps } from "react-hook-form";

const InputStyles = styled("div")(({ theme }) => ({
	display: "flex",
	[theme.breakpoints.down("sm")]: {
		flexWrap: "wrap",
	},
	alignItems: "center",
	justifyContent: "center",
	"& .form-group": {
		position: "relative",
		[theme.breakpoints.down("sm")]: {
			minWidth: "350px",
		},
		display: "flex",
		"& input[type='text']": {
			width: "100%",
		},
		"& .MuiBox-root": {
			width: "100%",
		},
		"& .tooltip-wrapper": {
			"& button": {
				width: "30px",
				height: "30px",
				marginTop: "15px",
				"& img": {
					maxWidth: "30px",
				},
			},
		},
	},
}));

export const tournamentTypeLists: string[] = [
	"",
	"Free tournament",
	"Paid Tournaments (Normal)",
	"Paid Tournaments (Dynamic)",
	"Paid Tournaments (Auto)",
	"Sponsored Tournaments",
	"Try Based Tournaments",
];

export default function BasicInfoTwo({ form }: { form: FormProviderProps }) {
	const {
		// register,
		setValue,
		getValues,
	} = form;
	const [tournamentType, setTournamentType] = useState<number>(
		getValues("tournament_type")
	);
	const [gameMode, setGameMode] = useState<number>(getValues("game_mode"));

	const handleTournamentTypeChange = (event: SelectChangeEvent) => {
		setValue("tournament_type", parseInt(event.target.value, 10));
		setTournamentType(parseInt(event.target.value, 10));
	};
	const handleGGameModeChange = (event: SelectChangeEvent) => {
		setValue("game_mode", parseInt(event.target.value, 10));
		setGameMode(parseInt(event.target.value, 10));
	};
	return (
		<form>
			<MDBox>
				<MDBox width="82%" textAlign="center" mx="auto" mt={5} pt={5}>
					<MDBox mb={1}>
						<MDTypography variant="h4" fontWeight="regular">
							Tournament type
						</MDTypography>
					</MDBox>
					<MDTypography variant="body2" color="text">
						This settings modify the way the players interact
					</MDTypography>
				</MDBox>
				<MDBox mt={5} pt={2}>
					<Grid container mx="auto">
						<Grid item lg={5} mx="auto">
							<InputStyles>
								<div className="form-group">
									<div className="tooltip-wrapper">
										<CustomTooltip
											title="On Free Tournament Mode your users can play games without using tickets or money"
											placement="left-end"
											iconImg={lightImg}
										/>
									</div>
									<FormControl sx={{ m: 1, minWidth: 228 }}>
										<InputLabel id="tournament-type-label">
											Tournament Type
										</InputLabel>
										<Select
											// {...register("tournament_type")}
											name="tournament_type"
											labelId="tournament-type-label"
											id="tournament-type"
											value={`${tournamentType}`}
											label="Tournament Type"
											onChange={
												handleTournamentTypeChange
											}
											IconComponent={KeyboardArrowDown}
											sx={{ minHeight: 45 }}
										>
											<MenuItem disabled value="">
												<em>None</em>
											</MenuItem>
											{tournamentTypeLists.map(
												(item, index: number) => {
													if (index > 1) {
														return (
															<Tooltip
																arrow={false}
																title="This option is only available in the Escaleup and Add-ons."
																placement="left-start"
																key={item}
															>
																<span>
																	<MenuItem
																		value={
																			index
																		}
																		disabled={
																			index >
																			0
																		}
																	>
																		<span>
																			{
																				item
																			}
																		</span>
																	</MenuItem>
																</span>
															</Tooltip>
														);
													}
													return (
														<MenuItem
															key={item}
															value={index}
														>
															<span>{item}</span>
														</MenuItem>
													);
												}
											)}
										</Select>
									</FormControl>
								</div>
								<FormControl sx={{ m: 1, minWidth: 228 }}>
									<InputLabel id="game-mode-label">
										Game mode
									</InputLabel>
									<Select
										// {...register("game_mode")}
										name="game_mode"
										labelId="game-mode-label"
										id="game-mode"
										value={`${gameMode}`}
										label="Tournament Type"
										onChange={handleGGameModeChange}
										IconComponent={KeyboardArrowDown}
										sx={{ minHeight: 45 }}
									>
										<MenuItem disabled value="">
											<em>None</em>
										</MenuItem>
										<MenuItem value="1">
											High score based
										</MenuItem>
										<MenuItem value="2">
											Player vs Player
										</MenuItem>
									</Select>
								</FormControl>
								{!Number.isNaN(gameMode) && (
									<div className="tooltip-wrapper">
										<CustomTooltip
											title={
												// eslint-disable-next-line eqeqeq
												gameMode == 1
													? "Players will compete for the high score in the leaderboard"
													: "Players will compete with others in real time, if the system can't match them with another player it will automatically select a bot with 95% probability to lose against the player"
											}
											placement="right-end"
											iconImg={lightImg}
										/>
									</div>
								)}
							</InputStyles>
						</Grid>
					</Grid>
				</MDBox>
			</MDBox>
		</form>
	);
}
