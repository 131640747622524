/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axiosClient from "utils/axios";
import { AppThunk, store } from "../store";
// import { handleAjaxError } from "../utils/ajax-error-handler";

interface PaginationDetailsState {
	offset: number;
	limit: number;
	count: number;
	page: number;
	next: string | null;
	previous: string | null;
	results: any[];
	url: string | null;
}

const defaultPaginationDetails: PaginationDetailsState = {
	offset: 0,
	limit: 10,
	count: 0,
	next: null,
	page: 1,
	previous: null,
	results: [],
	url: null,
};

interface PaginationState {
	applications: PaginationDetailsState;
	tournaments: PaginationDetailsState;
}

const initialState: PaginationState = {
	applications: {
		...defaultPaginationDetails,
	},
	tournaments: {
		...defaultPaginationDetails,
	},
};

const slice = createSlice({
	name: "paginationDetails",
	initialState,
	reducers: {
		setPagination(
			state: PaginationState,
			action: PayloadAction<any>
		): void {
			const { name, details } = action.payload;
			state[name as keyof PaginationState] = { ...details };
			//   state.token = { access: access, refresh: refresh };
		},
		resetPagination(
			state: PaginationState,
			action: PayloadAction<any>
		): void {
			const { name } = action.payload;
			state[name as keyof PaginationState] = defaultPaginationDetails;
		},
	},
});

export const { reducer } = slice;

export const setPagination =
	(name: string, details: PaginationDetailsState): AppThunk =>
	(dispatch): any => {
		dispatch(slice.actions.setPagination({ name, details }));
	};

export const resetPagination =
	(name: string): AppThunk =>
	(dispatch): any => {
		dispatch(slice.actions.resetPagination({ name }));
	};

export const updateListByPagination =
	(name: string, page: number, url: string = null): AppThunk =>
	(dispatch): any => {
		const { paginationDetails } = store.getState();
		const currentPaginationDetails = paginationDetails[name];
		if (
			page === currentPaginationDetails.page ||
			(!url && !currentPaginationDetails.url)
		) {
			return;
		}
		const offset = (page - 1) * currentPaginationDetails.limit;
		const { limit } = currentPaginationDetails;
		// const url = currentPaginationDetails.url;
		return new Promise((resolve, reject) => {
			try {
				const response: any = axiosClient.get(
					url || currentPaginationDetails.url,
					{
						params: {
							offset,
							limit,
						},
					}
				);
				dispatch(
					setPagination(name, {
						...currentPaginationDetails,
						...response.data,
						offset,
						page,
					})
				);
				resolve(response.data);
			} catch (error: any) {
				error.handleGlobally(error);
				reject(error.response);
			}
		});
	};

export const fetchListByPagination =
	(name: string, url: string = null): AppThunk =>
	async (dispatch): Promise<any> => {
		const { paginationDetails } = store.getState();
		const currentPaginationDetails = paginationDetails[name];
		if (!url && !currentPaginationDetails.url) {
			return;
		}
		return new Promise((resolve, reject) => {
			try {
				const response: any = axiosClient.get(
					url || currentPaginationDetails.url,
					{
						params: {
							offset: currentPaginationDetails.offset,
							limit: currentPaginationDetails.limit,
						},
					}
				);
				if (response.data.results?.length !== 0) {
					dispatch(
						setPagination(name, {
							...currentPaginationDetails,
							...response.data,
							url: url || currentPaginationDetails.url,
						})
					);
					resolve(response.data);
				}
			} catch (error: any) {
				error.handleGlobally(error);
				reject(error.response);
			}
		});
	};
