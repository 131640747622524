/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
	loading: boolean;
	token: null | {
		access: string;
		refresh: string;
	};
	user: null | any;
}

const initialState: AuthState = {
	loading: false,
	token: null,
	user: null,
};

const slice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		onSignInBegin(state: AuthState): void {
			state.loading = true;
		},
		setTokens(state: AuthState, action: PayloadAction<any>): void {
			const { access, refresh } = action.payload;
			state.token = { access, refresh };
			state.loading = false;
		},
		onSignInFail(state: AuthState): void {
			state.token = null;
			state.loading = false;
			state.user = null;
		},
		clearTokens(state: AuthState): void {
			state.token = null;
			state.loading = false;
			state.user = null;
		},
		setUserInfo(state: AuthState, action: PayloadAction<any>): void {
			state.user = action.payload;
			state.loading = false;
		},
	},
});

export const { actions, reducer } = slice;

// Extract and export each action creator by name
export const {
	setTokens,
	clearTokens,
	onSignInBegin,
	onSignInFail,
	setUserInfo,
} = actions;

export default reducer;
