import { styled } from "@mui/system";
import CopyGameBlock from "components/docs/CopyGameBlock";
// import MDTypography from "template/components/MDTypography";
import CustomTooltip from "../CustomTooltip";

const LockStyle = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	position: "relative",
	// backgroundColor: theme.palette.dark.semi,
	backgroundColor: theme.palette.dark.ultraLight,
	minHeight: 48,
	padding: 10,
	borderRadius: 5,
	[theme.breakpoints.down("sm")]: {
		padding: 0,
	},
	".locked": {
		fontSize: "0",
	},
	".tooltip-wrapper": {
		position: "absolute",
		right: -30,
		backgroundColor: theme.palette.primary.main,
		width: 22,
		height: 22,
		borderRadius: "50%",
		paddingLeft: "1px",
		"& button": {
			padding: "5px 6px 3px 5px",
			"& img": {
				maxHeight: "14px",
				paddingLeft: "1px",
				paddingBottom: "2px",
			},
		},
	},
}));

interface lockType {
	unlock?: boolean;
	url?: string;
}
export default function LockBlock({ unlock, url }: lockType) {
	return unlock ? (
		<LockStyle>
			<div className="unlocked">
				<CopyGameBlock link={url} />
				{/* <MDTypography
					variant="body2"
					fontSize="small"
					fontWeight="bold"
				>
					{url}
				</MDTypography> */}
			</div>
			<div className="tooltip-wrapper">
				<CustomTooltip
					title="Share this url with the person who is doing Integration"
					placement="right"
				/>
			</div>
		</LockStyle>
	) : (
		<LockStyle>
			<div className="locked">locked</div>
			<div className="tooltip-wrapper">
				<CustomTooltip
					title="This unique url will be required during Integration process. Integration is a one time process and takes hardly 30 minutes."
					placement="right"
				/>
			</div>
		</LockStyle>
	);
}

LockBlock.defaultProps = {
	unlock: true,
	url: "",
};
