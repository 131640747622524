import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";
import MDButton from "template/components/MDButton";
import ModalWrapper from "components/modals/ModalWrapper";
import backImg from "assets/images/come_back.jpg";
import CloseButton from "components/elements/CloseButton";

const ConfirmStyle = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.white.main,
	padding: "3rem",
	boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
	borderRadius: "12px",
	maxWidth: "96vw",
	[theme.breakpoints.down("sm")]: {
		padding: "2rem",
	},
	"& .modal-wrapper": {
		display: "flex",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			alignItems: "center",
		},
		"& .modal-thumb": {
			minWidth: "260px",
			[theme.breakpoints.down("sm")]: {
				marginBottom: "15px",
				textAlign: "center",
			},
			img: {
				maxWidth: "100%",
			},
		},
		"& .list-style": {
			"& li": {
				paddingBottom: "25px",
				"&:last-child": {
					paddingBottom: "0",
				},
			},
		},
	},
}));

type modalType = {
	open: boolean;
	handleClose: Function;
	confirmModalAction: Function;
};

export default function ConfirmModal({
	open,
	handleClose,
	confirmModalAction,
}: modalType) {
	return (
		<div>
			<Modal
				open={open}
				aria-labelledby="confirm-message"
				aria-describedby="confirm-message-description"
			>
				<>
					<ModalWrapper>
						<Box>
							<ConfirmStyle>
								<div className="modal-wrapper">
									<div className="modal-thumb">
										<img src={backImg} alt="welcome" />
									</div>
									<div className="modal-content">
										<ul className="list-style">
											<li>
												Book the meeting with us and our
												expert Developers will do the
												Integration for you on a live
												call, FREE OF COST.
											</li>
											<li>
												Once Integrated come back to our
												Platform and start publishing
												your Tournaments.
											</li>
										</ul>
									</div>
								</div>
								<Box
									mt={4}
									sx={{
										display: "flex",
										justifyContent: "center",
									}}
								>
									<MDButton
										variant="outlined"
										color="dark"
										onClick={() => confirmModalAction()}
									>
										Ok
									</MDButton>
								</Box>
							</ConfirmStyle>
						</Box>
					</ModalWrapper>
					<CloseButton handleClose={handleClose} buttonText="Close" />
				</>
			</Modal>
		</div>
	);
}
