/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPlatform, IPlatforms } from "types/data";

const initialState: IPlatforms = {
	platforms: [],
	loading: false,
	error: null,
};

const slice = createSlice({
	name: "platforms",
	initialState,
	reducers: {
		fetchPlatformsBegin: (state: IPlatforms) => {
			state.loading = true;
		},
		fetchPlatformsSuccess(
			state: IPlatforms,
			action: PayloadAction<IPlatform[]>
		): void {
			state.platforms = [...action.payload];
			state.loading = false;
		},
		createPlatformSuccess: (
			state: IPlatforms,
			action: PayloadAction<IPlatform>
		) => {
			state.platforms = [...state.platforms, action.payload];
			state.loading = false;
		},
		fetchPlatformsFail: (state: IPlatforms) => {
			state.platforms = [];
			state.loading = false;
		},
		stopPlatformLoading: (state: IPlatforms) => {
			state.loading = false;
		},
		clearPlatforms: (state: IPlatforms) => {
			state.loading = false;
			state.platforms = [];
			state.error = null;
		},
	},
});

export const { actions, reducer } = slice;

export const {
	fetchPlatformsBegin,
	fetchPlatformsSuccess,
	createPlatformSuccess,
	fetchPlatformsFail,
	stopPlatformLoading,
	clearPlatforms,
} = actions;

export default reducer;
