import { styled } from "@mui/system";

const ModalWrapper = styled("div")(({ theme }) => ({
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	boxShadow: "24",
	minWidth: "55vw",
	border: "0",
	outline: "0",
	[theme.breakpoints.down("xl")]: {
		minWidth: "80vw",
	},
	[theme.breakpoints.down("lg")]: {
		minWidth: "80vw",
	},
	[theme.breakpoints.down("md")]: {
		minWidth: "95vw",
		paddingTop: "15%",
		paddingBottom: "15%",
		overflowY: "scroll",
		maxHeight: "100vh",
		top: "50.5%",
	},
	"&.last-wrapper": {
		left: "35%",
	},
}));

export default ModalWrapper;
