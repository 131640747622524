/* eslint-disable no-unused-vars */
import { useState, ReactNode } from "react";
import { styled } from "@mui/system";

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import MDAvatar from "template/components/MDAvatar";
import MDButton from "template/components/MDButton";
import ConfirmModal from "components/modals/ConfirmModal";
import { useDispatch } from "react-redux";
import { openGetStartedModal } from "slices/modalSlice";
import { useNavigate } from "react-router-dom";
import { MEETING_CALL_URL } from "constants/constants";

// Declaring prop types for the ActionCard
interface Props {
	color?:
		| "primary"
		| "secondary"
		| "info"
		| "success"
		| "warning"
		| "error"
		| "dark"
		| "light";
	image: string;
	title: string;
	dateTime?: string;
	description: ReactNode;
	members?: string[];
	dropdown?: {
		action?: (...arg: any) => void;
		menu?: ReactNode;
	};
	platformUnlocked?: boolean;
	actionButtons?: {
		disabledButtons:
			| {
					delete?: boolean;
			  }
			| undefined
			| null;
		action?: (...arg: any) => void;
	};
	[key: string]: any;
}

// Custom styles for ActionCard
const CustomCardStyle = styled("div")(({ theme }) => ({
	position: "relative",
	"& .card-container": {
		display: "flex",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			rowGap: "30px",
			alignItems: "center",
		},
	},
	"& .code-block-container": {
		width: "93%",
	},
	"& .header-container": {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: "20px",
		marginTop: "-20px",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			justifyContent: "center",
			textAlign: "left",
			paddingLeft: 0,
			"& .title-container": {
				paddingLeft: "25px",
			},
		},
	},
	"& .card-footer": {
		[theme.breakpoints.down("sm")]: {
			alignItems: "baseline",
			flexFlow: "column",
			rowGap: "10px",
		},
	},
}));
function IntegrationCard({
	footerTitle = "",
	footerSubtitle = "",
	footerButton = null,
	color,
	image,
	imageOnly = false,
	title,
	subtitle,
	dateTime,
	description,
	members,
	dropdown,
	platformUnlocked,
	actionButtons,
}: Props): JSX.Element {
	const navigate = useNavigate();
	// const externalMeetingUrl = "https://meetings.hubspot.com/aakanksha-singh";
	const [confirmModal, setConfirmModal] = useState<boolean>(false);
	const handleConfirmModalClose = () => setConfirmModal(false);
	const handleConfirmModalOpen = () => setConfirmModal(true);
	const confirmModalAction = () => {
		window.open(MEETING_CALL_URL, "_blank");
		setConfirmModal(false);
	};
	const dispatch = useDispatch();

	return (
		<CustomCardStyle>
			<Card>
				<MDBox p={2}>
					<MDBox className="card-container">
						<MDAvatar
							src={image}
							alt={title}
							size="xl"
							variant="rounded"
							bgColor={color}
							sx={{
								"& .MuiAvatar-img": {
									width: imageOnly ? "100%" : "80%",
								},
								p: imageOnly ? 0 : 1,
								mt: -6,
								borderRadius: ({ borders: { borderRadius } }) =>
									borderRadius.xl,
							}}
						/>
						<MDBox
							sx={{
								display: "flex",
								width: "100%",
							}}
							className="header-container"
							lineHeight={0}
						>
							<MDBox
								sx={{
									display: "flex",
								}}
								className="title-container"
							>
								<MDTypography
									variant="h6"
									textTransform="capitalize"
									fontWeight="medium"
								>
									{title}
								</MDTypography>
								{platformUnlocked && (
									<MDTypography
										color="dark"
										onClick={() =>
											actionButtons.action("edit")
										}
										sx={{
											ml: 1,
											mr: 2,
											alignSelf: "flex-start",
										}}
										fontSize="small"
										lineHeight={2}
									>
										<Icon
											sx={{
												cursor: "pointer",
												fontWeight: "bold",
											}}
											fontSize="small"
										>
											edit
										</Icon>
									</MDTypography>
								)}
							</MDBox>
							{subtitle && (
								<MDBox
									sx={{
										width: "70%",
										pt: 1,
										direction: "rtl",
									}}
								>
									{subtitle}
								</MDBox>
							)}
						</MDBox>
						{dropdown && (
							<MDTypography
								color="secondary"
								onClick={dropdown.action}
								sx={{
									ml: "auto",
									mt: -1,
									alignSelf: "flex-start",
									py: 1.25,
								}}
							>
								<Icon
									sx={{
										cursor: "pointer",
										fontWeight: "bold",
									}}
								>
									more_vert
								</Icon>
							</MDTypography>
						)}
						{dropdown.menu}
					</MDBox>
					<MDBox
						sx={{
							display: "flex",
							justifyContent: "space-between",
						}}
						py={2}
					>
						<MDBox
							my={2}
							lineHeight={1}
							className="code-block-container"
						>
							<MDTypography
								variant="button"
								fontWeight="light"
								color="text"
							>
								{description}
							</MDTypography>
						</MDBox>
					</MDBox>
					<MDBox
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						className="card-footer"
					>
						<MDBox
							display="flex"
							flexDirection="column"
							lineHeight={0}
						>
							{platformUnlocked && (
								<MDTypography
									variant="caption"
									fontWeight="medium"
									sx={{ fontSize: "13px" }}
								>
									{footerTitle}
								</MDTypography>
							)}

							<MDTypography
								variant="button"
								fontWeight="regular"
								color="primary"
								onClick={() => {
									/* dispatch(
										openGetStartedModal(
											"https://www.youtube.com/embed/3ztRM_LO8bI"
										)
									); */
								}}
								style={{
									textDecoration: "underline",
									cursor: "pointer",
								}}
							>
								{footerSubtitle}
							</MDTypography>
						</MDBox>
						{!platformUnlocked ? (
							<MDBox
								display="flex"
								flexDirection="column"
								lineHeight={0}
							>
								{/* <MDTypography variant="button" fontWeight="medium">
							{dateTime}
						</MDTypography> */}
								<MDButton
									onClick={footerButton.onClick}
									variant="gradient"
									color={footerButton.color || "info"}
									disabled={footerButton?.disabled}
								>
									<MDTypography
										variant="button"
										fontWeight="regular"
										color="inherit"
										textTransform="uppercase"
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										{footerButton?.label}
									</MDTypography>
								</MDButton>
							</MDBox>
						) : (
							<MDBox
								display="flex"
								flexDirection="row"
								lineHeight={0}
								sx={{
									flexDirection: {
										lg: "row",
										sm: "column",
										xs: "column",
									},
									rowGap: {
										lg: "0",
										sm: "7px",
										xs: "7px",
									},
								}}
							>
								<MDButton
									onClick={() => {
										navigate("/doc");
									}}
									variant="outlined"
									color="info"
									size="small"
									sx={{
										marginRight: "10px",
										paddingLeft: "12px",
										paddingRight: "12px",
									}}
								>
									<MDTypography
										variant="button"
										color="success"
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										Do It Myself
									</MDTypography>
								</MDButton>
								<MDButton
									onClick={() => handleConfirmModalOpen()}
									variant="gradient"
									color="success"
									size="medium"
									sx={{
										paddingLeft: "12px",
										paddingRight: "12px",
									}}
								>
									<MDTypography
										variant="button"
										fontWeight="regular"
										color="inherit"
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										Goama to do it FREE of Cost
									</MDTypography>
								</MDButton>
							</MDBox>
						)}
					</MDBox>
				</MDBox>
			</Card>
			<ConfirmModal
				open={confirmModal}
				handleClose={handleConfirmModalClose}
				confirmModalAction={confirmModalAction}
			/>
		</CustomCardStyle>
	);
}

// Declaring default props for ActionCard
IntegrationCard.defaultProps = {
	color: "dark",
	dateTime: "",
	members: [],
	dropdown: false,
	platformUnlocked: false,
	actionButtons: false,
};

export default IntegrationCard;
