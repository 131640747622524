import Modal from "@mui/material/Modal";
import CloseButton from "components/elements/CloseButton";
import { useDispatch, useSelector } from "react-redux";
import { closeGetStartedModal } from "slices/modalSlice";
// import analytics from "utils/analytics";
import ModalWrapper from "./ModalWrapper";

export default function GetStartedModal() {
	const dispatch = useDispatch();
	const { showGetStartedModal } = useSelector((state: any) => state.modal);
	const { modalVideoUrl } = useSelector((state: any) => state.modal);
	const handleClose = () => {
		// analytics.gotoDashboard({});
		dispatch(closeGetStartedModal());
	};
	if (!showGetStartedModal) return null;

	/* if (showWorkspaceModal) {
		analytics.enterWorkspace({});
	} */

	return (
		<div className="modal-wrapper">
			<Modal
				BackdropProps={{
					style: { backgroundColor: "#191919", opacity: 0.85 },
				}}
				// onClose={handleClose}
				open={showGetStartedModal}
				aria-labelledby="workspace-modal"
				aria-describedby="create-workspace"
			>
				<>
					<ModalWrapper>
						<iframe
							style={{
								height: "70vh",
								width: "100%",
							}}
							src={modalVideoUrl.payload}
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						/>
					</ModalWrapper>
					<CloseButton handleClose={handleClose} buttonText="Close" />
				</>
			</Modal>
		</div>
	);
}
