// @mui material components
import MDProgress from "template/components/MDProgress";
import MDBox from "template/components/MDBox";
import { useState, useEffect } from "react";
import MDAvatar from "template/components/MDAvatar";
import LOGO from "assets/images/logos/brand_logo_dark.png";
import { Modal, Theme } from "@mui/material";
import Fade from "@mui/material/Fade";
import { useSelector } from "react-redux";

function FullScreenLoader(): JSX.Element {
	const modal = useSelector((state: any) => state.modal);
	const [progress, setProgress] = useState(0);
	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress >= 100) {
					return 0;
				}
				const diff = Math.random() * 20;
				return Math.min(oldProgress + diff, 100);
			});
		}, 300);

		return () => {
			clearInterval(timer);
		};
	}, []);
	return (
		<Modal hideBackdrop open={modal.fullScreenLoading}>
			<Fade
				timeout={{
					enter: 0,
					exit: 1000,
				}}
				in={modal.fullScreenLoading}
				unmountOnExit
			>
				<MDBox
					sx={(theme: Theme) => ({
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						height: "100vh",
						width: "100vw",
						zIndex: 99999,
						background: `${theme.palette.light.main}`,
					})}
				>
					<MDAvatar
						sx={{ mb: 2 }}
						size="xxl"
						src={LOGO}
						alt="Avatar"
						variant="square"
					/>
					{/* <MDTypography color="primary" sx={{ mb: 2 }} variant="h2">
						Goama
					</MDTypography> */}
					<MDBox
						sx={{
							width: "10rem",
						}}
					>
						<MDProgress
							variant="contained"
							color="primary"
							value={progress}
						/>
					</MDBox>
				</MDBox>
			</Fade>
		</Modal>
	);
}

export default FullScreenLoader;
