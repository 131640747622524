import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import Switch from "@mui/material/Switch";

export default function SwitchButton(props: any) {
	const {
		checked,
		onChange,
		activeButtontext,
		activeColor,
		inactiveButtonText,
		disabled = false,
	} = props;

	return (
		<div className="toggle-modal-wrapper">
			<MDBox lineHeight={1}>
				<MDBox ml={1}>
					<Switch
						disabled={disabled}
						checked={checked}
						onChange={onChange}
						color="primary"
						// disabled={activeButtontext === "completed"}
					/>
				</MDBox>
				{checked ? (
					<MDTypography
						variant="caption"
						fontWeight="regular"
						color={
							checked
								? activeColor || "warning"
								: (disabled && "text") || "success"
						}
					>
						{activeButtontext}
					</MDTypography>
				) : (
					<MDTypography
						variant="caption"
						fontWeight="regular"
						className="label"
						color={
							inactiveButtonText === "completed" || disabled
								? "text"
								: "black"
						}
					>
						{inactiveButtonText}
					</MDTypography>
				)}
			</MDBox>
		</div>
	);
}
