/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import MDInput from "template/components/MDInput";
import MDButton from "template/components/MDButton";

// Authentication layout components
import BasicLayout from "template/layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/backgrounds/reset-password.png";
import { getJSONfromQueryParams } from "utils";
import axiosClient from "utils/axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import FormFieldErrorMessage from "components/forms/FormFieldErrorMessage";
import { NavLink } from "react-router-dom";

const schema = yup
	.object({
		password: yup
			.string()
			.required("No password provided")
			.min(8, "Password should be 8 characters minimum"),
		/* .matches(
			/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
			"Password should contain at least one letter and one number"
		), */
		password_confirmation: yup
			.string()
			.oneOf([yup.ref("password"), null], "Passwords do not match"),
	})
	.required();

function ResetPasswordPage(): JSX.Element {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});
	const {
		location: { search },
	} = window;
	const params = getJSONfromQueryParams(search);

	const onSubmit = async (data: any) => {
		setLoading(true);
		axiosClient
			.post("users/password/reset/", {
				...params,
				password: data?.password,
			})
			.then((res: any) => {
				if (res?.data?.success) {
					setSuccess(true);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<BasicLayout hideFooter hideNavBar image={bgImage}>
			<Card>
				<MDBox
					variant="gradient"
					bgColor={success ? "success" : "info"}
					borderRadius="lg"
					coloredShadow={success ? "success" : "info"}
					mx={2}
					mt={-3}
					p={2}
					mb={1}
					textAlign="center"
				>
					<MDTypography
						variant="h4"
						fontWeight="medium"
						color="white"
						mt={1}
					>
						{!success ? "Change Password" : "Congratulations"}
					</MDTypography>
					{!success && (
						<Grid container spacing={3} justifyContent="center">
							<Grid item>
								<MDTypography variant="subtitle2" color="white">
									Set a new password for your account
								</MDTypography>
							</Grid>
						</Grid>
					)}
				</MDBox>
				{!success && (
					<MDBox pt={4} pb={3} px={3}>
						<MDBox component="form" role="form">
							<MDBox mb={4}>
								<MDTypography variant="subtitle2">
									Use at least one Capital letter and a
									special character
								</MDTypography>
							</MDBox>
							<MDBox>
								<MDInput
									{...register("password")}
									type="password"
									label="New Password"
									fullWidth
									variant="standard"
								/>
							</MDBox>
							<FormFieldErrorMessage
								message={errors?.password?.message}
							/>
							<MDBox mt={2}>
								<MDInput
									{...register("password_confirmation")}
									type="password"
									label="Confirm Password"
									variant="standard"
									fullWidth
								/>
							</MDBox>
							<FormFieldErrorMessage
								message={errors?.password_confirmation?.message}
							/>
							<MDBox mt={4} mb={1}>
								<MDButton
									loading={loading}
									disabled={loading}
									onClick={handleSubmit(onSubmit)}
									variant="gradient"
									color="dark"
									fullWidth
								>
									set new password
								</MDButton>
							</MDBox>
						</MDBox>
					</MDBox>
				)}
				{success && (
					<MDBox pt={4} pb={3} px={3}>
						<MDTypography variant="subtitle2" color="dark">
							You have changed your password successfully
						</MDTypography>
						<MDBox mt={4} mb={1}>
							<NavLink to="/login">
								<MDButton
									variant="gradient"
									color="dark"
									fullWidth
								>
									Go back to Login
								</MDButton>
							</NavLink>
						</MDBox>
					</MDBox>
				)}
			</Card>
		</BasicLayout>
	);
}

export default ResetPasswordPage;
