import * as React from "react";

import { styled } from "@mui/system";
import MDTypography from "template/components/MDTypography";
import MDButton from "template/components/MDButton";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import LockResetIcon from "@mui/icons-material/LockReset";

const PasswordChangeFormStyle = styled("div")(({ theme }) => ({
	padding: "25px",
	boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
	backgroundColor: theme.palette.white.main,
	borderRadius: "12px",
	"& .basic-form-header": {
		marginBottom: "35px",
	},
	"& .form-wrapper": {
		"& .MuiFormControl-root": {
			marginBottom: "25px",
		},
		"& .text-area": {
			marginTop: "10px",
			"& ul": {
				margin: "15px 0 0 15px",
				padding: "0",
				fontSize: "0.875rem",
				color: theme.palette.text.main,
			},
		},
		"& .submit-btn": {
			textAlign: "right",
		},
	},
}));

export default function PasswordChangeForm() {
	interface State {
		currentPassword: string;
		newPassword: string;
		confirmPassword: string;
	}

	const [values, setValues] = React.useState<State>({
		currentPassword: "",
		newPassword: "",
		confirmPassword: "",
	});

	const handleChange =
		(prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({ ...values, [prop]: event.target.value });
		};

	return (
		<PasswordChangeFormStyle>
			<div className="basic-form-header">
				<MDTypography variant="h5">Change Password</MDTypography>
			</div>
			<div className="form-wrapper">
				<form>
					<FormControl
						sx={{ mb: 2, width: "100%" }}
						variant="outlined"
					>
						<OutlinedInput
							id="outlined-adornment-password"
							type="text"
							name="currentPassword"
							onChange={handleChange("currentPassword")}
							value={values.currentPassword}
							fullWidth
							placeholder="Current password"
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										edge="end"
									>
										<LockResetIcon />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
					<FormControl
						sx={{ mb: 2, width: "100%" }}
						variant="outlined"
					>
						<OutlinedInput
							id="outlined-adornment-password"
							type="text"
							name="newPassword"
							onChange={handleChange("newPassword")}
							value={values.currentPassword}
							fullWidth
							placeholder="New password"
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="new password"
										edge="end"
									>
										<LockResetIcon />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
					<FormControl
						sx={{ mb: 2, width: "100%" }}
						variant="outlined"
					>
						<OutlinedInput
							id="outlined-adornment-confirmPassword"
							type="text"
							name="confirmPassword"
							onChange={handleChange("confirmPassword")}
							value={values.confirmPassword}
							fullWidth
							placeholder="Confirm password"
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="confirm password"
										edge="end"
									>
										<LockResetIcon />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
					<div className="text-area">
						<MDTypography variant="h5" lineHeight={2}>
							Password requierements
						</MDTypography>
						<MDTypography
							variant="body2"
							fontWeight="regular"
							color="text"
						>
							Please follow this guide for a strong password:
						</MDTypography>
						<ul>
							<li>One special characters</li>
							<li>Min 6 characters</li>
							<li>One number (2 are recommended)</li>
							<li>Change it often</li>
						</ul>
					</div>
					<div className="submit-btn">
						<MDButton value="gradient" color="dark" type="submit">
							UPDATE PASSWORD
						</MDButton>
					</div>
				</form>
			</div>
		</PasswordChangeFormStyle>
	);
}
