// @mui material components
// import Grid from "@mui/material/Grid";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
// import MDTypography from "template/components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "template/examples/LayoutContainers/DashboardLayout";
// import Footer from "template/examples/Footer";
// import ReportsBarChart from "template/examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "template/examples/Charts/LineCharts/ReportsLineChart";
// import ComplexStatisticsCard from "template/examples/Cards/StatisticsCards/ComplexStatisticsCard";
// import BookingCard from "template/examples/Cards/BookingCard";

// Anaytics dashboard components
// import SalesByCountry from "template/layouts/dashboards/analytics/components/SalesByCountry";

// Data
// import defaultLineChartData from "template/layouts/pages/charts/data/defaultLineChartData";
// import reportsBarChartData from "template/layouts/dashboards/analytics/data/reportsBarChartData";
// import reportsLineChartData from "template/layouts/dashboards/analytics/data/reportsLineChartData";

// Images
// import booking1 from "assets/images/products/product-1-min.jpg";
// import booking2 from "assets/images/products/product-2-min.jpg";
// import booking3 from "assets/images/products/product-3-min.jpg";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
// import TournamentCard from "components/tournaments/TournamentCard";
// import ConnectFirstPlatformCard from "components/integration/ConnectFirstPlatformCard";
import MDTypography from "template/components/MDTypography";
// import CustomSelect from "components/elements/CustomSelect";
// import ListCard from "components/cards/ListCard";
// import GraphsTab from "components/dashboard/GraphsTab";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import analytics from "utils/analytics";
import fetchGraph from "actions/graphActions";
import TrialDurationAlert from "components/alerts/TrialDurationAlert";

// images
// import tournamentIcon from "assets/images/icons/tournament_icon.png";

/* const tournamnetsList = [
	{
		slug: "1",
		title: "Sneki snake charity tournament",
		meta_data: "Starts on 27 March 2020, at 12:30 PM",
		tournament_icon: tournamentIcon,
		status: false,
	},
	{
		slug: "1",
		title: "Tournament 2",
		meta_data: "Started on 03 March 2022, at 10:00 PM",
		tournament_icon: tournamentIcon,
		status: true,
	},
	{
		slug: "1",
		title: "Tournament 3",
		meta_data: "Started on 03 March 2022, at 10:00 PM",
		tournament_icon: tournamentIcon,
		status: true,
	},
]; */

function Analytics(): JSX.Element {
	const dispatch = useDispatch();
	// const { tournaments } = useSelector(
	// 	(state: any) => state.tournamentsReducer
	// );
	// const hasTournaments = tournaments && tournaments.length > 0;
	const { graph } = useSelector((state: any) => state.graphReducer);
	const { platforms } = useSelector((state: any) => state.platformsReducer);
	const platform =
		platforms && platforms.length > 0 && platforms[0] ? platforms[0] : null;

	useEffect(() => {
		dispatch(fetchGraph(platform?.slug));
		analytics.clickDashboard({});
	}, []);

	const getAnalyticNoDataText = () => {
		if (!graph?.dashboard_url) {
			return "No data to show yet. It will start appearing once a tournament goes live on your app";
		}
		return "";
		/* let title = "No data to show yet";
		if (hasTournaments && platform) {
			return `${title}`;
		}
		title += ", please ";
		if (!hasTournaments && platform) {
			title = `${title} create a Tournament `;
		} else if (!platform && hasTournaments) {
			title = `${title} connect a platform `;
		} else {
			title = `${title} create a Tournament and connect a platform `;
		}
		return title; */
	};

	// const { sales, tasks } = reportsLineChartData;

	// Action buttons for the BookingCard
	/* const actionButtons = (
		<>
			<Tooltip title="Refresh" placement="bottom">
				<MDTypography
					variant="body1"
					color="primary"
					lineHeight={1}
					sx={{ cursor: "pointer", mx: 3 }}
				>
					<Icon color="inherit">refresh</Icon>
				</MDTypography>
			</Tooltip>
			<Tooltip title="Edit" placement="bottom">
				<MDTypography
					variant="body1"
					color="info"
					lineHeight={1}
					sx={{ cursor: "pointer", mx: 3 }}
				>
					<Icon color="inherit">edit</Icon>
				</MDTypography>
			</Tooltip>
		</>
	); */

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox py={3}>
				<TrialDurationAlert />
				<MDBox py={3}>
					<MDTypography variant="h5">
						{getAnalyticNoDataText()}
					</MDTypography>
					{graph?.dashboard_url && (
						<MDBox mt={2}>
							<iframe
								title="Dashboard"
								className="frame-style"
								src={graph?.dashboard_url}
								width="100%"
								height="800"
								frameBorder="0"
							/>
						</MDBox>
					)}
				</MDBox>
			</MDBox>
			{/* <Footer /> */}
		</DashboardLayout>
	);
}

export default Analytics;
