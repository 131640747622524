import { Card, Box, CardContent, CardMedia, Typography } from "@mui/material";

// Declaring props types for BookingCard
interface Props {
	title: string;
	description: string;
	href: string;
	hideFooter?: boolean;
	type: "video" | "audio" | "picture" | "iframe" | "img";
}

/* <iframe
	width="560"
	height="315"
	src="https://www.youtube.com/embed/65Oudj5bsYk"
	title="YouTube video player"
	frameborder="0"
	allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
	allowfullscreen
></iframe>; */

function VideoCard({
	title,
	description,
	href,
	type,
	hideFooter = false,
}: Props): JSX.Element {
	const renderCardMedia = () => {
		switch (type) {
			case "video":
				return (
					<CardMedia
						component={type}
						sx={{ width: "100%", margin: 0 }}
						src={href}
					/>
				);
			case "iframe":
				return (
					<CardMedia
						component="iframe"
						sx={{ width: "100%", height: 270, margin: 0 }}
						src={href}
						title={title}
						allowFullScreen
						allow="autoplay"
						// frameborder="0"
						// allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					/>
				);
			default:
				return (
					<CardMedia
						component={type}
						sx={{ width: "100%", margin: 0 }}
						src={href}
					/>
				);
		}
	};

	return (
		<Card sx={{ display: "flex" }}>
			{renderCardMedia()}
			{!hideFooter && (
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<CardContent sx={{ flex: "1 0 auto" }}>
						<Typography component="div" variant="h5">
							{title}
						</Typography>
						<Typography
							variant="subtitle1"
							color="text.secondary"
							component="div"
						>
							{description}
						</Typography>
					</CardContent>
				</Box>
			)}
		</Card>
	);
}

// Declaring default props for BookingCard
VideoCard.defaultProps = {
	hideFooter: false,
};

export default VideoCard;
