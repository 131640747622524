import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";

// template component
import MDTypography from "template/components/MDTypography";

// images
// import gameThumb from "assets/images/thumb/game_thumb.png";
import { useDispatch, useSelector } from "react-redux";
import ActionCard from "components/cards/ActionCard";
import { Grid, Icon } from "@mui/material";
import GAME_IMAGE from "assets/images/dummy/game.png";
import { openTournamentBuilderModal } from "slices/modalSlice";
// import TrialDurationAlert from "components/alerts/TrialDurationAlert";
import MDBox from "template/components/MDBox";
import { useNavigate } from "react-router-dom";
import TournamentViewFilter from "./components/TournamentViewFilter";

// import single tournament
import SingleTournament from "../components/SingleTournament";

// styles

const ViewStyle = styled("div")(({ theme }) => ({
	marginTop: "50px",
	"& .tournament-view-list": {
		display: "flex",
		flexWrap: "wrap",
		marginTop: "50px",
		"& .custom-table": {
			borderCollapse: "separate",
			borderSpacing: "0 1.25rem",
			maxWidth: "100vw",
			minWidth: "100%",
		},
		"& .MuiPaper-root": {
			backgroundColor: "transparent",
			boxShadow: "none",
			"& .MuiTableRow-root": {
				position: "relative",
				height: "142px",
				"&::before": {
					content: `""`,
					position: "absolute",
					left: "0",
					top: "0",
					width: "100%",
					height: "100%",
					backgroundColor: theme.palette.white.main,
					boxShadow: "0 1px 1px rgb(0 0 0 / 25%)",
					borderRadius: "12px",
				},
				"& .MuiTableCell-root": {
					position: "relative",
					zIndex: "5",
					padding: "0",
				},
				"&.first-row": {
					backgroundColor: "transparent",
					boxShadow: "none",
					height: "unset",
					"&::before": {
						content: "none",
					},
				},
			},
		},
		"& .game-info": {
			display: "flex",
			justifyContent: "left",
			alignItems: "center",
			paddingLeft: "15px",
			flexWrap: "wrap",
			"& .game-checked": {
				// maxWidth: "50px",
				// paddingLeft: "8px",
			},
			"& .game-thumb": {
				// display: "flex",
				// paddingLeft: 20,
			},
			"& .game-data": {
				maxWidth: "200px",
				overflow: "hidden",
				"& h6": {
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					overflow: "hidden",
				},
			},
		},
		"& .avg-player": {
			display: "flex",
			justifyContent: "flex-start",
			paddingLeft: "15px",
		},
		"& .game-action": {
			display: "flex",
			"& button": {
				backgroundColor: "transparent",
				border: "0",
				padding: "0 6px",
				cursor: "pointer",
				"&:first-child": {
					paddingLeft: "0",
				},
				"& svg": {
					color: theme.palette.dark.main,
					maxWidth: "1.1rem",
					opacity: "0.85",
				},
			},
		},
		// "& .active-status": {
		// 	"& .Mui-checked": {
		// 		pointerEvents: "none !important",
		// 		"& .MuiSwitch-thumb": {
		// 			borderColor: `${theme.palette.success.main} !important`,
		// 		},
		// 		"&+.MuiSwitch-track": {
		// 			borderColor: `${theme.palette.success.main} !important`,
		// 			backgroundColor: `${theme.palette.success.main} !important`,
		// 		},
		// 	},
		// },
		"& .inactive-status": {
			// "& .MuiSwitch-track": {
			// 	borderColor: `${theme.palette.dark.main} !important`,
			// 	backgroundColor: `${theme.palette.dark.main} !important`,
			// },
			// "& .MuiSwitch-thumb": {
			// 	borderColor: `${theme.palette.secondary.main} !important`,
			// },
			"& .Mui-checked": {
				"& .MuiSwitch-thumb": {
					borderColor: `${theme.palette.warning.main} !important`,
				},
				"&+.MuiSwitch-track": {
					borderColor: `${theme.palette.warning.main} !important`,
					backgroundColor: `${theme.palette.warning.main} !important`,
				},
			},
		},
		"& .off-status": {
			".label": {
				color: `${theme.palette.secondary.main} !important`,
			},
			"& .MuiSwitch-track": {
				borderColor: `${theme.palette.secondary.main} !important`,
				backgroundColor: `${theme.palette.secondary.main} !important`,
			},
			"& .MuiSwitch-thumb": {
				borderColor: `${theme.palette.secondary.main} !important`,
				// backgroundColor: `${theme.palette.secondary.main} !important`,
			},
		},
		"& .scheduled-status": {
			"& .MuiSwitch-track": {
				borderColor: `${theme.palette.warning.focus} !important`,
				backgroundColor: `${theme.palette.warning.focus} !important`,
			},
			"& .MuiSwitch-thumb": {
				borderColor: `${theme.palette.warning.main} !important`,
			},
		},
		"& .on-status": {
			"& .MuiSwitch-track": {
				borderColor: `${theme.palette.success.focus} !important`,
				backgroundColor: `${theme.palette.success.focus} !important`,
			},
			"& .MuiSwitch-thumb": {
				borderColor: `${theme.palette.success.main} !important`,
			},
		},
	},
}));

export default function TournamentsView() {
	const { isDesktop } = useSelector((state: any) => state.layoutReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { tournaments } = useSelector(
		(state: any) => state.tournamentsReducer
	);
	const hasTournaments = tournaments && tournaments.length > 0;
	// console.log(tournaments);
	if (!hasTournaments) {
		return (
			<ViewStyle>
				<Grid>
					<Grid item xl={6} lg={6} md={6} sm={12}>
						<ActionCard
							color="secondary"
							imageOnly
							image={GAME_IMAGE}
							title="Add Games on my platform"
							description="Select the perfect game to engage your users to play and encourage them to do specific actions on-site."
							footerTitle="1,000"
							footerSubtitle="Active Players available"
							footerButton={{
								color: "secondary",
								label: "SELECT GAMES FOR MY APP",
								onClick: () => {
									dispatch(openTournamentBuilderModal());
								},
							}}
						/>
					</Grid>
				</Grid>
			</ViewStyle>
		);
	}
	return (
		<ViewStyle>
			<div className="tournaments-view-wrapper">
				{hasTournaments && (
					<div>
						<MDBox
							sx={{
								mt: -6,
								mb: 1,
								display: "flex",
								justifyContent: "flex-end",
								alignItems: "center",
								cursor: "pointer",
							}}
							onClick={() => {
								navigate("/dashboard");
							}}
						>
							<MDTypography color="secondary">
								STEPS GUIDE
							</MDTypography>
							<Icon fontSize="small" color="inherit">
								keyboard_double_arrow_right
							</Icon>
						</MDBox>
						<TournamentViewFilter />
						<MDBox
							className="tournament-view-list"
							sx={{
								marginTop: !isDesktop && "10px !important",
								overflow: "hidden",
							}}
						>
							<TableContainer component={Paper}>
								<Table
									sx={{ minWidth: 1200 }}
									aria-label="simple table"
									className="custom-table"
								>
									<TableHead
										style={{
											display: "table-header-group",
										}}
									>
										<TableRow className="first-row">
											<TableCell
												align="center"
												colSpan={3}
											>
												<MDTypography
													variant="body2"
													color="text"
													fontWeight="medium"
													fontSize="small"
												>
													TITLE
												</MDTypography>
											</TableCell>
											<TableCell align="left" colSpan={1}>
												<MDTypography
													variant="body2"
													color="text"
													fontWeight="medium"
													fontSize="small"
												>
													START DATE
												</MDTypography>
											</TableCell>
											<TableCell align="left" colSpan={1}>
												<MDTypography
													variant="body2"
													color="text"
													fontWeight="medium"
													fontSize="small"
												>
													END DATE
												</MDTypography>
											</TableCell>
											<TableCell align="left" colSpan={2}>
												<MDTypography
													variant="body2"
													color="text"
													fontWeight="medium"
													fontSize="small"
													textTransform="uppercase"
												>
													TOTAL PLAYERS
												</MDTypography>
											</TableCell>
											<TableCell align="left" colSpan={1}>
												<MDTypography
													variant="body2"
													color="text"
													fontWeight="medium"
													fontSize="small"
													textTransform="uppercase"
												>
													{/* PLATFORM CONNECTED */}
												</MDTypography>
											</TableCell>
											{/* <TableCell align="left" colSpan={1}>
												<MDTypography
													variant="body2"
													color="text"
													fontWeight="medium"
													fontSize="small"
													textTransform="uppercase"
												>
													PLATFORM CONNECTED
												</MDTypography>
											</TableCell> */}
											<TableCell
												align="center"
												colSpan={1}
											>
												<MDTypography
													variant="body2"
													color="text"
													fontWeight="medium"
													fontSize="small"
												>
													STATUS
												</MDTypography>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{tournaments.map((tournament: any) => (
											<SingleTournament
												tournament={tournament}
												key={tournament.alias}
											/>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</MDBox>
					</div>
				)}
			</div>
		</ViewStyle>
	);
}
