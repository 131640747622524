/* eslint-disable camelcase */
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MDTypography from "template/components/MDTypography";
import SmallCard from "components/elements/SmallCard";
import { styled } from "@mui/system";

// images
import cardIconOne from "assets/images/icons/desktop.png";
import pencilIcon from "assets/images/icons/pencil.png";
import premiumIcon from "assets/images/icons/premium_2.png";
import awardIcon from "assets/images/icons/award_2.png";
import calenderIcon from "assets/images/icons/calender.png";
import groupIcon from "assets/images/icons/group.png";
import MDBox from "template/components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import { ITournamentBuilderFormData } from "types/data";
import { formatDistanceStrict, format } from "date-fns";
import { useEffect, useState } from "react";
import { updateTournamentBuilderForm } from "slices/tournamentBuilderSlice";
import fetchGames from "actions/gamesActions";
import { truncateString } from "utils";

const SummeryStyle = styled("div")(({ theme }) => ({
	display: "flex",
	flexGrow: 1,
	justifyContent: "center",
	alignItems: "center",
	"& .single-summary": {
		minWidth: "265px",
		[theme.breakpoints.down("sm")]: {
			margin: "15px 0",
		},
	},
	"& .small-card-content": {
		textAlign: "right",
	},
	"& .action-btn": {
		"& button": {
			backgroundColor: "transparent",
			border: "0",
			paddingLeft: "10px",
			cursor: "pointer",
			"& img": {
				marginRight: "5px",
				maxWidth: "13px",
				maxHeight: "13px",
				objectFit: "cover",
			},
		},
	},
}));

export const tournamentTypeLists: string[] = [
	"",
	"Free tournament",
	"Paid Tournaments (Normal)",
	"Paid Tournaments (Dynamic)",
	"Paid Tournaments (Auto)",
	"Sponsored Tournaments",
	"Try Based Tournaments",
];

export default function Summary() {
	const dispatch = useDispatch();
	const [game, setGame] = useState(null);
	const { games } = useSelector((state: any) => state.gamesReducer);
	const { formData }: { formData: ITournamentBuilderFormData } = useSelector(
		(state: any) => state.tournamentBuilderReducer
	);
	const {
		start_time,
		end_time,
		tournament_type,
		// max_player,
		// goal,
		title,
		subtitle,
		// show_reward_setup,
		// no_rewards_checked,
		game_alias,
	} = formData;
	const startTime = `${start_time}` === "Invalid Date" ? null : start_time;
	const endTime = `${end_time}` === "Invalid Date" ? null : end_time;
	// console.log({ start_time, end_time });
	const distanceInWords =
		(startTime &&
			endTime &&
			formatDistanceStrict(new Date(startTime), new Date(endTime))) ||
		0;
	useEffect(() => {
		dispatch(fetchGames() as any).then(() => {
			if (game_alias) {
				const game = games.find(
					(item: any) => item.alias === formData?.game_alias
				);
				setGame(game);
			} else {
				setGame(null);
			}
		});
	}, [formData]);

	/* const rewards =
		no_rewards_checked || show_reward_setup === "no"
			? "No rewards"
			: "Yes, give rewards"; */

	const goToStep = (step: number) => {
		dispatch(
			updateTournamentBuilderForm({
				step,
			})
		);
	};
	return (
		<SummeryStyle>
			<MDBox>
				<Grid container xl={9} lg={11} sm={11} mx="auto">
					<Grid
						container
						item
						mx="auto"
						spacing={{
							xs: 0,
							sm: 2,
							lg: 3,
							md: 3,
							xl: 4,
						}}
						sx={{ margin: "0 auto", justifyContent: "center" }}
					>
						<Grid
							item
							xl={6}
							lg={6}
							md={5}
							sm={6}
							className="single-summary"
						>
							<Box>
								<SmallCard icon={cardIconOne}>
									<div className="small-card-content">
										<div className="action-btn">
											<button
												onClick={() => goToStep(1)}
												type="button"
											>
												<img
													src={pencilIcon}
													alt="edit"
												/>
												Edit
												{game?.service ===
													"premium" && (
													<span>
														&nbsp;&nbsp;
														<img
															src={premiumIcon}
															alt="premium"
														/>
														Game
													</span>
												)}
											</button>
										</div>
										<MDTypography
											// fontWeight="light"
											variant="body2"
										>
											{game?.service === "premium"
												? "Premium"
												: "Free"}{" "}
											game
										</MDTypography>
										<MDTypography variant="h5">
											{game?.name}
										</MDTypography>
									</div>
								</SmallCard>
							</Box>
						</Grid>
						<Grid
							item
							xl={6}
							lg={6}
							md={5}
							sm={6}
							className="single-summary"
						>
							<Box>
								<SmallCard icon={awardIcon}>
									<div className="small-card-content">
										<div className="action-btn">
											<button
												onClick={() => goToStep(2)}
												type="button"
											>
												<img
													src={pencilIcon}
													alt="edit"
												/>
												Edit Info
											</button>
										</div>
										<MDTypography variant="body2">
											{truncateString(subtitle, 11)}
										</MDTypography>
										<MDTypography variant="h5">
											{truncateString(title, 11)}
										</MDTypography>
									</div>
								</SmallCard>
							</Box>
						</Grid>
						<Grid
							item
							xl={6}
							lg={6}
							md={5}
							className="single-summary"
						>
							<Box>
								<SmallCard icon={calenderIcon}>
									<div className="small-card-content">
										<div className="action-btn">
											<button
												onClick={() => goToStep(4)}
												type="button"
											>
												<img
													src={pencilIcon}
													alt="edit"
												/>
												Edit Duration
											</button>
										</div>
										<MDTypography variant="h5">
											{distanceInWords}
										</MDTypography>
										<MDTypography
											// fontWeight="regular"
											variant="body2"
										>
											Start date{" "}
											{startTime &&
												format(
													new Date(startTime),
													"MM/dd/yyyy"
												)}
										</MDTypography>
									</div>
								</SmallCard>
							</Box>
						</Grid>
						<Grid
							item
							xl={6}
							lg={6}
							md={5}
							className="single-summary"
						>
							<Box>
								<SmallCard icon={groupIcon}>
									<div className="small-card-content">
										<div className="action-btn">
											<button
												onClick={() => goToStep(3)}
												type="button"
											>
												<img
													src={pencilIcon}
													alt="edit"
												/>
												Edit Tournament Type
											</button>
										</div>
										<MDTypography variant="body2">
											(Tournament Type)
										</MDTypography>
										<MDTypography variant="h5">
											{
												tournamentTypeLists[
													tournament_type as keyof typeof tournamentTypeLists
												]
											}
										</MDTypography>
									</div>
								</SmallCard>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</MDBox>
		</SummeryStyle>
	);
}
