// @mui material components
// import Grid from "@mui/material/Grid";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
// import Banner from "components/Banner";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "template/layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import bannerImg from "assets/images/banners/01.jpg";
import TournamentsView from "containers/Tournaments/TournamentsView";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchPlatforms } from "actions/platformActions";
import { fetchTournaments } from "actions/tournamentActions";
import { closeTournamentBuilderModal } from "slices/modalSlice";
import PageBanner from "components/Banner/PageBanner";
import analytics from "utils/analytics";
import TrialDurationAlert from "components/alerts/TrialDurationAlert";

function Tournaments(): JSX.Element {
	const dispatch = useDispatch();
	const { workspaces } = useSelector((state: any) => state.workspaces);
	useEffect(() => {
		analytics.clickTournament({});
		dispatch(closeTournamentBuilderModal());
		dispatch(fetchPlatforms((workspaces && workspaces[0]?.alias) || ""));
		dispatch(fetchTournaments());
	}, []);
	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox>
				<TrialDurationAlert />
				<PageBanner backgroundImage={bannerImg} title="Tournaments" />
				<TournamentsView />
			</MDBox>
		</DashboardLayout>
	);
}

export default Tournaments;
