import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// @mui material components
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import MDInput from "template/components/MDInput";
import MDButton from "template/components/MDButton";

import { useForm } from "react-hook-form";

// Authentication layout components
import IllustrationLayout from "template/layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/illustrations/login_bg.jpg";
import googleIcon from "assets/images/icons/google.png";
import AuthButton from "components/elements/auth-button";
import { useDispatch, useSelector } from "react-redux";
import { login } from "actions/auth";
import { CircularProgress } from "@mui/material";
import { RootState } from "store";
import {
	startFullScreenLoading,
	stopFullScreenLoading,
} from "slices/modalSlice";
import FormFieldErrorMessage from "components/forms/FormFieldErrorMessage";
// import { login } from "slices/auth";

const schema = yup
	.object({
		email: yup
			.string()
			.email("Please enter a valid email")
			.required("No email provided"),
		password: yup
			.string()
			.required("No password provided.")
			.min(8, "Password is too short - should be 8 chars minimum."),
	})
	.required();

function SignInPage(): JSX.Element {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [serverErrors, setServerErrors] = useState<any>(null);
	const auth = useSelector((state: RootState) => state.auth);
	// const [rememberMe, setRememberMe] = useState<boolean>(false);

	// const handleSetRememberMe = () => setRememberMe(!rememberMe);
	const onSubmit = async (data: any) => {
		setServerErrors(null);
		const { email, password } = data;
		try {
			await dispatch(login(email, password));
			try {
				await dispatch(startFullScreenLoading());
				setTimeout(() => {
					dispatch(stopFullScreenLoading());
					navigate("/dashboard");
				}, 3000);
				// eslint-disable-next-line no-empty
			} catch (e: any) {}
		} catch (error: any) {
			setServerErrors(error);
			// await dispatch(stopFullScreenLoading());
		}
	};

	return (
		<IllustrationLayout
			// title="Sign In"
			// description="Enter your email and password to sign in"
			illustration={bgImage}
			hideNavBar
			header={
				<>
					<MDBox mb={1} textAlign="center">
						<MDTypography variant="h4" fontWeight="bold">
							Login
						</MDTypography>
					</MDBox>
					<MDBox
						my={2}
						mx="auto"
						justifyContent="center"
						textAlign="center"
					>
						<AuthButton
							title="Continue with Google"
							icon={googleIcon}
							authProvider="google"
						/>
					</MDBox>
					<MDBox
						my={2}
						mx="auto"
						justifyContent="center"
						textAlign="center"
					>
						<MDTypography variant="body2" color="text">
							<b>or</b>
						</MDTypography>
					</MDBox>
					<MDTypography variant="body2" color="text">
						Enter your email and password to login
					</MDTypography>
				</>
			}
		>
			<MDBox mb={2}>
				<FormFieldErrorMessage message={serverErrors?.detail} />
			</MDBox>
			<MDBox
				onSubmit={handleSubmit(onSubmit)}
				component="form"
				role="form"
			>
				<MDBox>
					<MDInput
						{...register("email")}
						type="email"
						label="Email"
						fullWidth
						error={errors.email}
					/>
				</MDBox>
				<FormFieldErrorMessage message={errors?.email?.message} />
				<MDBox mt={2}>
					<MDInput
						{...register("password")}
						type="password"
						label="Password"
						fullWidth
						error={errors.password}
					/>
				</MDBox>
				<FormFieldErrorMessage message={errors?.password?.message} />
				<MDBox
					display="flex"
					justifyContent="flex-end"
					alignItems="center"
					ml={-1}
					mt={2}
				>
					{/* <div>
						<Switch
							checked={rememberMe}
							onChange={handleSetRememberMe}
						/>
						<MDTypography
							variant="button"
							fontWeight="regular"
							color="text"
							onClick={handleSetRememberMe}
							sx={{
								cursor: "pointer",
								userSelect: "none",
								ml: -1,
							}}
						>
							&nbsp;&nbsp;Remember me
						</MDTypography>
					</div> */}
					<div>
						<MDTypography
							component={Link}
							to="/forgot-password"
							variant="button"
							color="text"
							fontWeight="regular"
						>
							Forgot Password?
						</MDTypography>
					</div>
				</MDBox>
				<MDBox mt={2} mb={1}>
					<MDButton
						onClick={handleSubmit(onSubmit)}
						color="warning"
						size="large"
						fullWidth
						disabled={auth?.loading}
					>
						{auth?.loading ? (
							<CircularProgress color="inherit" size={20} />
						) : (
							"Login"
						)}
					</MDButton>
				</MDBox>
				<MDBox my={2} textAlign="center">
					<MDTypography variant="button" color="text">
						Don&apos;t have an account? &nbsp;
						<MDTypography
							component={Link}
							to="/sign-up"
							variant="button"
							color="info"
							fontWeight="medium"
							textGradient
						>
							Sign up
						</MDTypography>
					</MDTypography>
				</MDBox>
			</MDBox>
		</IllustrationLayout>
	);
}

export default SignInPage;
