import { ReactNode } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DefaultNavbar from "template/examples/Navbars/DefaultNavbar";
import PageLayout from "template/examples/LayoutContainers/PageLayout";

// Material Dashboard 2 PRO React page layout routes
import pageRoutes from "page.routes";

// Authentication pages components
import Footer from "template/layouts/authentication/components/Footer";

// Declaring props types for BasicLayout
interface Props {
	image: string;
	children: ReactNode;
	hideNavBar?: boolean;
	hideFooter?: boolean;
}

function BasicLayout({ hideNavBar, image, children, hideFooter }: Props): JSX.Element {
	return (
		<PageLayout>
			{!hideNavBar && 
				<DefaultNavbar
					routes={pageRoutes}
					action={{
						type: "external",
						route: "https://creative-tim.com/product/material-dashboard-2-pro-react-ts",
						label: "buy now",
						color: "info",
					}}
					transparent
					light
				/>
			}
			<MDBox
				position="absolute"
				width="100%"
				minHeight="100vh"
				sx={{
					backgroundImage: ({
						functions: { linearGradient, rgba },
						palette: { gradients },
					}: Theme) =>
						image &&
						`${linearGradient(
							rgba(gradients.dark.main, 0.6),
							rgba(gradients.dark.state, 0.6)
						)}, url(${image})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
				}}
			/>
			<MDBox px={1} width="100%" height="100vh" mx="auto">
				<Grid
					container
					spacing={1}
					justifyContent="center"
					alignItems="center"
					height="100%"
				>
					<Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
						{children}
					</Grid>
				</Grid>
			</MDBox>
			{!hideFooter && <Footer light />}
		</PageLayout>
	);
}

export default BasicLayout;
