import IconButton from "@mui/material/IconButton";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import whatIcon from "assets/images/icons/what.png";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	padding: "10px",
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: "#7B809A",
		boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
		borderRadius: "12px",
		marginRight: "0 !important",
		marginLeft: "0 !important",
	},
}));

type ToolTipsType = {
	iconImg?: string;
	title: string;
	placement:
		| "bottom-end"
		| "bottom-start"
		| "bottom"
		| "left-end"
		| "left-start"
		| "left"
		| "right-end"
		| "right-start"
		| "right"
		| "top-end"
		| "top-start"
		| "top";
};

export default function CustomTooltip({
	iconImg = whatIcon,
	title,
	placement,
}: ToolTipsType) {
	return (
		<LightTooltip
			title={title}
			placement={placement}
			arrow={false}
			enterTouchDelay={0}
		>
			{iconImg && (
				<IconButton>
					<img src={iconImg} alt={title} />
				</IconButton>
			)}
		</LightTooltip>
	);
}

CustomTooltip.defaultProps = {
	iconImg: whatIcon,
};
