/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { IPlatform, IPlatforms } from "types/data";

const initialState: any = {
	tournaments: [],
	loading: false,
	error: null,
};

const slice = createSlice({
	name: "tournaments",
	initialState,
	reducers: {
		fetchTournamentsBegin: (state: any) => {
			state.loading = true;
		},
		fetchTournamentsSuccess(
			state: any,
			action: PayloadAction<any[]>
		): void {
			state.tournaments = [...action.payload];
			state.loading = false;
		},
		createTournamentSuccess: (state: any, action: PayloadAction<any>) => {
			state.tournaments = [...state.tournaments, action.payload];
			state.loading = false;
		},
		fetchTournamentsFail: (state: any) => {
			state.tournaments = [];
			state.loading = false;
		},
	},
});

export const { actions, reducer } = slice;

export const {
	fetchTournamentsBegin,
	fetchTournamentsSuccess,
	createTournamentSuccess,
	fetchTournamentsFail,
} = actions;

export default reducer;
