import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import iso3311a2 from "iso-3166-1-alpha-2";
import { styled } from "@mui/system";
import _debounce from "lodash/debounce";
import { TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import axiosClient from "utils/axios";
import { ErrorMessage } from "@hookform/error-message";

const InputStyles = styled("div")(() => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	"& .form-group": {
		position: "relative",
		display: "flex",
		"& input[type='text']": {
			width: "100%",
		},
		"& .MuiBox-root": {
			width: "100%",
		},
		"& .tooltip-wrapper": {
			"& button": {
				width: "30px",
				height: "30px",
				marginTop: "15px",
				"& img": {
					maxWidth: "30px",
				},
			},
		},
	},
}));

export default function PlatformInfoForm({ form }: { form: any }) {
	const {
		register,
		setValue,
		getValues,
		setError,
		formState: { errors },
		clearErrors,
	} = form;
	const onCountrySelect = (event: SelectChangeEvent) => {
		setValue("country", event.target.value);
		clearErrors("country");
	};
	const [currentName, setCurrentName] = useState<string | null>(null);
	const countryList = iso3311a2.getData();
	const checkDuplication = (value: string) => {
		if (value === currentName) {
			clearErrors("name");
			return;
		}
		axiosClient
			.post("duplicate/platform/", { name: value })
			.then((res) => {
				if (res?.data?.success) {
					clearErrors("name");
					setValue("name", value);
				} else {
					setError(
						"name",
						{
							type: "custom",
							message: "Platform already exists",
						},
						{
							shouldFocus: true,
						}
					);
				}
			})
			.catch(() => {
				setError(
					"name",
					{
						type: "custom",
						message: "Platform already exists",
					},
					{
						shouldFocus: true,
					}
				);
			});
	};
	const debounceFn = useCallback(_debounce(checkDuplication, 1000), []);
	useEffect(() => {
		register("name");
		setCurrentName(getValues().name);
	}, []);
	return (
		<form>
			<MDBox>
				<MDBox width="82%" textAlign="center" mx="auto" mt={5} pt={5}>
					<MDBox mb={1}>
						<MDTypography variant="h4" fontWeight="regular">
							Name and country
						</MDTypography>
					</MDBox>
					<MDTypography variant="body2" color="text">
						Place the internal name for the platform and country
						where it operates
					</MDTypography>
				</MDBox>
				<MDBox mt={5} pt={2}>
					<InputStyles>
						<MDBox
							sx={{
								display: "flex",
								justifyContent: "center",
								flexDirection: "column",
							}}
						>
							<TextField
								disabled={
									getValues("alias") !== null ||
									getValues("alias") === undefined
								}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={(event: any) => {
									// setValue("name", event.target.value);
									debounceFn(event.target.value);
								}}
								// {...register("name")}
								label="Platform Name"
								name="name"
								defaultValue={getValues().name}
								placeholder="Mobile App Asia"
								sx={{
									m: 1,
									width: 242,
									maxWidth: "98%",
								}}
							/>
							<ErrorMessage
								errors={errors}
								name="name"
								render={({ message }) => (
									<MDTypography
										variant="caption"
										color="secondary"
										style={{
											marginLeft: 15,
										}}
									>
										{message}
									</MDTypography>
								)}
							/>
							{/* {duplicate && (
								<MDTypography
									style={{
										float: "left",
									}}
									variant="caption"
									color="secondary"
								>
									&nbsp;&nbsp;* Platform already exists
								</MDTypography>
							)} */}
						</MDBox>
						<FormControl
							sx={{ m: 1, width: 242, maxWidth: "98%", mt: 3 }}
						>
							<InputLabel id="country-label">Country</InputLabel>
							<Select
								disabled={
									getValues("alias") !== null ||
									getValues("alias") === undefined
								}
								{...register("country")}
								name="country"
								labelId="country-label"
								id="country"
								// value={getValues("country")}
								defaultValue={getValues("country")}
								label="Country"
								onChange={onCountrySelect}
								IconComponent={KeyboardArrowDown}
								sx={{ minHeight: 45 }}
								placeholder="Select country"
							>
								<MenuItem value="none" disabled>
									Select Country
								</MenuItem>
								{Object.keys(countryList).map((key: string) => (
									<MenuItem key={key} value={key}>
										{countryList[key]} ({key})
									</MenuItem>
								))}
							</Select>
							<ErrorMessage
								errors={errors}
								name="country"
								render={({ message }) => (
									<MDTypography
										variant="caption"
										color="secondary"
										style={{
											marginLeft: 5,
											marginTop: 5,
										}}
									>
										{message}
									</MDTypography>
								)}
							/>
						</FormControl>
					</InputStyles>
				</MDBox>
			</MDBox>
		</form>
	);
}
