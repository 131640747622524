/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-useless-fragment */
import { yupResolver } from "@hookform/resolvers/yup";
// @mui material components
import Card from "@mui/material/Card";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
	resetTournamentBuilderForm,
	updateTournamentBuilderForm,
} from "slices/tournamentBuilderSlice";
// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
import MDButton from "template/components/MDButton";
import { ITournamentBuilderFormData } from "types/data";
// NewUser layout schemas for form and form feilds
import Confetti from "react-confetti";
import { closeTournamentBuilderModal } from "slices/modalSlice";
import { useWindowSize } from "react-use";
import axiosClient from "utils/axios";
import SingleGame from "components/games/SingleGame";
import format from "date-fns/format";
// import { PRIZE_TYPE } from "constants/constants";
import { fetchTournaments } from "actions/tournamentActions";
import { dateFormat } from "utils/date-format";
import { TOURNAMENT_STATUS } from "constants/constants";
import { useNavigate } from "react-router-dom";
import analytics from "utils/analytics";
import { toast } from "react-toastify";
import CongratulationsMessage from "components/modals/CongratulationsMessage";
import validations from "./schemas/validations";
import BasicInfoOne from "./steps/BasicInfoOne";
import BasicInfoTwo, { tournamentTypeLists } from "./steps/BasicInfoTwo";
// import RewardOne from "./steps/RewardOne";
// import RewardTwo from "./steps/RewardTwo";
import SelectGame from "./steps/SelectGame";
// import PlayerSessions from "./steps/PlayerSessions";

// Tournament Builder All Steps
import Template from "./steps/Template";
import TimeDuration from "./steps/TimeDuration";
import Summary from "./steps/Summary";
// import { fetchWorkspaces } from "actions/workspaces";
// import MDAvatar from "template/components/MDAvatar";

const StepperStyle = styled("div")(({ theme }) => ({
	"& .MuiStepper-root": {
		background: `linear-gradient(180deg, ${theme.palette.gradients.primary.main} 0%, ${theme.palette.gradients.primary.state} 100%)`,
		".MuiStepIcon-root": {
			width: " 0.8125rem",
			height: "0.8125rem",
		},
	},
	// ".MuiSt"
}));

const ModalWrapperStyle = styled("div")(({ theme }) => ({
	maxWidth: "65vw",
	minWidth: "65vw",
	"@media(max-width: 1023px)": {
		maxWidth: "90vw",
		minWidth: "90vw",
		[theme.breakpoints.down("sm")]: {
			maxWidth: "100vw",
			minWidth: "100vw",
		},
	},
	"& .tournament-card": {
		minHeight: "570px",
		"@media(max-width: 1023px)": {
			minHeight: "770px",
		},
	},
}));

function getSteps(): string[] {
	return [
		"Template",
		"Select Game",
		"Basic Info",
		"",
		"Time & Duration",
		// "Rewards",
		// "Player Sessions",
		"Summary",
	];
}

const GAMEMODES = ["High score based", "Player vs Player"];

function TournamentSteps(): JSX.Element {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { formData, step } = useSelector(
		(state: any) => state.tournamentBuilderReducer
	);
	const { platforms } = useSelector((state: any) => state.platformsReducer);
	const platform =
		platforms && platforms.length > 0 && platforms[0] ? platforms[0] : null;
	const [activeStep, setActiveStep] = useState(0);
	const { games } = useSelector((state: any) => state.gamesReducer);
	const steps = getSteps();
	// const { formId, formField } = form;
	const currentValidation = validations[activeStep];
	const isLastStep = activeStep === steps.length - 1;
	const [finishStep, setFinishStep] = useState<boolean>(false);
	// const handleFinishStep = () => setFinishStep(true);
	const [loading, setLoading] = useState<boolean>(false);
	const [initialStatus, setInitialStatus] = useState<number>(
		TOURNAMENT_STATUS.DRAFT
	);
	useEffect(() => {
		if (step === 6) {
			setActiveStep(5);
			dispatch(
				updateTournamentBuilderForm({
					step: 5,
				})
			);
		}
	}, []);

	useEffect(() => {
		if (activeStep === steps.length) {
			setFinishStep(true);
		}
		if (step) {
			setActiveStep(step);
		} else {
			setActiveStep(0);
		}
	}, []);

	useEffect(() => {
		if (formData) {
			setInitialStatus(
				formData?.status ? formData?.status : TOURNAMENT_STATUS.DRAFT
			);
		}
	}, []);

	useEffect(() => {
		// console.log({ step });
		if (activeStep !== step) {
			setActiveStep(step);
			// setFinishStep(false);
		}
		/* if (activeStep === steps.length) {
			setFinishStep(true);
		} */
	}, [step]);

	const form: any = useForm<ITournamentBuilderFormData>({
		// defaultValues: { ...formData, prize: [...formData.prize] },
		defaultValues: { ...formData },
		resolver: yupResolver(currentValidation),
	});

	const {
		getValues,
		setValue,
		formState: { errors },
	} = form;

	useEffect(() => {
		if (errors && errors.game_alias) {
			toast("Please select a game from the list", {
				autoClose: 1500,
				type: "error",
				hideProgressBar: false,
				theme: "light",
			});
		}
	}, [errors]);

	const onSubmit = (data: any) => {
		if (activeStep === 0) {
			setActiveStep(activeStep + 1);
			dispatch(
				updateTournamentBuilderForm({
					// formData: { ...data, prize: [...data.prize] },
					formData: { ...data },
					step: activeStep + 1,
				})
			);
			return;
		}

		if (activeStep === 1) {
			const selectedItem = games.find(
				(item: any) => item.alias === data.game_alias
			);
			analytics.selectGame({
				game: selectedItem.name,
				platform: platform?.name,
			});
		} else if (activeStep === 2) {
			analytics.enterTournamentInfoPage({
				title: data?.title,
				subtitle: data?.subtitle,
			});
		} else if (activeStep === 3) {
			analytics.enterTournamentType({
				tournament_type: tournamentTypeLists[data.tournament_type],
				game_mode: GAMEMODES[data.game_mode],
			});
		} else if (activeStep === 4) {
			analytics.enterTimeDuration({
				start_time: data?.start_time,
				end_time: data?.end_time,
			});
		}
		/* else if (activeStep === 5) {
			analytics.enterPlayerSessions({});
		} */
		// if (activeStep === steps.length - 1) {
		const {
			subtitle,
			// start_time,
			// end_time,
			// no_rewards_checked,
			// show_reward_setup,
		} = formData;
		/* let prizeData = [];
		if (!no_rewards_checked && show_reward_setup === "yes") {
			prizeData = data?.prize.map((item: any) => {
				if (item.prize_type === PRIZE_TYPE.PHYSICAL_PRIZE) {
					return {
						...item,
						prize_type: parseInt(item.prize_type, 10),
						prize: `${item.quantity.toString()} ${item.prize.toString()}`,
					};
				}
				return {
					...item,
					prize_type: parseInt(item.prize_type, 10),
					prize: item.quantity,
				};
			});
		} */

		const { start_time, end_time, timezone_label, timezone_offset } = data;
		// console.log({ formData, data });
		let params: any = {
			// ...data,
			alias: formData?.alias,
			// ...formData,
			// list_subtitle: subtitle || data?.subtitle,
			// prize: prizeData,
			steps: activeStep + 1,
		};
		if (activeStep === 1) {
			params = {
				alias: formData?.alias,
				game_alias: data.game_alias,
				goal: data.goal,
				steps: activeStep + 1,
			};
		} else if (activeStep === 2) {
			params = {
				alias: formData?.alias,
				title: data.title,
				list_subtitle: data?.subtitle || subtitle,
				subtitle: data?.subtitle || subtitle,
				steps: activeStep + 1,
			};
		} else if (activeStep === 3) {
			params = {
				alias: formData?.alias,
				game_mode: data.game_mode,
				tournament_type: data.tournament_type,
				steps: activeStep + 1,
			};
		} else if (activeStep === 4) {
			// Time & Duration
			let startTime = start_time;
			let endTime = end_time;
			// eslint-disable-next-line no-console
			// console.log({ startTime, endTime });
			try {
				startTime = format(new Date(start_time), "yyyy-MM-dd'T'HH:mm");
				// eslint-disable-next-line no-empty
			} catch (e: any) {
				// console.log(e);
			}
			try {
				endTime = dateFormat(end_time, "yyyy-MM-dd'T'HH:mm");
				// eslint-disable-next-line no-empty
			} catch (e: any) {
				// console.log(e);
			}
			params.start_time = startTime;
			params.end_time = endTime;
			params.timezone_label = timezone_label;
			params.timezone_offset = timezone_offset;
			delete params.timezone;
		} else if (activeStep === 5) {
			/* params = {
				alias: formData?.alias,
				game_alias: data.game_alias,
				goal: data.goal,
				title: data.title,
				list_subtitle: data?.subtitle || subtitle,
				subtitle: data?.subtitle || subtitle,
				game_mode: data.game_mode,
				tournament_type: data.tournament_type,
				start_time: startTime,
				end_time: endTime,
				timezone_label: timezone_label,
				timezone_offset: timezone_offset,
			}; */
		}

		if (activeStep !== 5) {
			delete params.max_player;
		}

		/* if (!(prizeData && prizeData.length > 0)) {
			delete params.prize;
		} */
		delete params.game;
		delete params.prize;
		delete params.status;
		delete params.timezone;
		// delete params.no_rewards_checked;
		// delete params.show_reward_setup;
		// delete params.prize;
		if (!params.alias) {
			params = {
				goal: formData.goal,
				game_alias: formData.game_alias,
				steps: activeStep + 1,
			};
		}
		// console.log({ params });
		setLoading(true);
		const axiosInstance = params?.alias
			? axiosClient.patch
			: axiosClient.post;
		const url = params?.alias
			? `tournaments/${params.alias}/`
			: `tournaments/`;
		delete params.alias; // ! THIS LINE CAN NOT BE ADDED BEFORE axiosInstance is post/patch is determined
		axiosInstance(url, { ...params })
			.then((res: any) => {
				dispatch(fetchTournaments());
				// dispatch(fetchWorkspaces());
				// eslint-disable-next-line no-console
				// console.log({ res });
				// setFinishStep(true);
				dispatch(
					updateTournamentBuilderForm({
						formData: {
							...data,
							alias: res?.data?.alias || null,
							// game_alias: res?.data?.game_alias,
						},
						step: activeStep + 1,
					})
				);
			})
			.catch(() => {
				toast(data?.reason || "Something went wrong", {
					autoClose: 5000,
					type: "error",
					hideProgressBar: false,
					theme: "light",
				});
			})
			.finally(() => {
				setLoading(false);
			});
		/* } else {
			dispatch(
				updateTournamentBuilderForm({
					formData: { ...data, prize: [...data.prize] },
					step: activeStep + 1,
				})
			);
			setActiveStep(activeStep + 1);
		} */

		if (activeStep === steps.length - 1) {
			const selectedGame = games.find(
				(item: any) => item.alias === formData?.game_alias
			);
			analytics.clickSaveTournament({
				game: selectedGame || null,
			});
			setFinishStep(true);
		}
		// setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
		dispatch(
			updateTournamentBuilderForm({
				step: activeStep - 1,
			})
		);
	};

	const getGameImage = () => {
		if (formData?.game_alias) {
			const game = games.find(
				(item: any) => item.alias === formData?.game_alias
			);
			return game || null;
		}
		return null;
	};

	useEffect(() => {
		if (formData?.timezone_label) {
			setValue("timezone_label", formData?.timezone_label);
		} else {
			const date = new Date();
			const offset = (date.getTimezoneOffset() / 60) * -1;
			setValue(
				"timezone_label",
				Intl.DateTimeFormat().resolvedOptions().timeZone
			);
			setValue("timezone_offset", offset);
		}
		if (formData?.timezone_offset) {
			setValue("timezone_offset", formData?.timezone_offset);
		} else {
			const date = new Date();
			const offset = (date.getTimezoneOffset() / 60) * -1;
			setValue("timezone_offset", offset);
		}
		if (formData?.timezone_label && formData?.timezone_offset) {
			setValue("timezone", {
				value: formData?.timezone_label,
				offset: formData?.timezone_offset,
			});
		}
	}, []);

	function getStepContent(stepIndex: number): JSX.Element {
		switch (stepIndex) {
			case 0:
				return (
					<Template
						form={form}
						onGoalSubmit={() => {
							dispatch(
								updateTournamentBuilderForm({
									formData: getValues(),
									step: activeStep + 1,
								})
							);
							setActiveStep(activeStep + 1);
						}}
					/>
				);
			case 1:
				return (
					<div key="select-game">
						<SelectGame form={form} />
					</div>
				);
			case 2:
				return <BasicInfoOne form={form} />;
			case 3:
				return <BasicInfoTwo form={form} />;
			case 4:
				return <TimeDuration form={form} />;
			/* case 5:
				return <RewardTwo form={form} />; */
			/* case 5:
				return <PlayerSessions form={form} />; */
			case 5:
				return <Summary />;
			default:
				return null;
		}
	}
	const { width, height } = useWindowSize();

	const handleClose = () => {
		dispatch(resetTournamentBuilderForm());
		dispatch(closeTournamentBuilderModal());
	};

	return (
		<ModalWrapperStyle>
			<MDBox
				sx={{
					// flexGrow: 0.5,
					mx: 2,
				}}
			>
				{finishStep ? (
					<>
						<Confetti
							width={width}
							height={height}
							recycle={false}
							numberOfPieces={1000}
							style={{
								position: "absolute" as "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
							}}
						/>
						<CongratulationsMessage
							showImage
							buttonText="GO TO TOURNAMENTS"
							header={
								<span>
									Congratulations!{" "}
									{initialStatus ===
										TOURNAMENT_STATUS.DRAFT &&
										"You've created and saved your tournament. "}
								</span>
							}
							description={
								initialStatus !== TOURNAMENT_STATUS.DRAFT
									? "You have successfully updated your tournament"
									: "We are processing the information and will receive an email when your tournament is ready to be tested"
							}
							closeModal={() => {
								navigate("/tournaments");
								handleClose();
							}}
							imageComponent={
								<MDBox mt={4}>
									<SingleGame game={getGameImage()} />
								</MDBox>
							}
							imageSrc={undefined}
						/>
					</>
				) : (
					<Card className="tournament-card">
						<MDBox mx={2} mt={-3}>
							<StepperStyle>
								<Stepper
									activeStep={activeStep}
									alternativeLabel
								>
									{steps.map((label, index: number) =>
										label !== "" ? (
											<Step key={label}>
												<StepLabel>{label}</StepLabel>
											</Step>
										) : (
											// eslint-disable-next-line react/no-array-index-key
											<span key={`${label}${index}`}>
												{null}
											</span>
										)
									)}
								</Stepper>
							</StepperStyle>
						</MDBox>
						<MDBox
							// m={3}
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "space-between",
								justifyContent: "center",
								flexGrow: 1,
								height: "100%",
								width: "100%",
							}}
						>
							<MDBox
								className="step-content-container"
								width="100%"
								sx={{
									height: "100%",
									flexGrow: 1,
									display: "flex",
									flexDirection: "column",
									justifyContent: "flex-start",
									// alignItems: "center",
								}}
							>
								{getStepContent(activeStep)}
							</MDBox>
							<MDBox
								width="100%"
								display="flex"
								justifyContent="space-between"
								px={2}
								py={2}
							>
								{activeStep === 0 ? (
									<MDBox />
								) : (
									<MDButton
										variant="outlined"
										color="dark"
										onClick={handleBack}
										style={{ maxHeight: "40px" }}
									>
										prev
									</MDButton>
								)}
								{isLastStep ? (
									<MDButton
										form={steps[activeStep]}
										// disabled={isSubmitting}
										type="submit"
										variant="gradient"
										color="info"
										// eslint-disable-next-line no-unused-vars
										onClick={form.handleSubmit(onSubmit)}
										loading={loading}
										// onClick={handleFinishStep}
									>
										Finish
									</MDButton>
								) : (
									activeStep !== 0 && (
										<MDButton
											onClick={form.handleSubmit(
												onSubmit
											)}
											form="tournament-builder-form"
											// disabled={isSubmitting}
											type="submit"
											variant="gradient"
											color="warning"
										>
											Next
										</MDButton>
									)
								)}
							</MDBox>
						</MDBox>
					</Card>
				)}
			</MDBox>
		</ModalWrapperStyle>
	);
}

export default TournamentSteps;
