/* eslint-disable import/prefer-default-export */
import { Dispatch } from "@reduxjs/toolkit";
import {
	createPlatformSuccess,
	fetchPlatformsBegin,
	fetchPlatformsFail,
	fetchPlatformsSuccess,
	stopPlatformLoading,
} from "slices/platformsSlice";
import { AppThunk } from "store";
import { IPlatform } from "types/data";
import axiosClient from "utils/axios";

import analytics from "utils/analytics";
import { fetchUserInfo } from "./auth";

export const fetchPlatforms =
	(workspaceAlias: string): AppThunk =>
	async (dispatch: Dispatch): Promise<void> =>
		// eslint-disable-next-line no-new
		new Promise((resolve, reject) => {
			dispatch(fetchPlatformsBegin());
			axiosClient
				.get(`workspaces/${workspaceAlias}/platform/`)
				.then((response: any) => {
					dispatch(fetchPlatformsSuccess(response.data));
					resolve(response.data);
					const platforms = response.data;
					const platform =
						platforms && platforms.length > 0 && platforms[0]
							? platforms[0]
							: null;
					analytics.setPlatform(platform?.name);
				})
				.catch((error: any) => {
					dispatch(fetchPlatformsFail());
					reject(error);
				});
		});

export const createPlatform =
	(workspaceAlias: string, params: IPlatform): AppThunk =>
	async (dispatch: Dispatch): Promise<void> =>
		// eslint-disable-next-line no-new
		new Promise((resolve, reject) => {
			dispatch(fetchPlatformsBegin());
			const temp = { ...params };
			delete temp.alias;
			axiosClient
				.post(`workspaces/${workspaceAlias}/platform/`, { ...temp })
				.then((response: any) => {
					dispatch(createPlatformSuccess(response.data));
					dispatch(fetchPlatforms(workspaceAlias) as any);
					resolve(response.data);
				})
				.catch((error: any) => {
					dispatch(fetchPlatformsFail());
					reject(error);
				});
		});

export const updatePlatform =
	(workspaceAlias: string, params: IPlatform): AppThunk =>
	async (dispatch: Dispatch): Promise<void> =>
		// eslint-disable-next-line no-new
		new Promise((resolve, reject) => {
			dispatch(fetchPlatformsBegin());
			axiosClient
				.patch(
					`workspaces/${workspaceAlias}/platform/${params.alias}`,
					{
						alias: params.alias,
						name: params.name,
						integration_mode: params.integration_mode,
						technology: params.technology,
						comment: params.comment,
					}
				)
				.then((response: any) => {
					dispatch(fetchPlatforms(workspaceAlias) as any);
					resolve(response.data);
				})
				.catch((error: any) => {
					dispatch(fetchPlatformsFail());
					reject(error);
				});
		});

export const unlockPlatform =
	(workspaceAlias: string): AppThunk =>
	async (dispatch: Dispatch): Promise<void> =>
		new Promise((resolve, reject) => {
			dispatch(fetchPlatformsBegin());
			axiosClient
				.get(`users/unlock/platform/`)
				.then((response: any) => {
					dispatch(fetchPlatforms(workspaceAlias) as any);
					dispatch(fetchUserInfo() as any);
					resolve(response.data);
				})
				.catch((error: any) => {
					dispatch(stopPlatformLoading());
					reject(error);
				});
		});
