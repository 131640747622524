/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React TS components
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import MDInput from "template/components/MDInput";
import MDButton from "template/components/MDButton";

// Authentication layout components
import BasicLayout from "template/layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/backgrounds/forgot-password.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import FormFieldErrorMessage from "components/forms/FormFieldErrorMessage";
import { useState } from "react";
import axiosClient from "utils/axios";
import { NavLink } from "react-router-dom";

const schema = yup
	.object({
		email: yup
			.string()
			.email("Please enter a valid email")
			.required("No email provided"),
	})
	.required();

function ForgotPasswordPage(): JSX.Element {
	const [loading, setLoading] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);
	// const handleSetRememberMe = () => setRememberMe(!rememberMe);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = (data: any) => {
		setLoading(true);
		axiosClient
			.post("users/password/reset/token/", {
				email: data.email,
			})
			.then((res: any) => {
				if (res?.data?.success) {
					setSuccess(true);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<BasicLayout hideNavBar hideFooter image={bgImage}>
			<Card>
				<MDBox
					variant="gradient"
					bgColor="secondary"
					borderRadius="lg"
					coloredShadow="info"
					mx={2}
					mt={-3}
					p={2}
					mb={1}
					textAlign="center"
				>
					<MDTypography
						variant="h4"
						fontWeight="medium"
						color="white"
						mt={1}
					>
						Reset Password
					</MDTypography>
					<Grid container spacing={3} justifyContent="center">
						<Grid item>
							<MDTypography variant="subtitle2" color="white">
								You will receive an e-mail in maximum 60 seconds
							</MDTypography>
						</Grid>
					</Grid>
				</MDBox>
				{!success && (
					<MDBox pt={4} pb={3} px={3}>
						<MDBox component="form" role="form">
							<MDBox mb={2}>
								<MDInput
									{...register("email")}
									name="email"
									type="email"
									label="Email"
									fullWidth
									variant="standard"
								/>
							</MDBox>
							<FormFieldErrorMessage
								message={errors?.email?.message}
							/>
							<MDBox mt={4} mb={1}>
								<MDButton
									onClick={handleSubmit(onSubmit)}
									loading={loading}
									disabled={loading}
									variant="gradient"
									color="dark"
									fullWidth
								>
									Send Email
								</MDButton>
							</MDBox>
						</MDBox>
					</MDBox>
				)}
				{success && (
					<MDBox pt={4} pb={3} px={3}>
						<MDTypography variant="subtitle2" color="dark">
							Please check your inbox to reset your password.
						</MDTypography>
						<MDBox mt={4} mb={1}>
							<NavLink to="/login">
								<MDButton
									variant="gradient"
									color="dark"
									fullWidth
								>
									Go back to Login
								</MDButton>
							</NavLink>
						</MDBox>
					</MDBox>
				)}
			</Card>
		</BasicLayout>
	);
}

export default ForgotPasswordPage;
