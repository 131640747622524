import { Link } from "react-router-dom";
import { useSelector } from "store";
import MDAlert from "template/components/MDAlert";
import MDBox from "template/components/MDBox";
import MDTypography from "template/components/MDTypography";
import { dateFormat, removeTimezoneFromDateString } from "utils/date-format";

function TrialDurationAlert() {
	const { user } = useSelector((state: any) => state.auth);
	const { isDesktop } = useSelector((state: any) => state.layoutReducer);
	const { platforms } = useSelector((state: any) => state.platformsReducer);
	const platform =
		platforms && platforms.length > 0 && platforms[0] ? platforms[0] : null;
	if (!platform || !platform?.is_trial || !platform.trial_end_date)
		return null;
	if (!user?.unlock_platform) return null;
	return (
		<MDBox
			sx={{
				width: "100%",
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				// justifyContent: isDesktop ? "center" : "flex-start",
				justifyContent: "space-around",
				marginTop: isDesktop ? -8 : 0,
			}}
		>
			<MDBox
				sx={{
					width: 480,
					zIndex: 1101,
				}}
			>
				<MDAlert color="warning">
					<MDBox
						sx={{
							display: "flex",
							flexDirection: isDesktop ? "row" : "column",
							alignItems: isDesktop ? "center" : "baseline",
							justifyContent: isDesktop
								? "space-between"
								: "flex-start",
						}}
					>
						<MDTypography variant="body2" color="light">
							30 days free trial ends on{" "}
							{dateFormat(
								new Date(
									removeTimezoneFromDateString(
										platform?.trial_end_date
									)
								),
								"dd MMMM yyyy"
							) || ""}
							&nbsp;&nbsp;
						</MDTypography>
						<Link to="/pricing">
							<MDTypography
								sx={{
									textDecoration: "underline",
									cursor: "pointer",
								}}
								variant="body2"
								color="white"
								fontWeight="regular"
							>
								Upgrade Now
							</MDTypography>
						</Link>
					</MDBox>
				</MDAlert>
			</MDBox>
		</MDBox>
	);
}

export default TrialDurationAlert;
