import { combineReducers } from "@reduxjs/toolkit";
import { reducer as authReducer } from "../slices/authSlice";
import { reducer as paginationDetailsReducer } from "../slices/paginationDetailsSlice";
import { reducer as modalReducer } from "../slices/modalSlice";
import { reducer as workspaces } from "../slices/workspaceSlice";
import gamesReducer from "../slices/gamesSlice";
import tournamentBuilder from "../slices/tournamentBuilderSlice";
import platformsReducer from "../slices/platformsSlice";
import tournamentsReducer from "../slices/tournamentsSlice";
import platformIntegratorReducer from "../slices/platformIntegratorSlice";
import graphReducer from "../slices/graphSlice";
import layoutReducer from "../slices/layoutSlice";

export const rootReducer = combineReducers({
	auth: authReducer,
	paginationDetails: paginationDetailsReducer,
	modal: modalReducer,
	workspaces,
	tournamentBuilderReducer: tournamentBuilder,
	gamesReducer,
	platformsReducer,
	tournamentsReducer,
	platformIntegratorReducer,
	graphReducer,
	layoutReducer,
});

export default rootReducer;
