/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { Dispatch } from "@reduxjs/toolkit";
import {
	createWorkspaceSuccess,
	fetchWorkspacesBegin,
	fetchWorkspacesFail,
	fetchWorkspacesSuccess,
} from "slices/workspaceSlice";
import { AppThunk } from "store";
import axiosClient from "utils/axios";

import analytics from "utils/analytics";

interface IWorkspaceFormValues {
	name: string;
	status: number;
	creator_role: string;
	platform_type: string;
	profile: string;
	industry: string;
	size: string;
}

export const fetchWorkspaces =
	(): AppThunk =>
	async (dispatch: Dispatch): Promise<void> => {
		// eslint-disable-next-line no-new
		return new Promise((resolve, reject) => {
			dispatch(fetchWorkspacesBegin());
			axiosClient
				.get("workspaces/")
				.then((response: any) => {
					dispatch(fetchWorkspacesSuccess(response.data));
					resolve(response.data);
					const workspaces = response.data;
					const workspace =
						workspaces && workspaces.length > 0 && workspaces[0]
							? workspaces[0]
							: null;
					analytics.setWorkspace(workspace?.name);
				})
				.catch((error: any) => {
					dispatch(fetchWorkspacesFail());
					reject(error);
				});
		});
	};

export const createWorkspace =
	(params: IWorkspaceFormValues): AppThunk =>
	async (dispatch: Dispatch): Promise<void> => {
		// eslint-disable-next-line no-new
		return new Promise((resolve, reject) => {
			dispatch(fetchWorkspacesBegin());
			axiosClient
				.post("workspaces/", { ...params })
				.then((response: any) => {
					dispatch(createWorkspaceSuccess(response.data));
					resolve(response.data);
				})
				.catch((error: any) => {
					dispatch(fetchWorkspacesFail());
					reject(error);
				});
		});
	};
