import { styled } from "@mui/system";

const ModalWrapperTwo = styled("div")(({ theme }) => ({
	position: "absolute" as "absolute",
	top: "50%",
	left: "90%",
	transform: "translate(-50%, -50%)",
	boxShadow: "24",
	minWidth: "45vw",
	border: "0",
	outline: "0",
	[theme.breakpoints.down("xl")]: {
		minWidth: "80vw",
	},
	[theme.breakpoints.down("lg")]: {
		minWidth: "80vw",
	},
	[theme.breakpoints.down("md")]: {
		minWidth: "95vw",
		paddingTop: "10%",
		paddingBottom: "10%",
		overflowY: "scroll",
	},
}));

export default ModalWrapperTwo;
