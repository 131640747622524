/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { TOURNAMENT_STATUS } from "constants/constants";
import { store } from "store";

export const countActiveTournaments = () => {
	const storeObj = store.getState();
	const { tournaments = [] } = storeObj ? storeObj.tournamentsReducer : {};
	const activeTournaments = tournaments.filter(
		(tournament: any) => tournament.status === TOURNAMENT_STATUS.ON
	);
	return activeTournaments.length;
};

export const hasTournaments = () => {
	const storeObj = store.getState();
	const { tournaments = [] } = storeObj ? storeObj.tournamentsReducer : {};
	return tournaments && tournaments.length > 0;
};
