// import { useState } from "react";
import { styled } from "@mui/system";
import Modal from "@mui/material/Modal";

import { useDispatch, useSelector } from "react-redux";
import { closeTournamentBuilderModal } from "slices/modalSlice";
import { useEffect, useState } from "react";
import DeviceEmulator from "react-device-emulator";
import "react-device-emulator/lib/styles/style.css";
import MDBox from "template/components/MDBox";

// import element
import CloseButton from "components/elements/CloseButton";
import { resetTournamentBuilderForm } from "slices/tournamentBuilderSlice";
import { fetchTournaments } from "actions/tournamentActions";
import TournamentSteps from "containers/Tournaments/form/TournamentSteps";

const ModalStyle = styled("div")(({ theme }) => ({
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -48%)",
	boxShadow: "24",
	display: "flex",
	justifyContent: "space-evenly",
	flexDirection: "row",
	// flexWrap: "wrap",
	alignItems: "center",
	/* [theme.breakpoints.up("lg")]: {
		minWidth: "975px",
	}, */
	minWidth: "100vw",
	maxHeight: "100vh",
	border: "0",
	outline: "0",
	[theme.breakpoints.down("xl")]: {
		minWidth: "85vw",
	},
	[theme.breakpoints.down("lg")]: {
		minWidth: "85vw",
	},
	[theme.breakpoints.down("md")]: {
		minWidth: "95vw",
		paddingTop: "10%",
		paddingBottom: "15%",
		overflow: "scroll",
		height: "100%",
	},
	"@media(max-width: 1023px)": {
		flexDirection: "column",
		width: "100vw",
		overflowY: "scroll",
		transform: "translate(-50%, -2%)",
		top: "20px",
		paddingTop: "800px",
		paddingBottom: "800px",
		"& .device-emulator-container": {
			paddingTop: "20px",
			paddingBottom: "20px",
		},
	},
}));

export default function TournamentBuilderModal() {
	const dispatch = useDispatch();
	const { formData, step } = useSelector(
		(state: any) => state.tournamentBuilderReducer
	);
	const { games } = useSelector((state: any) => state.gamesReducer);
	const { showTournamentBuilderModal } = useSelector(
		(state: any) => state.modal
	);
	const [gamePlayUrl, setGamePlayUrl] = useState<string | null>(null);
	const handleClose = () => {
		dispatch(fetchTournaments());
		dispatch(closeTournamentBuilderModal());
		dispatch(resetTournamentBuilderForm());
	};

	useEffect(
		() =>
			// dispatch(openTournamentBuilderModal());
			// dispatch(resetTournamentBuilderForm());
			() => {
				dispatch(resetTournamentBuilderForm());
				dispatch(closeTournamentBuilderModal());
			},
		[]
	);

	useEffect(() => {
		if (formData?.game_alias) {
			const game = games.find(
				(item: any) => item.alias === formData?.game_alias
			);
			setGamePlayUrl(game?.play_link);
		} else {
			setGamePlayUrl(null);
		}
	}, [formData?.game_alias, games]);

	useEffect(() => {
		if (showTournamentBuilderModal) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "scroll";
		}
	}, [showTournamentBuilderModal]);

	if (!showTournamentBuilderModal) return null;

	return (
		<div className="modal-wrapper">
			<Modal
				BackdropProps={{
					style: { backgroundColor: "#191919", opacity: 0.85 },
				}}
				// onClose={handleClose}
				open={showTournamentBuilderModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disableScrollLock
			>
				<>
					<ModalStyle>
						<TournamentSteps />
						{(gamePlayUrl || step > 0) && step < 8 && (
							<MDBox>
								<DeviceEmulator
									type="mobile"
									withDeviceSwitch={false}
									withRotator={false}
									url={gamePlayUrl || ""}
								/>
							</MDBox>
						)}
					</ModalStyle>
					<CloseButton
						handleClose={handleClose}
						buttonText="Close Tournament"
					/>
				</>
			</Modal>
		</div>
	);
}
