import TrialDurationAlert from "components/alerts/TrialDurationAlert";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "template/components/MDBox";
import DashboardLayout from "template/examples/LayoutContainers/DashboardLayout";
import ProfilePageContent from "./ProfilePageContent";

export default function UserProfilePage() {
	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox>
				<TrialDurationAlert />
				<MDBox py={5} mx={2}>
					<ProfilePageContent />
				</MDBox>
			</MDBox>
		</DashboardLayout>
	);
}
